import React, { useContext, useReducer, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useLocation } from "react-router-dom";
import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = { active: "Dashboard", activeSubmenu: "" };

const SideBar = () => {
  var d = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,changeBackground
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const [islightmode, setIsLightMode] = useState(true);

  //For scroll

  let handleheartBlast = document.querySelector(".heart");
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };
  // Menu dropdown list End
  const ChangeMode = () => {
    if(islightmode){
      changeBackground({ value: "dark", label: "Dark" })
    }else{
      changeBackground({ value: "light", label: "Light" })
    }
    setIsLightMode(!islightmode);
  };

  /// Path
  let path = useLocation();
  path = path.pathname.split("/");
  let activeMenu = path[1];

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    (data.content &&
                      data.content.some((d) => d.to === activeMenu)) ||
                    activeMenu === data.to
                      ? "mt-2 mm-active"
                      : "mt-2"
                  }`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to={"#"}
                        onClick={() => handleMenuActive(data.title)}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                      <ul className="collapse show">
                        {data.content &&
                          data.content.map((sub, index) => {
                            if (sub.iconStyle) {
                              return (
                                <Link key={index}>
                                   <div className="toggle-theme-wrapper">
                                   <span>🌒</span>
                                  
                                  <label
                                    className="toggle-theme"
                                    htmlFor="checkbox"
                                    onClick={() => ChangeMode()}
                                  >
                                    <input
                                      type="checkbox"
                                      id="checkbox"
                                      checked={islightmode}
                                      readOnly
                                    />
                                    {islightmode ? (
                                      <div className="lightmode round"></div>
                                    ) : (
                                      <div className="darkmode round"></div>
                                    )}
                                  </label>
                                  <span>☀️</span>
                                </div>
                                </Link>
                               
                              );
                            } else {
                              return (
                                <li
                                  key={index}
                                  className={`${
                                    state.activeSubmenu === sub.title
                                      ? "mm-active"
                                      : ""
                                  }`}
                                >
                                  {data.content && data.content.length > 0 ? (
                                    <Link
                                      to={sub.to}
                                      className={
                                        data.hasMenu ? "has-arrow" : ""
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(sub.title);
                                      }}
                                    >
                                      {sub.title}
                                    </Link>
                                  ) : (
                                    <Link to={sub.to}>
                                      <span className="nav-text">
                                        {sub.title}
                                      </span>
                                    </Link>
                                  )}
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>

        <div className="copyright">
          <p className="text-center">
            <strong>Bustan Al Sukkar Dashboard</strong> © {d.getFullYear()} All
            Rights Reserved
          </p>
          <p className="fs-12 text-center">
            Made with{" "}
            <span className="heart" onClick={() => heartBlast()}></span> by
            Vision & more
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
