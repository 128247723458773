import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import user from "./../../../../images/avatar/1.png";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next fas fa-arrow-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev fas fa-arrow-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

const LatestReview = ({ reviews }) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1560,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider
        className="front-view-slider owl-carousel owl-carousel owl-loaded owl-drag owl-dot"
        {...settings}
      >
        {reviews &&
          reviews.length > 0 &&
          reviews.map((review) => (
            <div className="items" key={review.hotelRatingId}>
              <div className="customers border">
                <ul className="d-flex">
                  {[...Array(review.starRating)].map((star, index) => (
                    <li key={index}>
                      <i className="fa fa-star light" aria-hidden="true"></i>
                    </li>
                  ))}
                  {[...Array((review.starRating - 5) * -1)].map(
                    (star, index) => (
                      <li key={index}>
                        <i className="fa fa-star dark" aria-hidden="true"></i>
                      </li>
                    )
                  )}
                </ul>
                <p className="fs-16 mt-4">{review.comment}</p>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div className="customer-profile d-flex ">
                    <img src={user} alt="" />
                    <div className="ms-3">
                      <h5 className="mb-0">
                        <Link to={"#"}>
                          {review.firstName + " " + review.lastName}
                        </Link>
                      </h5>
                      <h6 className="mb-0">
                        <Link to={"#"}>{review.phoneNumber}</Link>
                      </h6>
                      <span>{review.isRegistered ? "customer" : "Guest"}</span>
                    </div>
                  </div>
                  {/* <div className="customer-button text-nowrap">
                    <Link to={"#"}>
                      <i className="far fa-check-circle text-success"></i>
                    </Link>
                    <Link to={"#"}>
                      <i className="far fa-times-circle text-danger"></i>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
};
export default LatestReview;
