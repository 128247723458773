import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Global } from "../../../../../Global";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";

const CustomerAdd = () => {
  const { userToken, userId } = useSelector((state) => state.credential);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [value, setValue] = useState("");
  const [dob, setDob] = useState("");
  const [image, setImage] = useState("");

  const handleDrop = (e) => {
    setDisableSubmit(true);
    let formdata = new FormData();
    formdata.append("Images", e.target.files[0]);
    formdata.append("Sender", "A");
    formdata.append("SenderID", userId);

    fetch("https://bmsapi.vision-more.com/api/Others/UploadSingleImage", {
      method: "POST",
      headers: {
        "bs-api-key": Global.bs_api_key,
        AuthorizationUser: userToken,
      },
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setImage(result[0]);
        setDisableSubmit(false);
      })
      .catch((error) => console.log("error", error));
  };

  const AddCustomer = async (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email === "" ||
      dob === "" ||
      value === undefined
    ) {
      toast.error("All fields are required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else if (!isPossiblePhoneNumber(value)) {
      toast.error("Enter a valid phone number");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "Customer/CreateCustomer",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              email: email,
              phone: value,
              doB: new Date(dob),
              profilePicture: image,
              isPublished: true,
              loginTypeId: 1,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={AddCustomer}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group mb-4 col-md-6">
                  <label>
                    First Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label>
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label>
                    Email Address<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label>
                    Phone Number<span className="text-danger">*</span>
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    country="LB"
                    value={value}
                    onChange={(e) => setValue(e)}
                    required={true}
                  />
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label>
                    Date of Birth<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </div>
                <div className="mb-3 col-md-12">
                  <label>
                    Image<span className="text-danger">*</span>
                  </label>
                  <br />
                  {image !== "" && (
                    <img
                      style={{ height: "200px" }}
                      src={Global.PHOTO_URL + image}
                      alt="hotel"
                    />
                  )}
                  <br />
                  <br />
                  <input type="file" onChange={handleDrop} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerAdd;
