import React from "react";
import { setCredential } from "../../../store/slices/credential-slice";
import { useDispatch } from "react-redux";

function LogoutPage() {
const dispatch=useDispatch()
  function onLogout() {
   dispatch(setCredential({
    isLogged: false,
    userId: 0,
    userToken: "",
    email: "",
    role: {
      manageNotifications: false,
      manageMedia: false,
      listMedia: false,
      deleteMedia: false,
      manageCustomer: false,
      listCustomer: false,
      listCountry: false,
      listcity: false,
      countryPublication: false,
      countrySanctioning: false,
      listHotels: false,
      manageHotels: false,
      deleteHotels: false,
      manageRooms: false,
      deleteRooms: false,
      listRooms: false,
      manageOffers: false,
      listOffers: false,
      deleteOffers: false,
      manageRegulations: false,
      deleteRegulations: false,
      listRegulations: false,
      manageUsers: false,
      listUsers: false,
      deleteUsers: false,
      manageServices: false,
      listServices: false,
      deleteServices: false,
      manageAllowedItems: false,
      listAllowedItems: false,
      deleteAllowedItems: false,
    }
   }))
  }

  return (
    <>
      <button className="dropdown-item ai-icon" onClick={onLogout}>
        <svg
          id="icon-logout"
          xmlns="http://www.w3.org/2000/svg"
          className="text-danger me-1"
          width={18}
          height={18}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1={21} y1={12} x2={9} y2={12} />
        </svg>
        <span className="ms-2">Logout </span>
      </button>
    </>
  );
}

export default LogoutPage;
