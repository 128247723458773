import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

// import LightGallery from "lightgallery/react";
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

const RoomManage = () => {
  const { room_id } = useParams();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.credential);
  // const [refreshKey, setRefreshKey] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [roomTypeId, setRoomTypeId] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");
  const [weekdayPrice, setWeekdayPrice] = useState(0);
  const [weekendPrice, setWeekendPrice] = useState(0);
  const [totalBeds, setTotalBeds] = useState(0);
  const [totalBedrooms, setTotalBedrooms] = useState(0);
  const [totalGuests, setTotalGuests] = useState(0);
  const [totalBaths, setTotalBaths] = useState(0);
  const [roomName, setRoomName] = useState("");
  const [maxInfants, setMaxInfants] = useState(0);
  const [maxChildren, setMaxChildren] = useState(0);
  const [maxAdults, setMaxAdults] = useState(0);
  const [floor, setFloor] = useState(0);
  const [breakfastPrice, setBreakfastPrice] = useState(0);
  const [isBreakfastIncluded, setIsBreakfastIncluded] = useState(true);
  const [isPublished, setIsPublished] = useState(true);

  const [assignedRoomAllowedItems, setAssignedRoomAllowedItems] = useState([]);
  const [assignedRoomNotAllowedItems, setAssignedRoomNotAllowedItems] =
    useState([]);
  const [assignedRoomServices, setAssignedRoomServices] = useState([]);
  const [assignedBedTypes, setAssignedBedTypes] = useState([]);

  const [assignedImages, setAssignedImages] = useState([]);
  const [assignedDiscoverImages, setAssignedDiscoverImages] = useState([]);
  const [isOpenAssignedImages, setIsOpenAssignedImages] = useState(false);
  const [isOpenAssignedDiscoverImages, setIsOpenAssignedDiscoverImages] =
    useState(false);

  const [isOpenAssignedServices, setIsOpenAssignedServices] = useState(false);
  const [isOpenAssignedAllowedItems, setIsOpenAssignedAllowedItems] =
    useState(false);
  const [isOpenAssignedNotAllowedItems, setIsOpenAssignedNotAllowedItems] =
    useState(false);
  const [isOpenAssignedBedTypes, setIsOpenAssignedBedTypes] = useState(false);

  const [isImagesForBedType, setIsImagesForBedType] = useState(false);
  const [selectedBedTypeId, setSelectedBedTypeId] = useState(0);

  const [roomTypes, setRoomTypes] = useState([]);
  const [roomServices, setRoomServices] = useState([]);
  const [roomAllowedItems, setRoomAllowedItems] = useState([]);
  const [roomNotAllowedItems, setRoomNotAllowedItems] = useState([]);
  const [roomImages, setRoomImages] = useState([]);
  const [roomBedTypes, setRoomBedTypes] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  // const galleryRef = useRef();
  const pageLimit = 24;
  const activePag = useRef(0);

  // const [selectBtn, setSelectBtn] = useState("Newest");

  let paggination = Array(Math.ceil(totalCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const ChangePage = (i) => {
    activePag.current = i;

    changeData(
      activePag.current * pageLimit,
      (activePag.current + 1) * pageLimit
    );
  };

  const changeData = async (first, sec) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Gallery/GetGalleryList?Skip=" +
          first +
          "&Take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalCount(result.count);
        setRoomImages(array);
      } else if (result.code === 1) {
        setRoomImages([]);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OpenImagesModal = async (typeId, bedTypeId) => {
    if (typeId === 1) {
      setIsImagesForBedType(typeId === 1);
      setSelectedBedTypeId(bedTypeId);
    } else if (typeId === 2) {
      setIsOpenAssignedImages(true);
    } else {
      setIsOpenAssignedDiscoverImages(true);
    }
    try {
      const response = await fetch(
        Global.API_URL + "Gallery/GetGalleryList?Skip=0&Take=24",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        result.message.forEach((image) => {
          Object.assign(image, {
            galleryOrder: 0,
          });
        });
        let array = result.message;
        setTotalCount(result.count);
        setRoomImages(array);
      } else if (result.code === 1) {
        setRoomImages([]);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OpenServicesModal = async () => {
    setIsOpenAssignedServices(true);
    try {
      const response = await fetch(
        Global.API_URL + "RoomService/GetRoomServiceList?skip=0&take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      let result = await response.json();
      if (result.code === 0) {
        result.message.forEach((service) => {
          Object.assign(service, {
            description: "",
          });
        });
        setRoomServices(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OpenAllowedItemsModal = async () => {
    setIsOpenAssignedAllowedItems(true);
    try {
      const response = await fetch(
        Global.API_URL +
          "RoomAllowedItem/GetRoomAllowedItemsList?skip=0&take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      let result = await response.json();
      if (result.code === 0) {
        result.message.forEach((item) => {
          Object.assign(item, {
            description: "",
          });
        });
        setRoomAllowedItems(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const OpenNotAllowedItemsModal = async () => {
    setIsOpenAssignedNotAllowedItems(true);
    try {
      const response = await fetch(
        Global.API_URL + "RoomNotAllowedItem/GetNotAllowedItemsDropdownList",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      let result = await response.json();
      if (result.code === 0) {
        result.message.forEach((item) => {
          Object.assign(item, {
            description: "",
          });
        });
        setRoomNotAllowedItems(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const OpenBedTypesModal = async () => {
    setIsOpenAssignedBedTypes(true);
    try {
      const response = await fetch(
        Global.API_URL + "BedType/GetBedTypes?skip=0&take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      let result = await response.json();
      if (result.code === 0) {
        result.message.forEach((item) => {
          Object.assign(item, {
            imageURL: "",
            nbOfBeds: 0,
          });
        });
        setRoomBedTypes(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddRoom = async (e) => {
    e.preventDefault();
    if (
      roomTypeId === 0 ||
      shortDescription.trim() === "" ||
      description.trim() === "" ||
      weekdayPrice === 0 ||
      isNaN(weekdayPrice) ||
      weekendPrice === 0 ||
      isNaN(weekendPrice) ||
      totalBeds === 0 ||
      isNaN(totalBeds) ||
      totalBedrooms === 0 ||
      isNaN(totalBedrooms) ||
      totalBaths === 0 ||
      isNaN(totalBaths) ||
      totalGuests === 0 ||
      isNaN(totalGuests) ||
      roomName.trim() === "" ||
      (isBreakfastIncluded &&
        (breakfastPrice === 0 || isNaN(breakfastPrice))) ||
      assignedRoomAllowedItems.length === 0 ||
      assignedRoomAllowedItems.some((item) => item.description.trim() === "") ||
      assignedRoomNotAllowedItems.length === 0 ||
      assignedRoomNotAllowedItems.some(
        (item) => item.description.trim() === ""
      ) ||
      assignedRoomServices.length === 0 ||
      assignedRoomServices.some(
        (service) => service.description.trim() === ""
      ) ||
      assignedImages.length === 0 ||
      assignedImages.some((ai) => isNaN(ai.galleryOrder))
    ) {
      toast.error("All fields are required");
    } else {
      setDisableSubmit(true);
      let images = [],
        discoverImages = [],
        items = [],
        notallowedItem = [],
        services = [],
        bedTypes = [];
      assignedImages.forEach((image) => {
        images.push({
          galleryId: image.galleryId,
          galleryOrder: image.galleryOrder,
        });
      });
      assignedDiscoverImages.forEach((image) => {
        discoverImages.push({
          galleryId: image.galleryId,
          galleryOrder: image.galleryOrder,
        });
      });
      assignedRoomAllowedItems.forEach((item) => {
        items.push({
          allowedItemId: item.roomAllowedItemId,
          description: item.description,
        });
      });
      assignedRoomNotAllowedItems.forEach((item) => {
        notallowedItem.push({
          allowedItemId: item.itemId,
          description: item.description,
        });
      });
      assignedRoomServices.forEach((service) => {
        services.push({
          serviceId: service.roomServiceId,
          description: service.description,
        });
      });
      assignedBedTypes.forEach((type) => {
        bedTypes.push({
          bedTypeId: type.bedTypeId,
          imageURL: type.imageURL,
          nbOfBeds: type.nbOfBeds,
        });
      });
      try {
        const response = await fetch(Global.API_URL + "Room/AddRoom", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
          body: JSON.stringify({
            roomTypeId: roomTypeId,
            shortDescription: shortDescription,
            description: description,
            price_per_night_weekday: weekdayPrice,
            price_per_night_weekend: weekendPrice,
            totalnbofBeds: totalBeds,
            totalnbofBedrooms: totalBedrooms,
            nbofGuests: totalGuests,
            nbofBaths: totalBaths,
            roomName: roomName,
            max_occupancies_infants: maxInfants,
            max_occupancies_children: maxChildren,
            max_occupancies_adults: maxAdults,
            breakfastPrice: breakfastPrice,
            breakfastIncluded: isBreakfastIncluded,
            floor: floor,
            isPublished: isPublished,
            allowedItems: items,
            notAllowedItems: notallowedItem,
            images: images,
            roomServices: services,
            bedTypes: bedTypes,
          }),
        });

        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/room");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const EditRoom = async (e) => {
    e.preventDefault();
    if (
      roomTypeId === 0 ||
      shortDescription.trim() === "" ||
      description.trim() === "" ||
      weekdayPrice === 0 ||
      isNaN(weekdayPrice) ||
      weekendPrice === 0 ||
      isNaN(weekendPrice) ||
      totalBeds === 0 ||
      isNaN(totalBeds) ||
      totalBedrooms === 0 ||
      isNaN(totalBedrooms) ||
      totalBaths === 0 ||
      isNaN(totalBaths) ||
      totalGuests === 0 ||
      isNaN(totalGuests) ||
      roomName.trim() === "" ||
      (isBreakfastIncluded &&
        (breakfastPrice === 0 || isNaN(breakfastPrice))) ||
      assignedRoomAllowedItems.length === 0 ||
      assignedRoomAllowedItems.some((item) => item.description.trim() === "") ||
      assignedRoomNotAllowedItems.length === 0 ||
      assignedRoomNotAllowedItems.some(
        (item) => item.description.trim() === ""
      ) ||
      assignedRoomServices.length === 0 ||
      assignedRoomServices.some(
        (service) => service.description.trim() === ""
      ) ||
      assignedImages.length === 0 ||
      assignedImages.some((ai) => isNaN(ai.galleryOrder))
    ) {
      toast.error("All fields are required");
    } else {
      setDisableSubmit(true);
      let images = [],
        discoverImages = [],
        items = [],
        services = [],
        notallowedItem = [],
        bedTypes = [];

      assignedImages.forEach((image) => {
        images.push({
          galleryId: image.galleryId,
          galleryOrder: image.galleryOrder,
        });
      });
      assignedDiscoverImages.forEach((image) => {
        discoverImages.push({
          galleryId: image.galleryId,
          galleryOrder: image.galleryOrder,
        });
      });
      assignedRoomAllowedItems.forEach((item) => {
        items.push({
          allowedItemId: item.roomAllowedItemId,
          description: item.description,
        });
      });
      assignedRoomNotAllowedItems.forEach((item) => {
        notallowedItem.push({
          allowedItemId: item.itemId,
          description: item.description,
        });
      });
      assignedRoomServices.forEach((service) => {
        services.push({
          serviceId: service.roomServiceId,
          description: service.description,
        });
      });
      assignedBedTypes.forEach((type) => {
        bedTypes.push({
          bedTypeId: type.bedTypeId,
          imageURL: type.imageURL,
          nbOfBeds: type.nbOfBeds,
        });
      });
      try {
        console.log(
          JSON.stringify({
            roomTypeId: roomTypeId,
            shortDescription: shortDescription,
            description: description,
            price_per_night_weekday: weekdayPrice,
            price_per_night_weekend: weekendPrice,
            totalnbofBeds: totalBeds,
            totalnbofBedrooms: totalBedrooms,
            nbofGuests: totalGuests,
            nbofBaths: totalBaths,
            roomName: roomName,
            max_occupancies_infants: maxInfants,
            max_occupancies_children: maxChildren,
            max_occupancies_adults: maxAdults,
            breakfastPrice: breakfastPrice,
            breakfastIncluded: isBreakfastIncluded,
            floor: floor,
            isPublished: isPublished,
            allowedItems: items,
            notAllowedItems: notallowedItem,
            images: images,
            roomServices: services,
            bedTypes: bedTypes,
            discoverImages: discoverImages,
          })
        );
        const response = await fetch(
          Global.API_URL + "Room/EditRoom?roomid=" + room_id,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              roomTypeId: roomTypeId,
              shortDescription: shortDescription,
              description: description,
              price_per_night_weekday: weekdayPrice,
              price_per_night_weekend: weekendPrice,
              totalnbofBeds: totalBeds,
              totalnbofBedrooms: totalBedrooms,
              nbofGuests: totalGuests,
              nbofBaths: totalBaths,
              roomName: roomName,
              max_occupancies_infants: maxInfants,
              max_occupancies_children: maxChildren,
              max_occupancies_adults: maxAdults,
              breakfastPrice: breakfastPrice,
              breakfastIncluded: isBreakfastIncluded,
              floor: floor,
              isPublished: isPublished,
              allowedItems: items,
              notAllowedItems: notallowedItem,
              images: images,
              roomServices: services,
              bedTypes: bedTypes,
              discoverImages: discoverImages,
            }),
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/room");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const AssignImage = async (Image) => {
    if (!isImagesForBedType) {
      if (
        !assignedImages.some((image) => image.galleryId === Image.galleryId)
      ) {
        let array = [...assignedImages];
        let newArray = array.concat(Image);
        setAssignedImages(newArray);
      }
    } else {
      let index = assignedBedTypes.findIndex(
        (type) => type.bedTypeId === selectedBedTypeId
      );
      if (index !== -1) {
        let array = [...assignedBedTypes];
        array[index].imageURL = Image.imageUrl;
        setAssignedBedTypes(array);
      }
      setIsOpenAssignedImages(false);
    }
  };

  const UnassignImage = async (Image) => {
    if (assignedImages.some((image) => image.galleryId === Image.galleryId)) {
      let array = [...assignedImages];
      let newArray = array.filter(
        (image) => image.galleryId !== Image.galleryId
      );
      setAssignedImages(newArray);
      // setRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const ChangeGalleryOrder = (Value, GalleryId) => {
    let array = [...assignedImages];
    let index = array.findIndex((image) => image.galleryId === GalleryId);
    if (index !== -1) {
      array[index].galleryOrder = Value;
      setAssignedImages(array);
      // setRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const AssignDiscoverImage = async (Image) => {
    console.log(Image);
    let array = [...assignedDiscoverImages];
    let newArray = array.concat(Image);
    setAssignedDiscoverImages(newArray);
  };

  console.log(assignedDiscoverImages);

  const UnassignDiscoverImage = async (Image) => {
    if (
      assignedDiscoverImages.some(
        (image) => image.galleryId === Image.galleryId
      )
    ) {
      let array = [...assignedDiscoverImages];
      let newArray = array.filter(
        (image) => image.galleryId !== Image.galleryId
      );
      setAssignedDiscoverImages(newArray);
      // setRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const ChangeDiscoverGalleryOrder = (Value, GalleryId) => {
    let array = [...assignedDiscoverImages];
    let index = array.findIndex((image) => image.galleryId === GalleryId);
    if (index !== -1) {
      array[index].galleryOrder = Value;
      setAssignedDiscoverImages(array);
      // setRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const AssignService = async (Service) => {
    if (
      !assignedRoomServices.some(
        (service) => service.roomServiceId === Service.roomServiceId
      )
    ) {
      let array = [...assignedRoomServices];
      let newArray = array.concat(Service);
      setAssignedRoomServices(newArray);
    }
  };

  const AssignAllowItem = async (Item) => {
    if (
      !assignedRoomAllowedItems.some(
        (item) => item.roomAllowedItemId === Item.roomAllowedItemId
      )
    ) {
      let array = [...assignedRoomAllowedItems];
      let newArray = array.concat(Item);
      setAssignedRoomAllowedItems(newArray);
    }
  };
  const AssignNotAllowItem = async (Item) => {
    if (
      !assignedRoomNotAllowedItems.some((item) => item.itemId === Item.itemId)
    ) {
      let array = [...assignedRoomNotAllowedItems];
      let newArray = array.concat(Item);
      setAssignedRoomNotAllowedItems(newArray);
    }
  };

  const AssignBedType = async (Type) => {
    if (!assignedBedTypes.some((type) => type.bedTypeId === Type.bedTypeId)) {
      let array = [...assignedBedTypes];
      let newArray = array.concat(Type);
      setAssignedBedTypes(newArray);
    }
  };

  const UnassignService = async (Service) => {
    if (
      assignedRoomServices.some(
        (service) => service.roomServiceId === Service.roomServiceId
      )
    ) {
      let array = [...assignedRoomServices];
      let newArray = array.filter(
        (service) => service.roomServiceId !== Service.roomServiceId
      );
      setAssignedRoomServices(newArray);
    }
  };

  const UnassignAllowItem = async (Item) => {
    if (
      assignedRoomAllowedItems.some(
        (item) => item.roomAllowedItemId === Item.roomAllowedItemId
      )
    ) {
      let array = [...assignedRoomAllowedItems];
      let newArray = array.filter(
        (item) => item.roomAllowedItemId !== Item.roomAllowedItemId
      );
      setAssignedRoomAllowedItems(newArray);
    }
  };

  const UnassignNotAllowItem = async (Item) => {
    if (
      assignedRoomNotAllowedItems.some((item) => item.itemId === Item.itemId)
    ) {
      let array = [...assignedRoomNotAllowedItems];
      let newArray = array.filter((item) => item.itemId !== Item.itemId);
      setAssignedRoomNotAllowedItems(newArray);
    }
  };

  const UnassignBedType = async (Type) => {
    if (assignedBedTypes.some((type) => type.bedTypeId === Type.bedTypeId)) {
      let array = [...assignedBedTypes];
      let newArray = array.filter((type) => type.bedTypeId !== Type.bedTypeId);
      setAssignedBedTypes(newArray);
    }
  };

  const ChangeServiceDescription = (Service, Value) => {
    let index = assignedRoomServices.findIndex(
      (service) => service.roomServiceId === Service.roomServiceId
    );
    if (index !== -1) {
      let array = [...assignedRoomServices];
      array[index].description = Value;
      setAssignedRoomServices(array);
    }
  };

  const ChangeAllowItemDescription = (Item, Value) => {
    let index = assignedRoomAllowedItems.findIndex(
      (item) => item.roomAllowedItemId === Item.roomAllowedItemId
    );
    if (index !== -1) {
      let array = [...assignedRoomAllowedItems];
      array[index].description = Value;
      setAssignedRoomAllowedItems(array);
    }
  };

  const ChangeNotAllowItemDescription = (Item, Value) => {
    let index = assignedRoomNotAllowedItems.findIndex(
      (item) => item.itemId === Item.itemId
    );
    if (index !== -1) {
      let array = [...assignedRoomNotAllowedItems];
      array[index].description = Value;
      setAssignedRoomNotAllowedItems(array);
    }
  };

  const ChangeBedTypeNumberOfBeds = (Type, Value) => {
    let index = assignedBedTypes.findIndex(
      (type) => type.bedTypeId === Type.bedTypeId
    );
    if (index !== -1) {
      let array = [...assignedBedTypes];
      array[index].nbOfBeds = Value;
      setAssignedBedTypes(array);
    }
  };

  useEffect(() => {
    const GetRoomTypes = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "RoomType/GetRoomTypesListDropDown",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setRoomTypes(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetRoomTypes();
  }, [userToken]);

  useEffect(() => {
    const GetRoom = async () => {
      if (parseInt(room_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Room/GetRoomById?roomid=" + room_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          let result = await response.json();
          if (result.code === 0) {
            setRoomTypeId(result.message.roomDetails.roomTypeId);
            setShortDescription(result.message.roomDetails.shortDescription);
            setDescription(result.message.roomDetails.description);
            setWeekdayPrice(result.message.roomDetails.pricePerNightWeekday);
            setWeekendPrice(result.message.roomDetails.pricePerNightWeekend);
            setTotalBeds(result.message.roomDetails.totalnbofBeds);
            setTotalBedrooms(result.message.roomDetails.totalnbofBedrooms);
            setTotalGuests(result.message.roomDetails.nbofGuests);
            setTotalBaths(result.message.roomDetails.nbofBaths);
            setRoomName(result.message.roomDetails.roomName);
            setMaxInfants(result.message.roomDetails.maxOccupanciesInfants);
            setMaxChildren(result.message.roomDetails.maxOccupanciesChildren);
            setMaxAdults(result.message.roomDetails.maxOccupanciesAdults);
            setBreakfastPrice(result.message.roomDetails.breakfastPrice);
            setIsBreakfastIncluded(
              result.message.roomDetails.breakfastIncluded
            );
            setFloor(result.message.roomDetails.floor);
            setIsPublished(result.message.roomDetails.isPublished);
            setAssignedRoomAllowedItems(result.message.roomAllowedItems);
            setAssignedRoomNotAllowedItems(result.message.roomNotAllowedItems);
            setAssignedRoomServices(result.message.roomServices);
            setAssignedBedTypes(result.message.roomBedTypes);
            setAssignedImages(result.message.roomImages);
            setAssignedDiscoverImages(result.message.roomDiscover);
            setDisableSubmit(false);
            setIsLoading(false);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setDisableSubmit(false);
        setIsLoading(false);
      }
    };

    GetRoom();
  }, [userToken, room_id]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={(e) => {
              parseInt(room_id) === 0 ? AddRoom(e) : EditRoom(e);
            }}
          >
            Submit
          </button>
          <Link to={"/room"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Room Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      value={roomName}
                      onChange={(e) => setRoomName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Room Type<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={roomTypeId}
                      onChange={(e) => setRoomTypeId(parseInt(e.target.value))}
                    >
                      <option value={0}>Choose...</option>
                      {roomTypes.map((roomType, index) => (
                        <option value={roomType.roomTypeId} key={index}>
                          {roomType.typeName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>Floor</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="floor"
                      value={floor}
                      onChange={(e) => setFloor(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Total Number of Beds<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="beds"
                      value={totalBeds}
                      min="0"
                      onChange={(e) => setTotalBeds(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Total Number of Bedrooms
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="bedrooms"
                      value={totalBedrooms}
                      min="0"
                      onChange={(e) =>
                        setTotalBedrooms(parseInt(e.target.value))
                      }
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Total Number of Baths
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="baths"
                      value={totalBaths}
                      min="0"
                      onChange={(e) => setTotalBaths(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Total Number of Guests
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="guests"
                      value={totalGuests}
                      min="0"
                      onChange={(e) => setTotalGuests(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>Maximum Occupancies Adults</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="adults"
                      value={maxAdults}
                      min="0"
                      onChange={(e) => setMaxAdults(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>Maximum Occupancies Children</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="children"
                      value={maxChildren}
                      min="0"
                      onChange={(e) => setMaxChildren(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>Maximum Occupancies Infants</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="infants"
                      value={maxInfants}
                      min="0"
                      onChange={(e) => setMaxInfants(parseInt(e.target.value))}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Price per Weekday
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="price"
                      value={weekdayPrice}
                      min="0"
                      onChange={(e) =>
                        setWeekdayPrice(parseFloat(e.target.value))
                      }
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Price per Weekend
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="price"
                      value={weekendPrice}
                      min="0"
                      onChange={(e) =>
                        setWeekendPrice(parseFloat(e.target.value))
                      }
                    />
                  </div>
                  <div className="form-group mb-4 col-md-10">
                    <label>
                      Breakfast Price
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="price"
                      value={breakfastPrice}
                      min="0"
                      onChange={(e) =>
                        setBreakfastPrice(parseFloat(e.target.value))
                      }
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isBreakfastIncluded}
                          onChange={(e) =>
                            setIsBreakfastIncluded(e.target.checked)
                          }
                        />
                        Includes Breakfast
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Short Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      value={shortDescription}
                      onChange={(e) => setShortDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows="8"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Room Bed Types<span className="text-danger">*</span>
                    </label>
                    <br />
                    <Button
                      variant="primary"
                      className="mb-2 me-2"
                      onClick={OpenBedTypesModal}
                    >
                      Select Bed Types
                    </Button>
                    <Modal
                      className="fade bd-example-modal-lg"
                      show={isOpenAssignedBedTypes}
                      size="lg"
                    >
                      <Modal.Header>
                        <Modal.Title>Bed Types</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setIsOpenAssignedBedTypes(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        {roomBedTypes.length === 0 ? (
                          <div className="text-center">
                            <p className="m-0">There is no types yet</p>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <div
                              id="room_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                <thead>
                                  <tr role="row">
                                    <th>Id</th>
                                    <th>Item</th>
                                    <th>Publish Status</th>
                                    <th className="bg-none"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {roomBedTypes.map((item, index) => (
                                    <tr
                                      role="row"
                                      className={
                                        index % 2 === 0 ? "even" : "odd"
                                      }
                                      key={index}
                                    >
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {item.bedTypeId}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {item.bedTypeName}
                                        </span>
                                      </td>
                                      <td>
                                        {item.isPublished ? (
                                          <span className="font-w600 text-success">
                                            <i
                                              className="fa fa-check"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        ) : (
                                          <span className="font-w600 text-danger">
                                            <i
                                              className="fa fa-times"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        )}
                                      </td>
                                      {!assignedBedTypes.some(
                                        (type) =>
                                          type.bedTypeId === item.bedTypeId
                                      ) && (
                                        <td>
                                          <button
                                            type="submit"
                                            className="btn btn-outline-primary margin-right-15"
                                            onClick={() => AssignBedType(item)}
                                          >
                                            <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0V0z"
                                              ></path>
                                              <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                            </svg>
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger light"
                          onClick={() => setIsOpenAssignedBedTypes(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {assignedBedTypes.length > 0 && (
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Bed Type</th>
                                <th>
                                  Beds Number
                                  <span className="text-danger">*</span>
                                </th>
                                <th>
                                  Image
                                  <span className="text-danger">*</span>
                                </th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedBedTypes.map((item, index) => (
                                <tr
                                  role="row"
                                  className={index % 2 === 0 ? "even" : "odd"}
                                  key={index}
                                >
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {item.bedTypeId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {item.bedTypeName}
                                    </span>
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={item.nbOfBeds}
                                      onChange={(e) =>
                                        ChangeBedTypeNumberOfBeds(
                                          item,
                                          parseInt(e.target.value)
                                        )
                                      }
                                    ></input>
                                  </td>
                                  <td>
                                    {item.imageUrl === "" ? (
                                      <Button
                                        variant="primary"
                                        className="mb-2 me-2"
                                        onClick={() =>
                                          OpenImagesModal(1, item.bedTypeId)
                                        }
                                      >
                                        Select Image
                                      </Button>
                                    ) : (
                                      <img
                                        src={Global.PHOTO_URL + item.imageURL}
                                        style={{ width: "50%" }}
                                        alt="gallery"
                                        className="cursor-pointer"
                                        onClick={() =>
                                          OpenImagesModal(1, item.bedTypeId)
                                        }
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-primary"
                                      onClick={() => UnassignBedType(item)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M10 11V17"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14 11V17"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4 7H20"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Room Allowed Items<span className="text-danger">*</span>
                    </label>
                    <br />
                    <Button
                      variant="primary"
                      className="mb-2 me-2"
                      onClick={OpenAllowedItemsModal}
                    >
                      Select Allowed Items
                    </Button>
                    <Modal
                      className="fade bd-example-modal-lg"
                      show={isOpenAssignedAllowedItems}
                      size="lg"
                    >
                      <Modal.Header>
                        <Modal.Title>Allowed Items</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setIsOpenAssignedAllowedItems(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        {roomAllowedItems.length === 0 ? (
                          <div className="text-center">
                            <p className="m-0">There is no items yet</p>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <div
                              id="room_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                <thead>
                                  <tr role="row">
                                    <th>Id</th>
                                    <th>Item</th>
                                    <th>Publish Status</th>
                                    <th className="bg-none"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {roomAllowedItems.map((item, index) => (
                                    <tr
                                      role="row"
                                      className={
                                        index % 2 === 0 ? "even" : "odd"
                                      }
                                      key={index}
                                    >
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {item.roomAllowedItemId}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {item.itemName}
                                        </span>
                                      </td>
                                      <td>
                                        {item.isPublished ? (
                                          <span className="font-w600 text-success">
                                            <i
                                              className="fa fa-check"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        ) : (
                                          <span className="font-w600 text-danger">
                                            <i
                                              className="fa fa-times"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        )}
                                      </td>
                                      {!assignedRoomAllowedItems.some(
                                        (room) =>
                                          room.roomAllowedItemId ===
                                          item.roomAllowedItemId
                                      ) && (
                                        <td>
                                          <button
                                            type="submit"
                                            className="btn btn-outline-primary margin-right-15"
                                            onClick={() =>
                                              AssignAllowItem(item)
                                            }
                                          >
                                            <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0V0z"
                                              ></path>
                                              <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                            </svg>
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger light"
                          onClick={() => setIsOpenAssignedAllowedItems(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {assignedRoomAllowedItems.length > 0 && (
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Item</th>
                                <th>
                                  Description
                                  <span className="text-danger">*</span>
                                </th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedRoomAllowedItems.map((item, index) => (
                                <tr
                                  role="row"
                                  className={index % 2 === 0 ? "even" : "odd"}
                                  key={index}
                                >
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {item.roomAllowedItemId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {item.itemName}
                                    </span>
                                  </td>
                                  <td>
                                    <textarea
                                      className="form-control"
                                      rows="4"
                                      value={item.description}
                                      onChange={(e) =>
                                        ChangeAllowItemDescription(
                                          item,
                                          e.target.value
                                        )
                                      }
                                    ></textarea>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-primary"
                                      onClick={() => UnassignAllowItem(item)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M10 11V17"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14 11V17"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4 7H20"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Room Restricted Items
                      <span className="text-danger">*</span>
                    </label>
                    <br />
                    <Button
                      variant="primary"
                      className="mb-2 me-2"
                      onClick={OpenNotAllowedItemsModal}
                    >
                      Select Restricted Items
                    </Button>
                    <Modal
                      className="fade bd-example-modal-lg"
                      show={isOpenAssignedNotAllowedItems}
                      size="lg"
                    >
                      <Modal.Header>
                        <Modal.Title>Restricted Items</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() =>
                            setIsOpenAssignedNotAllowedItems(false)
                          }
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        {roomNotAllowedItems.length === 0 ? (
                          <div className="text-center">
                            <p className="m-0">There is no items yet</p>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <div
                              id="room_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                <thead>
                                  <tr role="row">
                                    <th>Id</th>
                                    <th>Item</th>
                                    <th className="bg-none"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {roomNotAllowedItems.map((item, index) => (
                                    <tr
                                      role="row"
                                      className={
                                        index % 2 === 0 ? "even" : "odd"
                                      }
                                      key={index}
                                    >
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {item.itemId}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {item.itemName}
                                        </span>
                                      </td>
                                      {!assignedRoomNotAllowedItems.some(
                                        (room) => room.itemId === item.itemId
                                      ) && (
                                        <td>
                                          <button
                                            type="submit"
                                            className="btn btn-outline-primary margin-right-15"
                                            onClick={() =>
                                              AssignNotAllowItem(item)
                                            }
                                          >
                                            <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0V0z"
                                              ></path>
                                              <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                            </svg>
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger light"
                          onClick={() =>
                            setIsOpenAssignedNotAllowedItems(false)
                          }
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {assignedRoomNotAllowedItems.length > 0 && (
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Item</th>
                                <th>
                                  Description
                                  <span className="text-danger">*</span>
                                </th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedRoomNotAllowedItems.map(
                                (item, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {item.itemId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {item.itemName}
                                      </span>
                                    </td>
                                    <td>
                                      <textarea
                                        className="form-control"
                                        rows="4"
                                        value={item.description}
                                        onChange={(e) =>
                                          ChangeNotAllowItemDescription(
                                            item,
                                            e.target.value
                                          )
                                        }
                                      ></textarea>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() =>
                                          UnassignNotAllowItem(item)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Room Services<span className="text-danger">*</span>
                    </label>
                    <br />
                    <Button
                      variant="primary"
                      className="mb-2 me-2"
                      onClick={OpenServicesModal}
                    >
                      Select Services
                    </Button>
                    <Modal
                      className="fade bd-example-modal-lg"
                      show={isOpenAssignedServices}
                      size="lg"
                    >
                      <Modal.Header>
                        <Modal.Title>Services</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setIsOpenAssignedServices(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        {roomServices.length === 0 ? (
                          <div className="text-center">
                            <p className="m-0">There is no services yet</p>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <div
                              id="room_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                <thead>
                                  <tr role="row">
                                    <th>Id</th>
                                    <th>Service</th>
                                    <th>Publish Status</th>
                                    <th className="bg-none"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {roomServices.map((service, index) => (
                                    <tr
                                      role="row"
                                      className={
                                        index % 2 === 0 ? "even" : "odd"
                                      }
                                      key={index}
                                    >
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {service.roomServiceId}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-16 font-w500 text-nowrap">
                                          {service.roomServiceName}
                                        </span>
                                      </td>
                                      <td>
                                        {service.isPublished ? (
                                          <span className="font-w600 text-success">
                                            <i
                                              className="fa fa-check"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        ) : (
                                          <span className="font-w600 text-danger">
                                            <i
                                              className="fa fa-times"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        )}
                                      </td>
                                      {!assignedRoomServices.some(
                                        (room) =>
                                          room.roomServiceId ===
                                          service.roomServiceId
                                      ) && (
                                        <td>
                                          <button
                                            type="submit"
                                            className="btn btn-outline-primary margin-right-15"
                                            onClick={() =>
                                              AssignService(service)
                                            }
                                          >
                                            <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill="none"
                                                d="M0 0h24v24H0V0z"
                                              ></path>
                                              <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                            </svg>
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger light"
                          onClick={() => setIsOpenAssignedServices(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {assignedRoomServices.length > 0 && (
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Service</th>
                                <th>
                                  Description
                                  <span className="text-danger">*</span>
                                </th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedRoomServices.map((service, index) => (
                                <tr
                                  role="row"
                                  className={index % 2 === 0 ? "even" : "odd"}
                                  key={index}
                                >
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {service.roomServiceId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {service.roomServiceName}
                                    </span>
                                  </td>
                                  <td>
                                    <textarea
                                      className="form-control"
                                      rows="4"
                                      value={service.description}
                                      onChange={(e) =>
                                        ChangeServiceDescription(
                                          service,
                                          e.target.value
                                        )
                                      }
                                    ></textarea>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-primary"
                                      onClick={() => UnassignService(service)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M10 11V17"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M14 11V17"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4 7H20"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3 col-md-12">
                    <label>
                      Room Images<span className="text-danger">*</span>
                    </label>
                    <br />
                    <Button
                      variant="primary"
                      className="mb-2 me-2"
                      onClick={() => OpenImagesModal(2, 0)}
                    >
                      Select Images
                    </Button>
                    <Modal
                      className="fade bd-example-modal-lg"
                      show={isOpenAssignedImages}
                      size="lg"
                    >
                      <Modal.Header>
                        <Modal.Title>Gallery</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setIsOpenAssignedImages(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        {roomImages.length === 0 ? (
                          <div className="text-center">
                            <p className="m-0">
                              There is no uploaded images yet
                            </p>
                          </div>
                        ) : (
                          <Fragment>
                            <div className="row">
                              {roomImages.map((image, index) => (
                                <div
                                  data-src={Global.PHOTO_URL + image.imageUrl}
                                  className="col-lg-3 col-md-6 mb-4"
                                  key={index}
                                >
                                  <img
                                    src={Global.PHOTO_URL + image.imageUrl}
                                    style={{ width: "100%" }}
                                    alt="gallery"
                                    className="cursor-pointer"
                                    onClick={() => AssignImage(image)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing {activePag.current * pageLimit + 1}
                                    of {totalCount} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                      to={"/room/" + room_id}
                                      onClick={() =>
                                        activePag.current > 0 &&
                                        ChangePage(activePag.current - 1)
                                      }
                                    >
                                      <i className="fa fa-angle-double-left"></i>{" "}
                                      Previous
                                    </Link>
                                    <span>
                                      {paggination.map((number, i) => (
                                        <Link
                                          key={i}
                                          to={"/room/" + room_id}
                                          className={`paginate_button  ${
                                            activePag.current === i
                                              ? "current"
                                              : ""
                                          } `}
                                          onClick={() => ChangePage(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>

                                    <Link
                                      className="paginate_button next"
                                      to={"/room/" + room_id}
                                      onClick={() =>
                                        activePag.current + 1 <
                                          paggination.length &&
                                        ChangePage(activePag.current + 1)
                                      }
                                    >
                                      Next{" "}
                                      <i className="fa fa-angle-double-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger light"
                          onClick={() => setIsOpenAssignedImages(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {assignedImages && assignedImages.length > 0 && (
                      // <LightGallery
                      //   // onInit={onInit}
                      //   speed={500}
                      //   plugins={[lgThumbnail, lgZoom]}
                      //   elementClassNames="row"
                      //   ref={galleryRef}
                      //   key={refreshKey}
                      // >
                      <div className="d-flex flex-row flex-wrap gap-5">
                        {assignedImages.map((image, index) => {
                          return (
                            <div
                              data-src={Global.PHOTO_URL + image.imageUrl}
                              className="col-lg-3 col-md-6 mb-4 room-image"
                              key={index}
                            >
                              <img
                                src={Global.PHOTO_URL + image.imageUrl}
                                style={{ width: "100%" }}
                                alt="gallery"
                                className="cursor-pointer "
                              />
                              <br />
                              <br />
                              <input
                                type="number"
                                className="form-control"
                                value={image.galleryOrder}
                                // onFocus={() =>
                                //   setRefreshKey((prevKey) => prevKey + 1)
                                // }
                                onChange={(e) =>
                                  ChangeGalleryOrder(
                                    parseInt(e.target.value),
                                    image.galleryId
                                  )
                                }
                              />
                              <button
                                className="btn-delete"
                                onClick={() => UnassignImage(image)}
                              >
                                <svg
                                  viewport="0 0 12 12"
                                  version="1.1"
                                  width="20px"
                                  height="15px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <line
                                    x1="1"
                                    y1="11"
                                    x2="11"
                                    y2="1"
                                    stroke="black"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="1"
                                    y1="1"
                                    x2="11"
                                    y2="11"
                                    stroke="black"
                                    strokeWidth="2"
                                  />
                                </svg>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                      // </LightGallery>
                    )}
                  </div>
                  <div className="mb-3 col-md-12">
                    <label>
                      Room Discover Images (Preferred size: 600*785)
                      <span className="text-danger">*</span>
                    </label>
                    <br />
                    <Button
                      variant="primary"
                      className="mb-2 me-2"
                      onClick={() => OpenImagesModal(3, 0)}
                    >
                      Select Images
                    </Button>
                    <Modal
                      className="fade bd-example-modal-lg"
                      show={isOpenAssignedDiscoverImages}
                      size="lg"
                    >
                      <Modal.Header>
                        <Modal.Title>Gallery</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={() => setIsOpenAssignedDiscoverImages(false)}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        {roomImages.length === 0 ? (
                          <div className="text-center">
                            <p className="m-0">
                              There is no uploaded images yet
                            </p>
                          </div>
                        ) : (
                          <Fragment>
                            <div className="row">
                              {roomImages.map((image, index) => (
                                <div
                                  data-src={Global.PHOTO_URL + image.imageUrl}
                                  className="col-lg-3 col-md-6 mb-4"
                                  key={index}
                                >
                                  <img
                                    src={Global.PHOTO_URL + image.imageUrl}
                                    style={{ width: "100%" }}
                                    alt="gallery"
                                    className="cursor-pointer"
                                    onClick={() => AssignDiscoverImage(image)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing {activePag.current * pageLimit + 1}
                                    of {totalCount} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                      to={"/room/" + room_id}
                                      onClick={() =>
                                        activePag.current > 0 &&
                                        ChangePage(activePag.current - 1)
                                      }
                                    >
                                      <i className="fa fa-angle-double-left"></i>{" "}
                                      Previous
                                    </Link>
                                    <span>
                                      {paggination.map((number, i) => (
                                        <Link
                                          key={i}
                                          to={"/room/" + room_id}
                                          className={`paginate_button  ${
                                            activePag.current === i
                                              ? "current"
                                              : ""
                                          } `}
                                          onClick={() => ChangePage(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>

                                    <Link
                                      className="paginate_button next"
                                      to={"/room/" + room_id}
                                      onClick={() =>
                                        activePag.current + 1 <
                                          paggination.length &&
                                        ChangePage(activePag.current + 1)
                                      }
                                    >
                                      Next{" "}
                                      <i className="fa fa-angle-double-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="danger light"
                          onClick={() => setIsOpenAssignedImages(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {assignedDiscoverImages &&
                      assignedDiscoverImages.length > 0 && (
                        // <LightGallery
                        //   // onInit={onInit}
                        //   speed={500}
                        //   plugins={[lgThumbnail, lgZoom]}
                        //   elementClassNames="row"
                        //   ref={galleryRef}
                        //   key={refreshKey}
                        // >
                        <div className="d-flex flex-row flex-wrap gap-5">
                          {assignedDiscoverImages.map((image, index) => {
                            return (
                              <div
                                data-src={Global.PHOTO_URL + image.imageUrl}
                                className="col-lg-3 col-md-6 mb-4 room-image"
                                key={index}
                              >
                                <img
                                  src={Global.PHOTO_URL + image.imageUrl}
                                  style={{ width: "100%" }}
                                  alt="gallery"
                                  className="cursor-pointer "
                                />
                                <br />
                                <br />
                                <input
                                  type="number"
                                  className="form-control"
                                  value={image.galleryOrder}
                                  // onFocus={() =>
                                  //   setRefreshKey((prevKey) => prevKey + 1)
                                  // }
                                  onChange={(e) =>
                                    ChangeDiscoverGalleryOrder(
                                      parseInt(e.target.value),
                                      image.galleryId
                                    )
                                  }
                                />
                                <button
                                  className="btn-delete"
                                  onClick={() => UnassignDiscoverImage(image)}
                                >
                                  <svg
                                    viewport="0 0 12 12"
                                    version="1.1"
                                    width="20px"
                                    height="15px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <line
                                      x1="1"
                                      y1="11"
                                      x2="11"
                                      y2="1"
                                      stroke="black"
                                      strokeWidth="2"
                                    />
                                    <line
                                      x1="1"
                                      y1="1"
                                      x2="11"
                                      y2="11"
                                      stroke="black"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                        // </LightGallery>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RoomManage;
