import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const MediaAdd = () => {
  const { userToken, userId } = useSelector((state) => state.credential);

  const [isLoading, setIsLoading] = useState(false);

  const [images, setImages] = useState([]);
  const handleDrop = (files) => {
    setIsLoading(true);
    var formdata = new FormData();
    for (let i = 0; i < files.length; i++) {
      formdata.append("Images", files[i]);
    }
    formdata.append("Sender", "A");
    formdata.append("SenderID", userId);

    fetch(Global.API_URL+"Others/UploadImage", {
      method: "POST",
      headers: {
        "bs-api-key": Global.bs_api_key,
        AuthorizationUser: userToken,
      },
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        let array = [...images];
        let newArray = array.concat(result);
        setImages(newArray);
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <Link to={"/media"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label>Images</label>
                    <Dropzone
                      onDrop={handleDrop}
                      accept="image/jpeg,image/png,image/jpg,image/svg"
                      onDropRejected={() =>
                        toast.error("Upload Error! Invalid Uploaded Type.")
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "form-control h-10 dropzone",
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>Drag'n'drop images, or click to select files</p>
                        </div>
                      )}
                    </Dropzone>
                  </div>

                  <div className="mb-3 col-md-12">
                    <label>Uploaded Images</label>
                    <LightGallery
                      // onInit={onInit}
                      speed={500}
                      plugins={[lgThumbnail, lgZoom]}
                      elementClassNames="row"
                    >
                      {images.map((image, index) => (
                        <div
                          data-src={Global.PHOTO_URL + image}
                          className="col-lg-3 col-md-6 mb-4"
                          key={index}
                        >
                          <img
                            src={Global.PHOTO_URL + image}
                            style={{ width: "100%" }}
                            alt="gallery"
                            className="cursor-pointer"
                          />
                        </div>
                      ))}
                    </LightGallery>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MediaAdd;
