import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const MediaList = () => {
  const { userToken } = useSelector((state) => state.credential);

  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [refreshKey, setRefreshKey] = useState(0);
  const pageLimit = 24;
  const activePag = useRef(0);

  // const [selectBtn, setSelectBtn] = useState("Newest");

  let paggination = Array(Math.ceil(totalCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const ChangePage = (i) => {
    activePag.current = i;

    changeData(
      activePag.current * pageLimit,
      (activePag.current + 1) * pageLimit
    );
  };

  const changeData = async (first, sec) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        Global.API_URL +
          "Gallery/GetGalleryList?Skip=" +
          first +
          "&Take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalCount(result.count);
        setImages(array);
        setIsLoading(false);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UnassignImage = async (Image) => {
    setRefreshKey((prevKey) => prevKey + 1);
    try {
      const response = await fetch(
        Global.API_URL +
          "Gallery/DeleteGallery?galleryId=" +
          Image.galleryId ,
        {
          method: "Delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      if (response.ok) {
        if (images.some((image) => image.galleryId === Image.galleryId)) {
          let array = [...images];
          let newArray = array.filter(
            (image) => image.galleryId !== Image.galleryId
          );
          setImages(newArray);
          setRefreshKey((prevKey) => prevKey + 1);
        }
      }else{
        const result=await response.json()
        toast.error(result.message)
     
      }
    } catch (error) {
      console.log(error);
    }


  };

  useEffect(() => {
    const GetImages = async () => {
      try {
        const response = await fetch(
          Global.API_URL +
            "Gallery/GetGalleryList?Skip=0&Take=24",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          if(result.count){
            setTotalCount(result.count);
          }else{
            setTotalCount(0)
          }
          
          setImages(array);
          setIsLoading(false);
        } else if(result.code === 1) {
          setIsLoading(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetImages();
  }, [userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <Link
            to={"/media/add"}
            className="btn btn-secondary margin-right-15"
          >
            + Add Media
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                {images.length === 0 ? (
                  <div className="text-center">
                    <p className="m-0">There is no uploaded images yet</p>
                  </div>
                ) : (
                  <Fragment>
                    <div className="row">
                      <div className="mb-3 col-md-12">
                        <label>Uploaded Images</label>
                        <LightGallery
                          // onInit={onInit}
                          speed={500}
                          plugins={[lgThumbnail, lgZoom]}
                          elementClassNames="row"
                          key={refreshKey}
                        >
                          {images.map((image, index) => (
                            <div
                              data-src={Global.PHOTO_URL + image.imageUrl}
                              className="col-lg-3 col-md-6 mb-4 room-image"
                              key={index}
                            >
                              <img
                                src={Global.PHOTO_URL + image.imageUrl}
                                style={{ width: "100%" }}
                                alt="gallery"
                                className="cursor-pointer"
                              />
                               <button
                                className="btn-delete"
                                onClick={() => UnassignImage(image)}
                              >
                                <svg
                                  viewport="0 0 12 12"
                                  version="1.1"
                                  width="20px"
                                  height="15px"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <line
                                    x1="1"
                                    y1="11"
                                    x2="11"
                                    y2="1"
                                    stroke="black"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="1"
                                    y1="1"
                                    x2="11"
                                    y2="11"
                                    stroke="black"
                                    strokeWidth="2"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </LightGallery>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <div
                        id="room_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                          <div className="dataTables_info">
                            Showing {activePag.current * pageLimit + 1}
                            of {totalCount} entries
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers mb-0"
                            id="example2_paginate"
                          >
                            <Link
                              className="paginate_button previous disabled"
                              to={"/media"}
                              onClick={() =>
                                activePag.current > 0 &&
                                ChangePage(activePag.current - 1)
                              }
                            >
                              <i className="fa fa-angle-double-left"></i>{" "}
                              Previous
                            </Link>
                            <span>
                              {paggination.map((number, i) => (
                                <Link
                                  key={i}
                                  to={"/media"}
                                  className={`paginate_button  ${
                                    activePag.current === i ? "current" : ""
                                  } `}
                                  onClick={() => ChangePage(i)}
                                >
                                  {number}
                                </Link>
                              ))}
                            </span>

                            <Link
                              className="paginate_button next"
                              to={"/media"}
                              onClick={() =>
                                activePag.current + 1 < paggination.length &&
                                ChangePage(activePag.current + 1)
                              }
                            >
                              Next <i className="fa fa-angle-double-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MediaList;
