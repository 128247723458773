import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";
import { useSelector } from "react-redux";

const RoomTypeManage = () => {
  const token = useSelector((state) => state.credential.userToken);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [roomType, setRoomType] = useState("");
  const [isPublished, setIsPublished] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();

  const validForm = roomType.trim() !== "";
  const Submit = async (e) => {
    e.preventDefault();
    if (!validForm) {
      toast.error("Fill all the  fields");
    } else {
      if (parseInt(id) === 0) {
        try {
          const response = await fetch(
            Global.API_URL + "RoomType/AddRoomType",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                "AuthorizationUser": token,
              },
              body: JSON.stringify({
                typeName: roomType,
                isPublished: isPublished,
              }),
            }
          );
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              toast.success(result.message);
              navigate("/room-type");
            } else {
              toast.error(result.message);
            }
          } else {
            const result = await response.json();
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      } else {
        try {
          const response = await fetch(
            Global.API_URL + "RoomType/EditRoomType?roomtypeid=" + id,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                "AuthorizationUser": token,
              },
              body: JSON.stringify({
                typeName: roomType,
                isPublished: isPublished,
              }),
            }
          );
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              toast.success(result.message);
              navigate("/room-type");
            } else {
              toast.error(result.message);
            }
          } else {
            const result = await response.json();
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      }
    }
    setDisableSubmit(false);
  };
  useEffect(() => {
    if (parseInt(id) !== 0) {
      const GetRoomTypes = async () => {
        try {
          const response = await fetch(
            Global.API_URL + "RoomType/GetRoomTypeById?roomtypeid=" + id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                "AuthorizationUser": token,
              },
            }
          );
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              setRoomType(result.message.typeName);
              setIsPublished(result.message.isPublished);
            } else {
              toast.error(result.message);
            }
          } else {
            const result = await response.json();
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      };

      GetRoomTypes();
    }
  }, [id,token]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            onClick={(e) => Submit(e)}
            disabled={disableSubmit}
          >
            Submit
          </button>
          <Link to="/room-type" className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <label>
                    Room Type Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Room Type Name"
                    value={roomType}
                    onChange={(e) => setRoomType(e.target.value)}
                  />
                </div>
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <div className="form-check form-check-inline mt-6">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={isPublished}
                        checked={isPublished}
                        onChange={(e) =>
                          setIsPublished(
                            e.target.value === "true" ? false : true
                          )
                        }
                      />
                      Published
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RoomTypeManage;
