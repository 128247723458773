import React, { useContext, useEffect, useState } from "react";
import { Global } from "../../../Global";
import { toast } from "react-toastify";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import ReservationStats from "./Dashboard/ReservationStats";
import LatestReview from "./Dashboard/LatestReview";

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [dashboardInfo, setDashboardInfo] = useState({
    availableRoomsToday: 0,
    latestRatings: [
      {
        firstName: "",
        hotelGuestEmail: "",
        hotelRatingId: 0,
        isRegistered: true,
        lastName: "",
        phoneNumber: "",
        starRating: 0,
      },
    ],
    nbOfBookings: 0,
    nbOfCustomers: 0,
    nbOfEmployees: 0,
    nbOfRooms: 0,
    soldOutRoomsToday: 0,
  });
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);
  useEffect(() => {
    const fetchdashboardInfo = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Dashboard/GetDashboardInfo",
          {
            method: "Get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          setDashboardInfo(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };
    fetchdashboardInfo();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="20"
                            viewBox="0 0 28 20"
                          >
                            <path
                              d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z"
                              transform="translate(-2 -6)"
                              fill="var(--primary-svg)"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {dashboardInfo.nbOfBookings}
                          </h2>
                          <p className="mb-0 text-nowrap">Bookings</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="20"
                            viewBox="0 0 28 20"
                          >
                            <path
                              d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z"
                              transform="translate(-2 -6)"
                              fill="var(--primary-svg)"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {dashboardInfo.nbOfRooms}
                          </h2>
                          <p className="mb-0 text-nowrap ">Rooms</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                          >
                            <path
                              data-name="Path 1957"
                              d="M129.035,178.842v2.8a5.6,5.6,0,0,0,5.6,5.6h14a5.6,5.6,0,0,0,5.6-5.6v-16.8a5.6,5.6,0,0,0-5.6-5.6h-14a5.6,5.6,0,0,0-5.6,5.6v2.8a1.4,1.4,0,0,0,2.8,0v-2.8a2.8,2.8,0,0,1,2.8-2.8h14a2.8,2.8,0,0,1,2.8,2.8v16.8a2.8,2.8,0,0,1-2.8,2.8h-14a2.8,2.8,0,0,1-2.8-2.8v-2.8a1.4,1.4,0,0,0-2.8,0Zm10.62-7-1.81-1.809a1.4,1.4,0,1,1,1.98-1.981l4.2,4.2a1.4,1.4,0,0,1,0,1.981l-4.2,4.2a1.4,1.4,0,1,1-1.98-1.981l1.81-1.81h-12.02a1.4,1.4,0,1,1,0-2.8Z"
                              transform="translate(-126.235 -159.242)"
                              fill="var(--primary-svg)"
                              fillRule="evenodd"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {dashboardInfo.nbOfCustomers}
                          </h2>
                          <p className="mb-0">Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="card booking">
                    <div className="card-body">
                      <div className="booking-status d-flex align-items-center">
                        <span>
                          <svg
                            id="_009-log-out"
                            data-name="009-log-out"
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                          >
                            <path
                              data-name="Path 1957"
                              d="M151.435,178.842v2.8a5.6,5.6,0,0,1-5.6,5.6h-14a5.6,5.6,0,0,1-5.6-5.6v-16.8a5.6,5.6,0,0,1,5.6-5.6h14a5.6,5.6,0,0,1,5.6,5.6v2.8a1.4,1.4,0,0,1-2.8,0v-2.8a2.8,2.8,0,0,0-2.8-2.8h-14a2.8,2.8,0,0,0-2.8,2.8v16.8a2.8,2.8,0,0,0,2.8,2.8h14a2.8,2.8,0,0,0,2.8-2.8v-2.8a1.4,1.4,0,0,1,2.8,0Zm-10.62-7,1.81-1.809a1.4,1.4,0,1,0-1.98-1.981l-4.2,4.2a1.4,1.4,0,0,0,0,1.981l4.2,4.2a1.4,1.4,0,1,0,1.98-1.981l-1.81-1.81h12.02a1.4,1.4,0,1,0,0-2.8Z"
                              transform="translate(-126.235 -159.242)"
                              fill="var(--primary-svg)"
                              fillRule="evenodd"
                            />
                          </svg>
                        </span>
                        <div className="ms-4">
                          <h2 className="mb-0 font-w600">
                            {dashboardInfo.nbOfEmployees}
                          </h2>
                          <p className="mb-0">Employees</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-12">
                      <ReservationStats />
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="card bg-secondary">
                        <div className="card-body">
                          <div className="d-flex align-items-end pb-4 justify-content-between">
                            <span className="fs-14 font-w500 text-white">
                              Available Room Today
                            </span>
                            <span className="fs-20 font-w600 text-white">
                              <span className="pe-2"></span>
                              {dashboardInfo.availableRoomsToday}
                            </span>
                          </div>
                          <div className="progress default-progress h-auto">
                            <div
                              className="progress-bar bg-white progress-animated"
                              style={{
                                width:
                                  (dashboardInfo.availableRoomsToday * 100) /
                                    dashboardInfo.nbOfRooms +
                                  "%",
                                height: "13px",
                              }}
                            >
                              <span className="sr-only">
                                {(dashboardInfo.availableRoomsToday * 100) /
                                  dashboardInfo.nbOfRooms}
                                % Complete
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="card bg-secondary">
                        <div className="card-body">
                          <div className="d-flex align-items-end pb-4 justify-content-between">
                            <span className="fs-14 font-w500 text-white">
                              Sold Out Room Today
                            </span>
                            <span className="fs-20 font-w600 text-white">
                              <span className="pe-2"></span>
                              {dashboardInfo.soldOutRoomsToday}
                            </span>
                          </div>
                          <div className="progress default-progress h-auto">
                            <div
                              className="progress-bar bg-white progress-animated"
                              style={{
                                width:
                                  (dashboardInfo.soldOutRoomsToday * 100) /
                                    dashboardInfo.nbOfRooms +
                                  "%",
                                height: "13px",
                              }}
                            >
                              <span className="sr-only">
                                {(dashboardInfo.soldOutRoomsToday * 100) /
                                  dashboardInfo.nbOfRooms}{" "}
                                % Complete
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="fs-20">Latest Review by Customers</h4>
                </div>
                <div className="card-body pt-0">
                  <LatestReview reviews={dashboardInfo.latestRatings} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
