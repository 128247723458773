import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { Global } from "../../../../../Global";
import { toast } from "react-toastify";

const Inbox = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(0);
  const sort = 10;
  const activePag = useRef(0);
  const [notificationGroup, setNotificationGroup] = useState("All");
  const [isRead, setIsRead] = useState(false);
  const [notification, setNotification] = useState({
    body: "",
    clickCount: 0,
    createdDate: "",
    isDeleted: false,
    isOpened: false,
    isStarred: false,
    notificationId: 0,
    notificationTypeId: 0,
    openedDate: "",
    referenceId: 0,
    title: "",
  });

  const chageData = async (first, sec) => {
    let offset = 0,
    date = "",
    newDate = "";
    try {
      const response = await fetch(
        Global.API_URL +
          "Notification/GetNotifications?skip=" +
          first +
          "&take=10",
        {
          method: "Get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Global.HostAPI,
            "bs-api-key": Global.bs_api_key,
          },
        }
      );

      const result = await response.json();
      if (result.code === 0) {
        result.message.forEach((notification) => {
          offset = new Date(notification.createdDate).getTimezoneOffset();
          date = new Date(notification.createdDate);
          newDate = new Date(date.getTime() - offset * 60 * 1000);
          notification.createdDate = newDate;
        });
        setData(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };
  useEffect(() => {
    const GetNotifications = async () => {
      let offset = 0,
      date = "",
      newDate = "";
      try {
        const response = await fetch(
          Global.API_URL + "Notification/GetNotifications?skip=0&take=10",
          {
            method: "Get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          result.message.forEach((notification) => {
            offset = new Date(notification.createdDate).getTimezoneOffset();
            date = new Date(notification.createdDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            notification.createdDate = newDate;
          });
          setData(result.message);
          setTotalCount(result.count);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };
    GetNotifications();
  }, []);

  let paggination = Array(Math.ceil(totalCount / sort))
    .fill()
    .map((_, i) => i + 1);

  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
  };
  const ChangeGroup = async (group) => {
    setNotificationGroup(group);
  };
  const ShowNotification = async (message) => {
    if (parseInt(notification.notificationTypeId) === 1) {
      navigate("/customer/" + notification.referenceId);
    } else if (parseInt(notification.notificationTypeId) === 2) {
      navigate("/booking/" + notification.referenceId);
    }else if (parseInt(notification.notificationTypeId) === 4) {
      navigate("/subscribers");
    }else if (parseInt(notification.notificationTypeId) === 3){
      navigate("/contact");
    }
  };

  function getSeconds(d) {
    var d1 = new Date(d);
    var d2 = new Date();
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return Math.floor((t2 - t1) / 1000);
  }

  function getMinutes(d) {
    var d1 = new Date(d);
    var d2 = new Date();
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return Math.floor((t2 - t1) / (60 * 1000));
  }

  function getHours(d) {
    var d1 = new Date(d);
    var d2 = new Date();
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return Math.floor((t2 - t1) / (3600 * 1000));
  }
  function getDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  }
  function getNotificationDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const formattedDate = date.toLocaleString('en-US', options);
    
    return formattedDate;
  }
  const ChangeStarred = async (Id, isStarred) => {
    if (isStarred) {
      try {
        const response = await fetch(
          Global.API_URL +
            "Notification/MarkNotificationAsUnstarred?notificationId=" +
            Id,
          {
            method: "Patch",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          chageData(activePag.current * sort, (activePag.current + 1) * sort);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    } else {
      try {
        const response = await fetch(
          Global.API_URL +
            "Notification/MarkNotificationAsStarred?notificationId=" +
            Id,
          {
            method: "Patch",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          chageData(activePag.current * sort, (activePag.current + 1) * sort);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    }
  };
  const ChangeTrash = async (Id, isDeleted) => {
    var not  ={...notification}
    if (isDeleted) {
      try {
        const response = await fetch(
          Global.API_URL +
            "Notification/MarkNotificationAsUndeleted?notificationId=" +
            Id,
          {
            method: "Patch",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
        if(notification.notificationId !== 0 ){
        
          not.isDeleted=false
          setNotification(not)
        }
          chageData(activePag.current * sort, (activePag.current + 1) * sort);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    } else {
      try {
        const response = await fetch(
          Global.API_URL +
            "Notification/MarkNotificationAsDeleted?notificationId=" +
            Id,
          {
            method: "Patch",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          if(notification.notificationId !== 0 ){
            not.isDeleted=true
            setNotification(not)
          }
          chageData(activePag.current * sort, (activePag.current + 1) * sort);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    }
  };
  const ChangeRead = async (Id, isOpened) => {
    if (isOpened) {
      try {
        const response = await fetch(
          Global.API_URL +
            "Notification/MarkNotificationAsUnread?notificationId=" +
            Id,
          {
            method: "Patch",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          chageData(activePag.current * sort, (activePag.current + 1) * sort);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    } else {
      try {
        const response = await fetch(
          Global.API_URL +
            "Notification/MarkNotificationAsRead?notificationId=" +
            Id,
          {
            method: "Patch",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          chageData(activePag.current * sort, (activePag.current + 1) * sort);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={notificationGroup}
        motherMenu="Notification"
        pageContent="Notification"
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              {isRead ? (
                <div className="row">
                  <div className="col-12">
                    <div className="email-right-box">
                      <div className="row">
                        <div className="col-12">
                          <div className="right-box-padding">
                            <div className="toolbar mb-4" role="toolbar">
                              <div className="btn-group mb-1">
                                <button
                                  type="button"
                                  className="btn btn-primary light px-3"
                                  onClick={() => {
                                    setIsRead(false);
                                    setNotification({
                                      body: "",
                                      clickCount: 0,
                                      createdDate: "",
                                      isDeleted: false,
                                      isOpened: false,
                                      isStarred: false,
                                      notificationId: 0,
                                      notificationTypeId: 0,
                                      openedDate: "",
                                      referenceId: 0,
                                      title: "",
                                    });
                                    chageData(
                                      activePag.current * sort,
                                      (activePag.current + 1) * sort
                                    );
                                  }}
                                >
                                  <i className="fas fa-arrow-left"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary light px-3"
                                  onClick={() => ShowNotification()}
                                >
                                  <i className="fa fa-eye"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary light px-3"
                                  onClick={() =>
                                    ChangeTrash(
                                      notification.notificationId,
                                      notification.isDeleted
                                    )
                                  }
                                >
                                  {notification.isDeleted ? (
                                    <i className="fa fa-retweet deleted"></i>
                                  ) : (
                                    <i className="fa fa-trash"></i>
                                  )}
                                </button>
                              </div>
                            </div>
                            <div className="read-content">
                              <div className="media pt-3 d-sm-flex d-block justify-content-between">
                                <div className="clearfix mb-3 d-flex">
                                  <div className="media-body me-2">
                                    <h5 className="text-primary mb-0 mt-1">
                                      {notification.title}
                                    </h5>
                                    <p className="mb-0">
                                      {getNotificationDate(
                                        notification.createdDate
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="read-content-body">
                                <p className="mb-2">{notification.body}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-xl-3 col-lg-4">
                    <div className="email-left-box">
                      <div className="p-0"></div>
                      <div className="mail-list rounded mt-4">
                        <Link
                          to="/notification-inbox"
                          onClick={() => ChangeGroup("All")}
                          className={`list-group-item  ${
                            notificationGroup === "All" ? "active" : ""
                          } `}
                        >
                          <i className="fa fa-inbox font-18 align-middle me-2"></i>
                          All
                          <span className="badge badge-primary badge-sm float-end">
                            {totalCount}
                          </span>
                        </Link>
                        <Link
                          onClick={() => ChangeGroup("Read")}
                          to="/notification-inbox"
                          className={`list-group-item  ${
                            notificationGroup === "Read" ? "active" : ""
                          } `}
                        >
                          <i className="fa fa-envelope-open font-18 align-middle me-2"></i>
                          Read
                        </Link>
                        <Link
                          to="/notification-inbox"
                          onClick={() => ChangeGroup("Important")}
                          className={`list-group-item  ${
                            notificationGroup === "Important" ? "active" : ""
                          } `}
                        >
                          <i className="fa fa-star font-18 align-middle me-2"></i>
                          Important
                        </Link>
                        <Link
                          to="/notification-inbox"
                          onClick={() => ChangeGroup("Trash")}
                          className={`list-group-item  ${
                            notificationGroup === "Trash" ? "active" : ""
                          } `}
                        >
                          <i className="fa fa-trash font-18 align-middle me-2"></i>
                          Trash
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-8">
                    {notificationGroup === "All" && data.length > 0 ? (
                      <div className="email-right-box">
                        {data.map((message, index) => (
                          <div className="email-list mt-3" key={index}>
                            <div
                              className={`message ${
                                message.isOpened ? "opened-message" : ""
                              }`}
                            >
                              <div>
                                <div className="d-flex message-single">
                                  <div className="ms-2">
                                    <button
                                      className="border-0 bg-transparent align-middle p-0"
                                      onClick={() =>
                                        ChangeStarred(
                                          message.notificationId,
                                          message.isStarred
                                        )
                                      }
                                    >
                                      <i
                                        className={`fa fa-star ${
                                          message.isStarred ? "starred" : ""
                                        }`}
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                  <div className="ms-2">
                                    <button
                                      className="border-0 bg-transparent align-middle p-0"
                                      onClick={() =>
                                        ChangeTrash(
                                          message.notificationId,
                                          message.isDeleted
                                        )
                                      }
                                    >
                                      <i
                                        className={`${
                                          message.isDeleted
                                            ? "fa fa-retweet deleted"
                                            : "fa fa-trash"
                                        }`}
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                  <div className="ms-2">
                                    <button
                                      className="border-0 bg-transparent align-middle p-0"
                                      onClick={() =>
                                        ChangeRead(
                                          message.notificationId,
                                          message.isOpened
                                        )
                                      }
                                    >
                                      <i
                                        className={` ${
                                          message.isOpened
                                            ? "fa fa-envelope-open read "
                                            : "fa fa-envelope"
                                        }`}
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                                <Link
                                  to="/notification-inbox"
                                  className="col-mail col-mail-2"
                                  onClick={() => {
                                    setIsRead(true);
                                    ChangeRead(
                                      message.notificationId,
                                      message.isOpened
                                    );
                                    setNotification(message);
                                  }}
                                >
                                  <div className="subject">
                                    {message.body.length > 70
                                      ? message.body.substring(0, 70) + "..."
                                      : message.body}
                                  </div>
                                  <div className="date">
                                    {getSeconds(message.createdDate) < 60
                                      ? "Just now"
                                      : getMinutes(message.createdDate) < 60
                                      ? getMinutes(message.createdDate) +
                                        " minutes ago"
                                      : getHours(message.createdDate) < 12
                                      ? getHours(message.createdDate) +
                                        " hours ago"
                                      : getDate(message.createdDate)}
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="row mt-4">
                          <div className="col-12 ps-3">
                            <nav>
                              <ul className="pagination pagination-gutter pagination-primary pagination-sm no-bg">
                                <li className="page-item page-indicator">
                                  <Link
                                    className="page-link"
                                    to="/notification-inbox"
                                    onClick={() =>
                                      activePag.current > 0 &&
                                      onClick(activePag.current - 1)
                                    }
                                  >
                                    <i className="la la-angle-left"></i>
                                  </Link>
                                </li>
                                {paggination.map((number, i) => (
                                  <li
                                    key={i}
                                    className={`page-item  ${
                                      activePag.current === i ? "active" : ""
                                    } `}
                                    onClick={() => onClick(i)}
                                  >
                                    <Link
                                      className="page-link"
                                      to="/notification-inbox"
                                    >
                                      {number}
                                    </Link>
                                  </li>
                                ))}

                                <li className="page-item page-indicator">
                                  <Link
                                    className="page-link"
                                    to="/notification-inbox"
                                    onClick={() =>
                                      activePag.current + 1 <
                                        paggination.length &&
                                      onClick(activePag.current + 1)
                                    }
                                  >
                                    <i className="la la-angle-right"></i>
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {notificationGroup === "Read" && data
                          .filter((m) => m.isOpened).length > 0 ? (
                      <div className="email-right-box">
                        {data
                          .filter((m) => m.isOpened)
                          .map((message, index) => (
                            <div className="email-list mt-3" key={index}>
                              <div
                                className={`message ${
                                  message.isOpened ? "opened-message" : ""
                                }`}
                              >
                                <div>
                                  <div className="d-flex message-single">
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeStarred(
                                            message.notificationId,
                                            message.isStarred
                                          )
                                        }
                                      >
                                        <i
                                          className={`fa fa-star ${
                                            message.isStarred ? "starred" : ""
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeTrash(
                                            message.notificationId,
                                            message.isDeleted
                                          )
                                        }
                                      >
                                        <i
                                          className={`${
                                            message.isDeleted
                                              ? "fa fa-retweet deleted"
                                              : "fa fa-trash"
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeRead(
                                            message.notificationId,
                                            message.isOpened
                                          )
                                        }
                                      >
                                        <i
                                          className={` ${
                                            message.isOpened
                                              ? "fa fa-envelope-open read "
                                              : "fa fa-envelope"
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                  <Link
                                    to="/notification-inbox"
                                    className="col-mail col-mail-2"
                                    onClick={() => {
                                      setIsRead(true);
                                      setNotification(message);
                                    }}
                                  >
                                    <div className="subject">
                                      {message.body.length > 70
                                        ? message.body.substring(0, 70) + "..."
                                        : message.body}
                                    </div>
                                    <div className="date">
                                      {getSeconds(message.createdDate) < 60
                                        ? "Just now"
                                        : getMinutes(message.createdDate) < 60
                                        ? getMinutes(message.createdDate) +
                                          " minutes ago"
                                        : getHours(message.createdDate) < 12
                                        ? getHours(message.createdDate) +
                                          " hours ago"
                                        : getDate(message.createdDate)}
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}

                        <div className="row mt-4">
                          <div className="col-12 ps-3">
                            <nav>
                              <ul className="pagination pagination-gutter pagination-primary pagination-sm no-bg">
                                <li className="page-item page-indicator">
                                  <Link
                                    className="page-link"
                                    to="/notification-inbox"
                                    onClick={() =>
                                      activePag.current > 0 &&
                                      onClick(activePag.current - 1)
                                    }
                                  >
                                    <i className="la la-angle-left"></i>
                                  </Link>
                                </li>
                                {paggination.map((number, i) => (
                                  <li
                                    key={i}
                                    className={`page-item  ${
                                      activePag.current === i ? "active" : ""
                                    } `}
                                    onClick={() => onClick(i)}
                                  >
                                    <Link
                                      className="page-link"
                                      to="/notification-inbox"
                                    >
                                      {number}
                                    </Link>
                                  </li>
                                ))}

                                <li className="page-item page-indicator">
                                  <Link
                                    className="page-link"
                                    to="/notification-inbox"
                                    onClick={() =>
                                      activePag.current + 1 <
                                        paggination.length &&
                                      onClick(activePag.current + 1)
                                    }
                                  >
                                    <i className="la la-angle-right"></i>
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {notificationGroup === "Important" ? (
                      data
                        .filter((m) => m.isStarred)
                        .map((message, index) => (
                          <div className="email-right-box" key={index}>
                            <div className="email-list mt-3">
                              <div
                                className={`message ${
                                  message.isOpened ? "opened-message" : ""
                                }`}
                              >
                                <div>
                                  <div className="d-flex message-single">
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeStarred(
                                            message.notificationId,
                                            message.isStarred
                                          )
                                        }
                                      >
                                        <i
                                          className={`fa fa-star ${
                                            message.isStarred ? "starred" : ""
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeTrash(
                                            message.notificationId,
                                            message.isDeleted
                                          )
                                        }
                                      >
                                        <i
                                          className={`${
                                            message.isDeleted
                                              ? "fa fa-retweet deleted"
                                              : "fa fa-trash"
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeRead(
                                            message.notificationId,
                                            message.isOpened
                                          )
                                        }
                                      >
                                        <i
                                          className={` ${
                                            message.isOpened
                                              ? "fa fa-envelope-open read "
                                              : "fa fa-envelope"
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                  <Link
                                    to="/notification-inbox"
                                    className="col-mail col-mail-2"
                                    onClick={() => {
                                      setIsRead(true);
                                      setNotification(message);
                                    }}
                                  >
                                    <div className="subject">
                                      {message.body.length > 70
                                        ? message.body.substring(0, 70) + "..."
                                        : message.body}
                                    </div>
                                    <div className="date">
                                      {getSeconds(message.createdDate) < 60
                                        ? "Just now"
                                        : getMinutes(message.createdDate) < 60
                                        ? getMinutes(message.createdDate) +
                                          " minutes ago"
                                        : getHours(message.createdDate) < 12
                                        ? getHours(message.createdDate) +
                                          " hours ago"
                                        : getDate(message.createdDate)}
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-12 ps-3">
                                <nav>
                                  <ul className="pagination pagination-gutter pagination-primary pagination-sm no-bg">
                                    <li className="page-item page-indicator">
                                      <Link
                                        className="page-link"
                                        to="/notification-inbox"
                                        onClick={() =>
                                          activePag.current > 0 &&
                                          onClick(activePag.current - 1)
                                        }
                                      >
                                        <i className="la la-angle-left"></i>
                                      </Link>
                                    </li>
                                    {paggination.map((number, i) => (
                                      <li
                                        key={i}
                                        className={`page-item  ${
                                          activePag.current === i
                                            ? "active"
                                            : ""
                                        } `}
                                        onClick={() => onClick(i)}
                                      >
                                        <Link
                                          className="page-link"
                                          to="/notification-inbox"
                                        >
                                          {number}
                                        </Link>
                                      </li>
                                    ))}

                                    <li className="page-item page-indicator">
                                      <Link
                                        className="page-link"
                                        to="/notification-inbox"
                                        onClick={() =>
                                          activePag.current + 1 <
                                            paggination.length &&
                                          onClick(activePag.current + 1)
                                        }
                                      >
                                        <i className="la la-angle-right"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <></>
                    )}
                    {notificationGroup === "Trash" ? (
                      data
                        .filter((m) => m.isDeleted)
                        .map((message, index) => (
                          <div className="email-right-box" key={index}>
                            <div className="email-list mt-3">
                              <div
                                className={`message ${
                                  message.isOpened ? "opened-message" : ""
                                }`}
                              >
                                <div>
                                  <div className="d-flex message-single">
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeStarred(
                                            message.notificationId,
                                            message.isStarred
                                          )
                                        }
                                      >
                                        <i
                                          className={`fa fa-star ${
                                            message.isStarred ? "starred" : ""
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeTrash(
                                            message.notificationId,
                                            message.isDeleted
                                          )
                                        }
                                      >
                                        <i
                                          className={`${
                                            message.isDeleted
                                              ? "fa fa-retweet deleted"
                                              : "fa fa-trash"
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                    <div className="ms-2">
                                      <button
                                        className="border-0 bg-transparent align-middle p-0"
                                        onClick={() =>
                                          ChangeRead(
                                            message.notificationId,
                                            message.isOpened
                                          )
                                        }
                                      >
                                        <i
                                          className={` ${
                                            message.isOpened
                                              ? "fa fa-envelope-open read "
                                              : "fa fa-envelope"
                                          }`}
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                  <Link
                                    to="/notification-inbox"
                                    className="col-mail col-mail-2"
                                    onClick={() => {
                                      setIsRead(true);
                                      setNotification(message);
                                    }}
                                  >
                                    <div className="subject">
                                      {message.body.length > 70
                                        ? message.body.substring(0, 70) + "..."
                                        : message.body}
                                    </div>
                                    <div className="date">
                                      {getSeconds(message.createdDate) < 60
                                        ? "Just now"
                                        : getMinutes(message.createdDate) < 60
                                        ? getMinutes(message.createdDate) +
                                          " minutes ago"
                                        : getHours(message.createdDate) < 12
                                        ? getHours(message.createdDate) +
                                          " hours ago"
                                        : getDate(message.createdDate)}
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-12 ps-3">
                                <nav>
                                  <ul className="pagination pagination-gutter pagination-primary pagination-sm no-bg">
                                    <li className="page-item page-indicator">
                                      <Link
                                        className="page-link"
                                        to="/notification-inbox"
                                        onClick={() =>
                                          activePag.current > 0 &&
                                          onClick(activePag.current - 1)
                                        }
                                      >
                                        <i className="la la-angle-left"></i>
                                      </Link>
                                    </li>
                                    {paggination.map((number, i) => (
                                      <li
                                        key={i}
                                        className={`page-item  ${
                                          activePag.current === i
                                            ? "active"
                                            : ""
                                        } `}
                                        onClick={() => onClick(i)}
                                      >
                                        <Link
                                          className="page-link"
                                          to="/notification-inbox"
                                        >
                                          {number}
                                        </Link>
                                      </li>
                                    ))}

                                    <li className="page-item page-indicator">
                                      <Link
                                        className="page-link"
                                        to="/notification-inbox"
                                        onClick={() =>
                                          activePag.current + 1 <
                                            paggination.length &&
                                          onClick(activePag.current + 1)
                                        }
                                      >
                                        <i className="la la-angle-right"></i>
                                      </Link>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Inbox;
