import React, { useState, useEffect, Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const DropdownBlog = ({ newsLetterId, customers, setCustomers, customer }) => {
  const { userToken } = useSelector((state) => state.credential);


  const ChangePublishStatus = async () => {
    try {
      const response = await fetch(
        Global.API_URL +
          "NewsLetter/ChangeStatusNewsLetterSubscription?SubscriptionId=" +
          newsLetterId,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        toast.success(result.message);
        let array = [...customers];
        let index = array.findIndex(
          (customer) => customer.newsLetterId === newsLetterId
        );
        array[index].isSub = !array[index].isSub;
        setCustomers(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Dropdown className="dropdown">
      <Dropdown.Toggle
        as="div"
        className="btn-link i-false"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu">

        <Dropdown.Item className="dropdown-item" onClick={ChangePublishStatus}>
          {customer.isSub ? "UnSubscribe" : "Subscribe"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const SubscribersList = () => {
  const { userToken } = useSelector((state) => state.credential);

  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);



  useEffect(() => {
    const GetCustomers = async () => {
      try {
        const response = await fetch(
          Global.API_URL +
          "NewsLetter/GetNewsLetterSubscriptions",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
            
          let array = result.message;
          setCustomers(array);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    GetCustomers();
  }, [userToken]);

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body p-0">
                <div className="table-responsive">
                  <div
                    id="room_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table customer-list-tbl dataTable no-footer">
                      <thead>
                        <tr role="row">
                          <th>Id</th>
                          <th>Email</th>
                          <th>Subscribed Status</th>
                          <th className="bg-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.map((customer, index) => (
                          <tr
                            role="row"
                            className={index % 2 === 0 ? "even" : "odd"}
                            key={index}
                          >
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {customer.newsLetterId}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {customer.email}
                              </span>
                            </td>
                            <td>
                              {customer.isSub ? (
                                <span className="font-w600 text-success">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span className="font-w600 text-danger">
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </td>
                            <td>
                              <DropdownBlog
                                newsLetterId={customer.newsLetterId}
                                customers={customers}
                                setCustomers={setCustomers}
                                customer={customer}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SubscribersList;
