import React, { Fragment } from "react";

//Import Components
import RecentBooking from "../../Dashboard/RecentBooking";
import { Link } from "react-router-dom";

const BookingList = () => {
  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
        <Link to={"/bookings"} className="btn btn-secondary margin-right-15">
            Bookings
          </Link>
          <Link to={"/booking/new-booking"} className="btn btn-secondary">
            + New Booking
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header border-0 pb-0">
                      <h4 className="fs-20">Recent Booking Schedule</h4>
                    </div>
                    <RecentBooking />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BookingList;
