import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Global } from "../../../../../Global";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

const PageManage = () => {
  const { page_id } = useParams();

  const { userToken } = useSelector((state) => state.credential);
  const navigate = useNavigate();
  const [pageName, setPageName] = useState("");

  const [widgets, setWidgets] = useState([]);
  const [assignedWidget, setAssignedWidgets] = useState([]);
  const [isOpenAssignedWidgets, setIsOpenAssignedWidgets] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const activePag = useRef(0);
  const pageLimit = 10;

  const [isLoading, setIsLoading] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const AssignWidget = async (Widget) => {
    if (!assignedWidget.some((widget) => widget.widgetId === Widget.widgetId)) {
      let array = [...assignedWidget];
      let newArray = array.concat(Widget);
      setAssignedWidgets(newArray);
    }
  };
  
  const UnassignWidget = async (Widget) => {
    if (assignedWidget.some((widget) => widget.widgetId === Widget.widgetId)) {
      let array = [...assignedWidget];
      let newArray = array.filter(
        (widget) => widget.widgetId !== Widget.widgetId
      );
      setAssignedWidgets(newArray);
    }
  };

  const ChangeWidgetOrder = (Widget, Value) => {
    let index = assignedWidget.findIndex(
      (widget) => widget.widgetId === Widget.widgetId
    );
    if (index !== -1) {
      let array = [...assignedWidget];
      array[index].widgetOrder = Value;
      setAssignedWidgets(array);
    }
  };

  let pagination = Array(Math.ceil(totalCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  const ChangePage = (i) => {
    activePag.current = i;

    changeData(
      activePag.current * pageLimit,
      (activePag.current + 1) * pageLimit
    );
  };

  const changeData = async (first, sec) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Widget/GetWidgets?skip=" +
          first +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setWidgets(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePage = async () => {
    if (pageName.trim() === "") {
      toast.error("Page Name is required");
    } else if (assignedWidget.length === 0) {
      toast.error("Assign Widget to Submit");
    } else {
      var assignedPageToWidgets = [];
      assignedWidget.forEach((widget) => {
        assignedPageToWidgets.push({
          widgetId: widget.widgetId,
          widgetOrder: widget.widgetOrder,
        });
      });
      try {
        const response = await fetch(
          Global.API_URL + "Page/EditPage?pageid=" + page_id,
          {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              pageName: pageName,
              assignedPageToWidgets: assignedPageToWidgets,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/page");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetPageByID = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Page/GetPageById?pageid=" + page_id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setPageName(result.message.pageName);
          setAssignedWidgets(result.message.assignedPageToWidgets);
          setIsLoading(false);
          setDisableSubmit(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const GetWidgets = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Widget/GetWidgets?skip=0&take=10",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          result.message.forEach((item) => {
            Object.assign(item, {
              widgetOrder: "",
            });
          });
          setWidgets(result.message);
          setTotalCount(result.count);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    GetWidgets();
    GetPageByID();
  }, [userToken, page_id]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={updatePage}
          >
            Submit
          </button>
          <Link to={"/page"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Page Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Page name"
                      value={pageName}
                      onChange={(e) => setPageName(e.target.value)}
                    />
                  </div>

                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Widgets<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedWidgets(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedWidgets}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Widgets</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedWidgets(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {widgets.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Widget Name</th>
                                      <th>Widget Type</th>
                                      <th>Widget Style</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {widgets.map((widget, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {widget.widgetId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {widget.widgetTitle}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {widget.widgetType}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {widget.widgetStyle === ""
                                              ? "-"
                                              : widget.widgetStyle}
                                          </span>
                                        </td>
                                        {!assignedWidget.some(
                                          (assign) =>
                                            assign.widgetId === widget.widgetId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignWidget(widget)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing {activePag.current * pageLimit + 1}
                                    of {totalCount} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                      onClick={() =>
                                        activePag.current > 0 &&
                                        ChangePage(activePag.current - 1)
                                      }
                                    >
                                      <i className="fa fa-angle-double-left"></i>{" "}
                                      Previous
                                    </Link>
                                    <span>
                                      {pagination.map((number, i) => (
                                        <Link
                                          key={i}
                                          className={`paginate_button  ${
                                            activePag.current === i
                                              ? "current"
                                              : ""
                                          } `}
                                          onClick={() => ChangePage(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>

                                    <Link
                                      className="paginate_button next"
                                      onClick={() =>
                                        activePag.current + 1 <
                                          pagination.length &&
                                        changeData(activePag.current + 1)
                                      }
                                    >
                                      Next{" "}
                                      <i className="fa fa-angle-double-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedWidgets(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Widget Title</th>
                                <th>Widget Type</th>
                                <th>Widget Style</th>
                                <th>Widget Order</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedWidget.length > 0 ? (
                                assignedWidget.map((widget, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {widget.widgetId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {widget.widgetTitle}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {widget.widgetType}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {widget.widgetStyle === ""
                                          ? "-"
                                          : widget.widgetStyle}
                                      </span>
                                    </td>
                                    <td>
                                      <input
                                        className="form-control"
                                        type="number"
                                        value={widget.widgetOrder}
                                        onChange={(e) =>
                                          ChangeWidgetOrder(
                                            widget,
                                            parseInt(e.target.value)
                                          )
                                        }
                                      ></input>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignWidget(widget)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageManage;
