export const MenuList = [
  {
    title: "Dashboard",
    to: "dashboard",
    iconStyle: <i className="fas fa-home" />,
  },
  {
    title: "Manage Hotel",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-wrench"></i>,
    content: [
      {
        title: "Manage Hotel",
        to: "hotel",
        iconStyle:false
      },

      {
        title: "Booking",
        to: "booking",
        iconStyle:false
      },
      {
        title: "Customer",
        to: "customer",
        iconStyle:false
      },
      {
        title: "Subscribers",
        to: "subscribers",
        iconStyle:false
      },
      {
        title: "Media",
        to: "media",
        iconStyle:false
      },
      {
        title: "Rating",
        to: "rating",
        iconStyle:false
      },
      {
        title: "Offers",
        to: "offer",
        iconStyle:false
      },
    ],
  },
  {
    title: "Manage Rooms",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-bed"></i>,
    content: [
      {
        title: "Rooms",
        to: "room",
        iconStyle:false
      },
      {
        title: "Type List",
        to: "room-type",
        iconStyle:false
      },
      {
        title: "Service List",
        to: "room-service",
        iconStyle:false
      },
      {
        title: "Allowed Item",
        to: "room-allowed-item",
        iconStyle:false
      },
      {
        title: "Restricted  Item",
        to: "room-restricted-item",
        iconStyle:false
      },
      {
        title: "Bed Type",
        to: "room-bed-type",
        iconStyle:false
      },
    ],
  },
  {
    title: "Manage Website",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-wrench"></i>,
    content: [
      {
        title: "Menu",
        to: "menu",
        iconStyle:false
      },
      {
        title: "Pages",
        to: "page",
        iconStyle:false
      },
      {
        title: "Widgets",
        to: "widget",
        iconStyle:false
      },
      {
        title: "Banner",
        to: "banner",
        iconStyle:false
      },
      {
        title: "Slide",
        to: "slide",
        iconStyle:false
      },
      {
        title: "Content",
        to: "content",
        iconStyle:false
      },
      {
        title: "News",
        to: "news",
        iconStyle:false
      },
      {
        title: "Contact us",
        to: "contact-us",
        iconStyle:false
      },
    ],
  },
  {
    title: "Utilities",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-cog"></i>,
    content: [
      {
        title: "Employee",
        to: "employee",
        iconStyle:false
      },
      {
        title: "Roles",
        to: "role",
        iconStyle:false
      },
      {
        title: "Mode",
        to:"",
        iconStyle:true
      }
    ],
  },
  {
    title: "Profile",
    to: "app-profile",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-user"></i>,
  },
  {
    title: "Notification",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-envelope"></i>,
    content: [
      {
        title: "Index",
        to: "notification-inbox",
        iconStyle:false
      },
      {
        title: "Contact Us",
        to: "contact",
        iconStyle:false
      },
    ],
  },
];
