import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Global } from "../../../../Global";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";

const DropdownBlog = ({ bookingId }) => {
  const navigate = useNavigate();

  return (
    <Dropdown className="dropdown dropend ms-auto">
      <Dropdown.Toggle as="div" className="btn-link i-false">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
            stroke="#575757"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
            stroke="#575757"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
            stroke="#575757"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() =>
            navigate("/booking/" + bookingId)
          }
        >
          View Details
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const RecentBooking = () => {
  const { userToken } = useSelector((state) => state.credential);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const ChangeDate = async (date) => {
    if (!isLoading) {
      setIsLoading(true);
      let start, end;
      if (date.length) {
        start = date[0].toISOString();
        end = date[1].toISOString();
      } else {
        start = end = date.toISOString();
      }
      setSelectedDate(date);
      try {
        const response = await fetch(
          Global.API_URL +
            "Booking/GetBookingByDate?startDate=" +
            start +
            "&endDate=" +
            end ,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          let offset, date, newDate;

          array.forEach((booking) => {
            offset = new Date(booking.reservationDate).getTimezoneOffset();
            date = new Date(booking.reservationDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            booking.reservationDate = newDate;
          });

          setBookings(array);
          setIsLoading(false);
        } else if (result.code === 1) {
          setBookings([]);
          setIsLoading(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetBooking = async () => {
      const today = new Date();
      try {
        const response = await fetch(
          Global.API_URL +
            `Booking/GetBookingByDate?startDate=${today.toISOString()}&endDate=${today.toISOString()}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          let offset, date, newDate;

          array.forEach((booking) => {
            offset = new Date(booking.reservationDate).getTimezoneOffset();
            date = new Date(booking.reservationDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            booking.reservationDate = newDate;
          });

          setBookings(array);
          setIsLoading(false);
        } else if (result.code === 1) {
          setBookings([]);
          setIsLoading(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetBooking();
  }, [userToken]);

  return (
    <div className="card-body pb-2 loadmore-content" id="BookingContent">
      <div className="text-center event-calender border-bottom booking-calender ">
        {/* <input type='text' className="form-control d-none " id='datetimepicker1' /> */}
        <Calendar
          onChange={ChangeDate}
          onClickDay={ChangeDate}
          value={selectedDate}
          selectRange={true}
          className="mb-4"
        />
      </div>
      {isLoading ? (
        <div className="card-body p-0 main-div-loader">
          <div className="d-flex justify-content-center loader">
            <div
              className="spinner-border "
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <Fragment>
          {bookings.length === 0 ? (
            <div className="text-center mt-lg-6 mb-md-6">
              <p className="m-0">
                There is no bookings yet{" "}
                {selectedDate.length
                  ? "between " +
                    selectedDate[0].toLocaleString() +
                    " and " +
                    selectedDate[1].toLocaleString()
                  : "on " + selectedDate.toLocaleString()}
              </p>
            </div>
          ) : (
            <Fragment>
              {bookings.map((booking, index) => (
                <div className="row align-items-center" key={index}>
                  <div className="col-xl-4 col-sm-7">
                    <div className="purchase-history d-flex align-items-center">
                      <img src={Global.PHOTO_URL + booking.roomImage} alt="" />
                      <div className="ms-3">
                        <h4 className="guest-text font-w500">
                          {booking.roomName}
                        </h4>
                        <span className="fs-14 d-block mb-2 text-secondary">
                          #{booking.roomId}
                        </span>
                        <span className="fs-16 text-nowrap">
                          {booking.reservationDate.toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-5 col-6">
                    <div>
                      <span className="d-block">Check In</span>
                      <span className="text-black fs-18 font-w500">
                        {booking.checkinDate
                          .toLocaleString()
                          .substring(
                            0,
                            booking.checkinDate.toLocaleString().indexOf(",")
                          )}
                      </span>
                      <span className="fs-14 d-block">
                        {booking.checkinDate
                          .toLocaleString()
                          .slice(
                            booking.checkinDate.toLocaleString().indexOf(",") +
                              1
                          )}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-4 col-6">
                    <div>
                      <span className="d-block">Check Out</span>
                      <span className="text-black fs-18 font-w500">
                        {booking.checkoutDate
                          .toLocaleString()
                          .substring(
                            0,
                            booking.checkoutDate.toLocaleString().indexOf(",")
                          )}
                      </span>
                      <span className="fs-14 d-block">
                        {booking.checkoutDate
                          .toLocaleString()
                          .slice(
                            booking.checkoutDate.toLocaleString().indexOf(",") +
                              1
                          )}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-4 col-6">
                    <div className="mt-sm-0 mt-3">
                      <span className="d-block mb-2 text-black">Price</span>
                      <span className="font-w500 fs-18 text-black">
                        ${booking.totalPrice}
                        {/* <small className="fs-14 ms-2 text-secondary">
                              /night
                            </small> */}
                      </span>
                    </div>
                  </div>
                  <div className="col-xl-2 col-sm-4 col-6">
                    <div className="d-flex align-items-center mt-sm-0 mt-3">
                      <DropdownBlog bookingId={booking.bookingId} />
                    </div>
                  </div>
                </div>
              ))}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};
export default RecentBooking;
