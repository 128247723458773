import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const HotelRatingManage = () => {
  const { rating_id } = useParams();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.credential);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [value, setValue] = useState();
  const [starRating, setStarRating] = useState(0);
  const [ratingComment, setRatingComment] = useState("");
  const [isRegistered, setIsRegistered] = useState(true);
  const [bookingId, setBookingId] = useState(0);
  const [ratingDate, setRatingDate] = useState("");

  const AddHotelRating = async (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      value === undefined ||
      starRating === 0 ||
      isNaN(starRating) ||
      ratingComment.trim() === ""
    ) {
      toast.error("All fields are required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else if (!isPossiblePhoneNumber(value)) {
      toast.error("Enter a valid phone number");
    } else {
      setDisableSubmit(true);
      try {
        const response = await fetch(
          Global.API_URL + "HotelRating/AddHotelRating",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              hotelGuestEmail: email,
              isRegistered: false,
              firstName: firstName,
              lastName: lastName,
              starRating: starRating,
              ratingComment: ratingComment,
              phoneNumber: value,
              bookingId: 0,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/rating");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const EditHotelRating = async (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      value === undefined ||
      starRating === 0 ||
      isNaN(starRating) ||
      ratingComment.trim() === ""
    ) {
      toast.error("All fields are required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else if (!isPossiblePhoneNumber(value)) {
      toast.error("Enter a valid phone number");
    } else {
      setDisableSubmit(true);
      try {
        const response = await fetch(
          Global.API_URL +
            "HotelRating/EditHotelRating?hotelratingid=" +
            rating_id,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              hotelGuestEmail: email,
              isRegistered: isRegistered,
              firstName: firstName,
              lastName: lastName,
              starRating: starRating,
              ratingComment: ratingComment,
              phoneNumber: value,
              bookingId: bookingId,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/rating");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetRate = async () => {
      if (parseInt(rating_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "HotelRating/GetRatingByID?ratingid=" + rating_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            let offset, date, newDate;
            offset = new Date(result.message.ratingDate).getTimezoneOffset();
            date = new Date(result.message.ratingDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            result.message.ratingDate = newDate;

            setFirstName(result.message.firstName);
            setLastName(result.message.lastName);
            setEmail(result.message.hotelGuestEmail);
            setValue(result.message.phoneNumber);
            setStarRating(result.message.starRating);
            setRatingComment(result.message.ratingComment);
            setIsRegistered(result.message.isRegistered);
            setBookingId(result.message.bookingId);
            setRatingDate(result.message.ratingDate);

            setIsLoading(false);
            setDisableSubmit(false);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setIsLoading(false);
        setDisableSubmit(false);
      }
    };

    GetRate();
  }, [rating_id, userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={(e) => {
              parseInt(rating_id) === 0 ? AddHotelRating(e) : EditHotelRating(e);
            }}
          >
            Submit
          </button>
          <Link
            to={"/rating"}
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                {bookingId === 0 ? (
                  <div className="row">
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        {parseInt(rating_id) === 0
                          ? "This review will be created by you"
                          : "Review created by admin"}
                      </label>
                    </div>
                    {parseInt(rating_id) !== 0 && (
                      <div className="form-group mb-4 col-md-12">
                        <label>
                          Rating Date: {ratingDate.toLocaleString()}
                        </label>
                      </div>
                    )}
                    <div className="form-group mb-4 col-md-6">
                      <label>
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-4 col-md-6">
                      <label>
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-4 col-md-6">
                      <label>
                        Email Address<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-4 col-md-6">
                      <label>
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      <PhoneInput
                        placeholder="Phone Number"
                        country="LB"
                        value={value}
                        onChange={(e) => setValue(e)}
                        required={true}
                      />
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Rate<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Rate"
                        min="1"
                        max="5"
                        value={starRating}
                        onChange={(e) =>
                          setStarRating(parseInt(e.target.value))
                        }
                      />
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Rating Comment<span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="8"
                        placeholder="Comment"
                        value={ratingComment}
                        onChange={(e) => setRatingComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="form-group mb-4 col-md-12">
                      <Link
                        to={
                          "/booking/" +
                          bookingId +
                          "/rating/" +
                          rating_id
                        }
                        className="btn btn-secondary margin-right-15"
                      >
                        View Booking
                      </Link>
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label> {isRegistered ? "Customer" : "Guest"}</label>
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label>Name: {firstName + " " + lastName}</label>
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label>Email: {email}</label>
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label>PhoneNumber: {value}</label>
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Rate<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Rate"
                        min="1"
                        max="5"
                        value={starRating}
                        onChange={(e) =>
                          setStarRating(parseInt(e.target.value))
                        }
                      />
                    </div>
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Rating Comment<span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="8"
                        placeholder="Comment"
                        value={ratingComment}
                        onChange={(e) => setRatingComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HotelRatingManage;
