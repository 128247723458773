import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Global } from "../../../../../Global";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import uploadFile from "../../../../../images/uploadFile.jpg";
import axios from "axios";

const OfferManage = () => {
  const navigate = useNavigate();
  const { offer_id } = useParams();
  const { userToken, userId } = useSelector((state) => state.credential);

  const [isLoading, setIsLoading] = useState(true);

  const [offerName, setOfferName] = useState("");
  const [offerTypeId, setOfferTypeId] = useState(0);
  const [breakfastIncluded, setBreakfastIncluded] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minimumDays, setMinimumDays] = useState(0);
  const [maximumDays, setMaximumDays] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [isPublished, setIsPublished] = useState(true);
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [offerLimit, setOfferLimit] = useState(0);
  const [offerImage, setOfferImage] = useState("");
  const [isAssignedToRoom, setIsAssignedToRoom] = useState(false);
  const [isAssignedToRoomType, setIsAssignedToRoomType] = useState(false);
  const [isAssignedToService, setIsAssignedToService] = useState(false);
  const [isAssignedToGroup, setIsAssignedToGroup] = useState(false);

  const [isOpenGroup, setIsOpenGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const [assignedGroups, setAssignedGroups] = useState([]);

  const [isOpenRoomType, setIsOpenRoomType] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);
  const [assignedRoomTypes, setAssignedRoomTypes] = useState([]);

  const [isOpenRoom, setIsOpenRoom] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [assignedRooms, setAssignedRooms] = useState([]);

  const [isOpenService, setIsOpenService] = useState(false);
  const [services, setServices] = useState([]);
  const [assignedServices, setAssignedServices] = useState([]);

  const [offerTypes, setOfferType] = useState([]);

  const OpenGroupsModal = async () => {
    setIsOpenGroup(true);
    try {
      const response = await fetch(Global.API_URL + "Group/GetGroups", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "bs-api-key": Global.bs_api_key,
          AuthorizationUser: userToken,
        },
      });
      let result = await response.json();

      if (result.code === 0) {
        setGroups(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AssignGroup = async (Group) => {
    if (!assignedGroups.some((group) => group.groupId === Group.groupId)) {
      let array = [...assignedGroups];
      let newArray = array.concat(Group);
      setAssignedGroups(newArray);
    }
  };

  const UnassignGroup = async (Group) => {
    if (assignedGroups.some((group) => group.groupId === Group.groupId)) {
      let array = [...assignedGroups];
      let newArray = array.filter((group) => group.groupId !== Group.groupId);
      setAssignedGroups(newArray);
    }
  };

  const OpenRoomTypesModal = async () => {
    setIsOpenRoomType(true);
    try {
      const response = await fetch(
        Global.API_URL + "RoomType/GetRoomTypesListDropDown",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      let result = await response.json();

      if (result.code === 0) {
        setRoomTypes(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AssignRoomType = async (Type) => {
    if (
      !assignedRoomTypes.some((type) => type.roomTypeId === Type.roomTypeId)
    ) {
      let array = [...assignedRoomTypes];
      let newArray = array.concat(Type);
      setAssignedRoomTypes(newArray);

      if (isAssignedToRoom) {
        setAssignedRooms([]);
        let query = "";

        assignedServices.forEach((service) => {
          if (query === "") {
            query = "?roomservices=" + service.serviceId;
          } else {
            query = query + "&roomservices" + service.serviceId;
          }
        });

        assignedRoomTypes.forEach((type) => {
          if (query === "") {
            query = "?roomtypes=" + type.roomTypeId;
          } else {
            query = query + "&roomtypes" + type.roomTypeId;
          }
        });

        if (query === "") {
          query = "?roomtypes=" + Type.roomTypeId;
        } else {
          query = query + "&roomtypes" + Type.roomTypeId;
        }

        try {
          const response = await fetch(
            Global.API_URL + "Room/GetRoomsByServiceAndType" + query,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );

          let result = await response.json();

          if (result.code === 0) {
            setRooms(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const UnassignRoomType = async (Type) => {
    if (assignedRoomTypes.some((type) => type.roomTypeId === Type.roomTypeId)) {
      let array = [...assignedRoomTypes];
      let newArray = array.filter(
        (type) => type.roomTypeId !== Type.roomTypeId
      );
      setAssignedRoomTypes(newArray);

      if (isAssignedToRoom) {
        setAssignedRooms([]);
        let query = "";

        assignedServices.forEach((service) => {
          if (query === "") {
            query = "?roomservices=" + service.serviceId;
          } else {
            query = query + "&roomservices" + service.serviceId;
          }
        });

        assignedRoomTypes.forEach((type) => {
          if (type.roomTypeId !== Type.roomTypeId) {
            if (query === "") {
              query = "?roomtypes=" + type.roomTypeId;
            } else {
              query = query + "&roomtypes" + type.roomTypeId;
            }
          }
        });

        try {
          const response = await fetch(
            Global.API_URL + "Room/GetRoomsByServiceAndType" + query,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );

          let result = await response.json();

          if (result.code === 0) {
            setRooms(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const OpenRoomsModal = async () => {
    setIsOpenRoom(true);
    let query = "";

    assignedServices.forEach((service) => {
      if (query === "") {
        query = "?roomservices=" + service.serviceId;
      } else {
        query = query + "&roomservices" + service.serviceId;
      }
    });

    assignedRoomTypes.forEach((type) => {
      if (query === "") {
        query = "?roomtypes=" + type.roomTypeId;
      } else {
        query = query + "&roomtypes" + type.roomTypeId;
      }
    });

    try {
      const response = await fetch(
        Global.API_URL + "Room/GetRoomsByServiceAndType" + query,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );

      let result = await response.json();

      if (result.code === 0) {
        setRooms(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AssignRoom = async (Room) => {
    if (!assignedRooms.some((room) => room.roomId === Room.roomId)) {
      let array = [...assignedRooms];
      let newArray = array.concat(Room);
      setAssignedRooms(newArray);
    }
  };

  const UnassignRoom = async (Room) => {
    if (assignedRooms.some((room) => room.roomId === Room.roomId)) {
      let array = [...assignedRoomTypes];
      let newArray = array.filter((room) => room.roomId !== Room.roomId);
      setAssignedRooms(newArray);
    }
  };

  const OpenServicesModal = async () => {
    setIsOpenService(true);
    try {
      const response = await fetch(
        Global.API_URL + "RoomService/GetRoomServicesDropdownList",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      let result = await response.json();

      if (result.code === 0) {
        setServices(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AssignService = async (Service) => {
    if (
      !assignedServices.some(
        (service) => service.serviceId === Service.serviceId
      )
    ) {
      let array = [...assignedServices];
      let newArray = array.concat(Service);
      setAssignedServices(newArray);
      if (isAssignedToRoom) {
        setAssignedRooms([]);
        let query = "";

        assignedServices.forEach((service) => {
          if (query === "") {
            query = "?roomservices=" + service.serviceId;
          } else {
            query = query + "&roomservices" + service.serviceId;
          }
        });

        assignedRoomTypes.forEach((type) => {
          if (query === "") {
            query = "?roomtypes=" + type.roomTypeId;
          } else {
            query = query + "&roomtypes" + type.roomTypeId;
          }
        });

        if (query === "") {
          query = "?roomservices=" + Service.serviceId;
        } else {
          query = query + "&roomservices" + Service.serviceId;
        }

        try {
          const response = await fetch(
            Global.API_URL + "Room/GetRoomsByServiceAndType" + query,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );

          let result = await response.json();

          if (result.code === 0) {
            setRooms(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const UnassignService = async (Service) => {
    if (
      assignedServices.some(
        (service) => service.serviceId === Service.serviceId
      )
    ) {
      let array = [...assignedRoomTypes];
      let newArray = array.filter(
        (service) => service.serviceId !== Service.serviceId
      );
      setAssignedServices(newArray);
      if (isAssignedToRoom) {
        setAssignedRooms([]);
        let query = "";

        assignedServices.forEach((service) => {
          if (service.serviceId !== Service.serviceId) {
            if (query === "") {
              query = "?roomservices=" + service.serviceId;
            } else {
              query = query + "&roomservices" + service.serviceId;
            }
          }
        });

        assignedRoomTypes.forEach((type) => {
          if (query === "") {
            query = "?roomtypes=" + type.roomTypeId;
          } else {
            query = query + "&roomtypes" + type.roomTypeId;
          }
        });

        try {
          const response = await fetch(
            Global.API_URL + "Room/GetRoomsByServiceAndType" + query,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );

          let result = await response.json();

          if (result.code === 0) {
            setRooms(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleImage = async (e) => {
    const formData = new FormData();
    formData.append("Images", e.target.files[0]);
    formData.append("Sender", "A");
    formData.append("SenderID", userId);

    try {
      const response = await axios.post(
        Global.API_URL + "Others/UploadSingleImage",
        formData,
        {
          headers: {
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      setOfferImage(response.data[0]);
    } catch (ex) {
      console.log(ex);
    }
  };

  const AddOffer = async () => {
    if (
      offerName.trim() === "" ||
      startDate === "" ||
      endDate === "" ||
      description.trim() === "" ||
      shortDescription.trim() === "" ||
      offerLimit === 0 ||
      isNaN(offerLimit) ||
      offerImage === "" ||
      (isAssignedToRoom && assignedRooms.length === 0) ||
      (isAssignedToRoomType && assignedRoomTypes.length === 0) ||
      (isAssignedToGroup && assignedGroups.length === 0) ||
      (isAssignedToService && assignedServices.length === 0)
    ) {
      toast.error("All fields are required");
    } else if (startDate > endDate) {
      toast.error("Error matching start date and end date");
    } else {
      let roomids = [],
        roomtypeids = [],
        groupids = [],
        serviceids = [];

      assignedRooms.forEach((room) => {
        roomids.push(room.roomId);
      });

      assignedRoomTypes.forEach((type) => {
        roomtypeids.push(type.roomTypeId);
      });

      assignedGroups.forEach((group) => {
        groupids.push(group.groupId);
      });

      assignedServices.forEach((service) => {
        serviceids.push(service.serviceId);
      });

      try {
        const response = await fetch(Global.API_URL + "Offer/AddOffer", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
          body: JSON.stringify({
            offerName: offerName,
            breakfastIncluded: breakfastIncluded,
            startDate: startDate,
            endDate: endDate,
            minimumDays: minimumDays,
            maximumDays: minimumDays,
            percentage: percentage,
            isPublished: isPublished,
            description: description,
            shortDescription: shortDescription,
            offerLimit: offerLimit,
            offerImage: offerImage,
            isAssignedToRoom: isAssignedToRoom,
            isAssignedToRoomType: isAssignedToRoomType,
            isAssignedToService: isAssignedToService,
            isAssignedToGroup: isAssignedToGroup,
            roomsId: roomids,
            roomTypesId: roomtypeids,
            servicesId: serviceids,
            groupsId: groupids,
          }),
        });
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/offer");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const EditOffer = async () => {
    if (
      offerName.trim() === "" ||
      startDate === "" ||
      endDate === "" ||
      description.trim() === "" ||
      shortDescription.trim() === "" ||
      offerLimit === 0 ||
      isNaN(offerLimit) ||
      offerImage === "" ||
      (isAssignedToRoom && assignedRooms.length === 0) ||
      (isAssignedToRoomType && assignedRoomTypes.length === 0) ||
      (isAssignedToGroup && assignedGroups.length === 0) ||
      (isAssignedToService && assignedServices.length === 0)
    ) {
      toast.error("All fields are required");
    } else if (startDate > endDate) {
      toast.error("Error matching start date and end date");
    } else {
      let roomids = [],
        roomtypeids = [],
        groupids = [],
        serviceids = [];

      assignedRooms.forEach((room) => {
        roomids.push(room.roomId);
      });

      assignedRoomTypes.forEach((type) => {
        roomtypeids.push(type.roomTypeId);
      });

      assignedGroups.forEach((group) => {
        groupids.push(group.groupId);
      });

      assignedServices.forEach((service) => {
        serviceids.push(service.serviceId);
      });

      try {
        const response = await fetch(
          Global.API_URL + "Offer/EditOffer?offerid=" + offer_id,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              offerName: offerName,
              breakfastIncluded: breakfastIncluded,
              startDate: startDate,
              endDate: endDate,
              minimumDays: minimumDays,
              maximumDays: minimumDays,
              percentage: percentage,
              isPublished: isPublished,
              description: description,
              shortDescription: shortDescription,
              offerLimit: offerLimit,
              offerImage: offerImage,
              isAssignedToRoom: isAssignedToRoom,
              isAssignedToRoomType: isAssignedToRoomType,
              isAssignedToService: isAssignedToService,
              isAssignedToGroup: isAssignedToGroup,
              roomsId: roomids,
              roomTypesId: roomtypeids,
              servicesId: serviceids,
              groupsId: groupids,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/offer");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetOffer = async () => {
      if (parseInt(offer_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Offer/GetOffer?offerid=" + offer_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            setOfferName(result.message.offerName);
            setOfferTypeId(result.message.offerTypeId);
            setBreakfastIncluded(result.message.breakfastIncluded);
            setDescription(result.message.description);
            setShortDescription(result.message.shortDescription);
            setStartDate(result.message.startDate);
            setEndDate(result.message.endDate);
            setIsAssignedToGroup(result.message.isAssignedToGroup);
            setIsAssignedToRoom(result.message.isAssignedToRoom);
            setIsAssignedToRoomType(result.message.isAssignedToRoomType);
            setIsAssignedToService(result.message.isAssignedToService);
            setIsPublished(result.message.isPublished);
            setMinimumDays(result.message.minimumDays);
            setMaximumDays(result.message.maximumDays);
            setOfferImage(result.message.offerImage);
            setOfferLimit(result.message.offerLimit);
            setPercentage(result.message.percentage);
            setAssignedServices(result.message.services);
            setAssignedRooms(result.message.rooms);
            setAssignedRoomTypes(result.message.types);
            setAssignedGroups(result.message.groups);
            setIsLoading(false);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setIsLoading(false);
      }
    };

    GetOffer();
  }, [userToken, offer_id]);

  useEffect(() => {
    const GetOfferTypes = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "OfferType/GetOfferTypes",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.cdoe === 0) {
          setOfferType(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetOfferTypes();
  }, [userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            onClick={parseInt(offer_id) === 0 ? AddOffer : EditOffer}
          >
            Submit
          </button>
          <Link to={"/offer"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Offer Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      value={offerName}
                      onChange={(e) => setOfferName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Offer Type<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={offerTypeId}
                      onChange={(e) => setOfferTypeId(parseInt(e.target.value))}
                    >
                      <option value={0}>Choose...</option>
                      {offerTypes.map((offertype, index) => (
                        <option value={offertype.offerTypeId} key={index}>
                          {offertype.offerTypeName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Percentage
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="percentage"
                      min="0"
                      value={percentage}
                      onChange={(e) => setPercentage(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-4">
                    <label>Offer Limit</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="offerLimit"
                      min="0"
                      value={offerLimit}
                      onChange={(e) => setOfferLimit(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-3">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={breakfastIncluded}
                          onChange={(e) =>
                            setBreakfastIncluded(e.target.checked)
                          }
                        />
                        Includes Breakfast
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>Start Date</label>
                    <span className="text-danger">*</span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder="StartDate"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>End Date</label>
                    <span className="text-danger">*</span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder="EndDate"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Minimum Days
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="minimumDays"
                      min="0"
                      value={minimumDays}
                      onChange={(e) => setMinimumDays(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Maximum Days
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="maximumDays"
                      min="0"
                      value={maximumDays}
                      onChange={(e) => setMaximumDays(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Short Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      value={shortDescription}
                      onChange={(e) => setShortDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows="8"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-5 col-md-6 col-lg-6 ">
                    <label>
                      Service Image <span className="text-danger">*</span>
                    </label>
                    <br />
                    <label htmlFor="offerImage">
                      <img
                        src={
                          offerImage && offerImage !== ""
                            ? Global.PHOTO_URL + offerImage
                            : uploadFile
                        }
                        width="250"
                        alt="offerImage"
                      />
                    </label>
                    <input
                      type="file"
                      name="offerImage"
                      id="offerImage"
                      onChange={handleImage}
                      style={{ visibility: "hidden" }}
                    ></input>
                  </div>
                </div>
                <div className="row">
                  <h4>Offers Assigns</h4>
                  <div className="col-xl-4  col-6">
                    <div className="form-check custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckBox1"
                        checked={isAssignedToGroup}
                        onChange={(e) => setIsAssignedToGroup(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckBox1"
                      >
                        To Group
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-4  col-6">
                    <div className="form-check custom-checkbox mb-3 checkbox-info">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckBox3"
                        checked={isAssignedToRoomType}
                        onChange={(e) => {
                          setIsAssignedToRoomType(e.target.checked);
                          setAssignedRooms([]);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckBox3"
                      >
                        To Room Type
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4  col-6">
                    <div className="form-check custom-checkbox mb-3 checkbox-new">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckBox4"
                        checked={isAssignedToService}
                        onChange={(e) => {
                          setIsAssignedToService(e.target.checked);
                          setAssignedRooms([]);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckBox4"
                      >
                        To Service
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-4 col-6">
                    <div className="form-check custom-checkbox mb-3 checkbox-success">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckBox5"
                        checked={isAssignedToRoom}
                        onChange={(e) => setIsAssignedToRoom(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckBox5"
                      >
                        To Rooms
                      </label>
                    </div>
                  </div>
                </div>

                {/* groups */}
                {isAssignedToGroup && (
                  <div className="row">
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Group<span className="text-danger">*</span>
                      </label>
                      <br />
                      <Button
                        variant="primary"
                        className="mb-2 me-2"
                        onClick={OpenGroupsModal}
                      >
                        Select Group
                      </Button>
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenGroup}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Groups</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenGroup(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {groups.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no groups</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Group</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {groups.map((group, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {group.groupId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {group.groupName}
                                          </span>
                                        </td>
                                        {!assignedGroups.some(
                                          (assigned) =>
                                            group.groupId === assigned.groupId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() => AssignGroup(group)}
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenGroup(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {assignedGroups.length > 0 && (
                        <div className="table-responsive">
                          <div
                            id="room_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                              <thead>
                                <tr role="row">
                                  <th>Id</th>
                                  <th>Group</th>
                                  <th className="bg-none"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {assignedGroups.map((group, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {group.groupId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {group.groupName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignGroup(group)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* room Types */}
                {isAssignedToRoomType && (
                  <div className="row">
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Room Type<span className="text-danger">*</span>
                      </label>
                      <br />
                      <Button
                        variant="primary"
                        className="mb-2 me-2"
                        onClick={OpenRoomTypesModal}
                      >
                        Select Room Type
                      </Button>
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenRoomType}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Room Types</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenRoomType(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {roomTypes.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no room types</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Room Type</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {roomTypes.map((type, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {type.roomTypeId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {type.typeName}
                                          </span>
                                        </td>
                                        {!assignedRoomTypes.some(
                                          (assigned) =>
                                            type.roomTypeId ===
                                            assigned.roomTypeId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignRoomType(type)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenRoomType(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {assignedRoomTypes.length > 0 && (
                        <div className="table-responsive">
                          <div
                            id="room_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                              <thead>
                                <tr role="row">
                                  <th>Id</th>
                                  <th>Room Type</th>
                                  <th className="bg-none"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {assignedRoomTypes.map((type, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {type.roomTypeId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {type.typeName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignRoomType(type)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* services */}
                {isAssignedToService && (
                  <div className="row">
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Room Service <span className="text-danger">*</span>
                      </label>
                      <br />
                      <Button
                        variant="primary"
                        className="mb-2 me-2"
                        onClick={OpenServicesModal}
                      >
                        Select Room Service
                      </Button>
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenService}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Room Service</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenService(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {services.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no room services</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Room Service</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {services.map((service, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {service.serviceId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {service.serviceName}
                                          </span>
                                        </td>
                                        {!assignedServices.some(
                                          (assigned) =>
                                            service.serviceId ===
                                            assigned.serviceId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignService(service)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenService(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {assignedServices.length > 0 && (
                        <div className="table-responsive">
                          <div
                            id="room_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                              <thead>
                                <tr role="row">
                                  <th>Id</th>
                                  <th>Service</th>
                                  <th className="bg-none"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {assignedServices.map((service, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {service.serviceId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {service.serviceName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignService(service)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* rooms */}
                {isAssignedToRoom && (
                  <div className="row">
                    <div className="form-group mb-4 col-md-12">
                      <label>
                        Room <span className="text-danger">*</span>
                      </label>
                      <br />
                      <Button
                        variant="primary"
                        className="mb-2 me-2"
                        onClick={OpenRoomsModal}
                      >
                        Select Room
                      </Button>
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenRoom}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Rooms</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenRoom(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {rooms.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no room</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Room</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rooms.map((room, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {room.roomId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {room.roomName}
                                          </span>
                                        </td>
                                        {!assignedRooms.some(
                                          (assigned) =>
                                            room.roomId === assigned.roomId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() => AssignRoom(room)}
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenRoom(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {assignedRooms.length > 0 && (
                        <div className="table-responsive">
                          <div
                            id="room_wrapper"
                            className="dataTables_wrapper no-footer"
                          >
                            <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                              <thead>
                                <tr role="row">
                                  <th>Id</th>
                                  <th>Room</th>
                                  <th className="bg-none"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {assignedRooms.map((room, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {room.roomId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {room.roomName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignRoom(room)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OfferManage;
