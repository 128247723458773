import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const DropdownBlog = ({ employeeId, users, setUsers, user }) => {
  const { userToken, userId } = useSelector((state) => state.credential);
  const navigate = useNavigate();

  const EditClick = () => {
    navigate("/employee/" + employeeId);
  };

  const ChangePublishStatus = async () => {
    if (parseInt(userId) === parseInt(employeeId)) {
      toast.error("You Can't Deactive you account");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "Employee/ChangePublishStatus?userid=" + employeeId,
          {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          let array = [...users];
          let index = array.findIndex((user) => user.employeeId === employeeId);
          array[index].isPublished = !array[index].isPublished;
          setUsers(array);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const ChangeLockStatus = async () => {
    if (parseInt(userId) === parseInt(employeeId)) {
      toast.error("You Can't Lock you account");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "Employee/ChangeLockStatus?userid=" + employeeId,
          {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          let array = [...users];
          let index = array.findIndex((user) => user.employeeId === employeeId);
          array[index].isLocked = !array[index].isLocked;
          setUsers(array);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Dropdown className="dropdown">
      <Dropdown.Toggle
        as="div"
        className="btn-link i-false"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu">
        <Dropdown.Item className="dropdown-item" onClick={EditClick}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item className="dropdown-item" onClick={ChangePublishStatus}>
          {user.isPublished ? "Deactivate" : "Activate"}
        </Dropdown.Item>
        <Dropdown.Item className="dropdown-item" onClick={ChangeLockStatus}>
          {user.isLocked ? "Unlock" : "Lock"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const UserList = () => {
  const { userToken } = useSelector((state) => state.credential);

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const pageLimit = 10;
  const activePag = useRef(0);

  let paggination = Array(Math.ceil(totalCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const ChangePage = (i) => {
    activePag.current = i;

    changeData(
      activePag.current * pageLimit,
      (activePag.current + 1) * pageLimit
    );
  };

  const changeData = async (first, sec) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        Global.API_URL +
          "Employee/GetEmployeesList?skip=" +
          first +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalCount(result.count);
        setUsers(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const GetCustomers = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Employee/GetEmployeesList?skip=0&take=10",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          setTotalCount(result.count);
          setUsers(array);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    GetCustomers();
  }, [userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <Link
            to={"/employee/0"}
            className="btn btn-secondary margin-right-15"
          >
            + New Employee
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body p-0">
                <div className="table-responsive">
                  <div
                    id="room_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table user-list-tbl dataTable no-footer">
                      <thead>
                        <tr role="row">
                          <th>Id</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Lock Status</th>
                          <th>Account Status</th>
                          <th className="bg-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr
                            role="row"
                            className={index % 2 === 0 ? "even" : "odd"}
                            key={index}
                          >
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {user.employeeId}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {user.firstName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {user.lastName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {user.email}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {user.roleName}
                              </span>
                            </td>
                            <td className="text-center">
                              {user.isLocked ? (
                                <span className="font-w600 text-success">
                                  <i
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span className="font-w600 text-danger">
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </td>
                            <td className="text-center">
                              {user.isPublished ? (
                                <span className="font-w600 text-success">
                                  ACTIVATED
                                </span>
                              ) : (
                                <span className="font-w600 text-danger">
                                  DEACTIVATED
                                </span>
                              )}
                            </td>
                            <td>
                              <DropdownBlog
                                employeeId={user.employeeId}
                                users={users}
                                setUsers={setUsers}
                                user={user}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                      <div className="dataTables_info">
                        Showing {activePag.current * pageLimit + 1}
                        of {totalCount} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers mb-0"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to={"/employee"}
                          onClick={() =>
                            activePag.current > 0 &&
                            ChangePage(activePag.current - 1)
                          }
                        >
                          <i className="fa fa-angle-double-left"></i> Previous
                        </Link>
                        <span>
                          {paggination.map((number, i) => (
                            <Link
                              key={i}
                              to={"/employee"}
                              className={`paginate_button  ${
                                activePag.current === i ? "current" : ""
                              } `}
                              onClick={() => ChangePage(i)}
                            >
                              {number}
                            </Link>
                          ))}
                        </span>

                        <Link
                          className="paginate_button next"
                          to={"/employee"}
                          onClick={() =>
                            activePag.current + 1 < paggination.length &&
                            ChangePage(activePag.current + 1)
                          }
                        >
                          Next <i className="fa fa-angle-double-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;
