import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Global } from "../../../../../Global";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";

const WidgetManage = () => {
  const { widget_id } = useParams();

  const { userToken } = useSelector((state) => state.credential);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const pageLimit = 10;

  const [widgetTypeId, setWidgetTypeId] = useState(0);
  const [widgetStyleId, setWidgetStyleId] = useState(0);
  const [widgetDescription, setWidgetDescription] = useState("");
  const [widgetName, setWidgetName] = useState("");
  const [widgetUrl, setWidgetUrl] = useState("/");
  const [isPublished, setIsPublished] = useState(true);

  const [widgetTypes, setWidgetTypes] = useState([]);
  const [widgetStyles, setWidgetStyles] = useState([]);

  // Type 1
  const [allowedItems, setAllowedItems] = useState([]);
  const [assignedAllowedItems, setAssignedAllowedItems] = useState([]);
  const [isOpenAssignedAllowedItems, setIsOpenAssignedAllowedItems] =
    useState(false);

  // Type 2
  const [banners, setBanners] = useState([]);
  const [assignedBanners, setAssignedBanners] = useState([]);
  const [isOpenAssignedBanners, setIsOpenAssignedBanners] = useState(false);
  const [totalBannerCount, setTotalBannerCount] = useState(0);
  const activeBannerPag = useRef(0);

  // Type 3
  const [contents, setContents] = useState([]);
  const [assignedContents, setAssignedContents] = useState([]);
  const [isOpenAssignedContents, setIsOpenAssignedContents] = useState(false);
  const [totalContentCount, setTotalContentCount] = useState(0);
  const activeContentPag = useRef(0);

  // Type 4
  const [news, setNews] = useState([]);
  const [assignedNews, setAssignedNews] = useState([]);
  const [isOpenAssignedNews, setIsOpenAssignedNews] = useState(false);
  const [totalNewsCount, setTotalNewsCount] = useState(0);
  const activeNewsPag = useRef(0);

  // Type 5
  const [offers, setOffers] = useState([]);
  const [assignedOffers, setAssignedOffers] = useState([]);
  const [isOpenAssignedOffers, setIsOpenAssignedOffers] = useState(false);

  // Type 6
  const [reviews, setReviews] = useState([]);
  const [assignedReviews, setAssignedReviews] = useState([]);
  const [isOpenAssignedReviews, setIsOpenAssignedReviews] = useState(false);
  const [totalReviewsCount, setTotalReviewsCount] = useState(0);
  const activeReviewsPag = useRef(0);

  // Type 7
  const [rooms, setRooms] = useState([]);
  const [assignedRooms, setAssignedRooms] = useState([]);
  const [isOpenAssignedRooms, setIsOpenAssignedRooms] = useState(false);

  // Type 8
  const [services, setServices] = useState([]);
  const [assignedServices, setAssignedServices] = useState([]);
  const [isOpenAssignedServices, setIsOpenAssignedServices] = useState(false);

  /* Allowed Items */
  const GetAllowedItems = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "RoomAllowedItem/GetAllowedItemsDropdownList",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setAllowedItems(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);

  const AssignAllowItem = async (Item) => {
    if (!assignedAllowedItems.some((item) => item.itemId === Item.itemId)) {
      let array = [...assignedAllowedItems];
      let newArray = array.concat(Item);
      setAssignedAllowedItems(newArray);
    }
  };

  const UnassignAllowItem = async (Item) => {
    if (assignedAllowedItems.some((item) => item.itemId === Item.itemId)) {
      let array = [...assignedAllowedItems];
      let newArray = array.filter((item) => item.itemId !== Item.itemId);
      setAssignedAllowedItems(newArray);
    }
  };
  /* Allowed Items */

  /* Banners */
  const GetBanners = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "Banner/GetBanners?skip=0&take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalBannerCount(result.count);
        setBanners(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);

  const AssignBanner = async (Banner) => {
    if (
      !assignedBanners.some((banner) => banner.bannerId === Banner.bannerId)
    ) {
      let array = [...assignedBanners];
      let newArray = array.concat(Banner);
      setAssignedBanners(newArray);
    }
  };

  const UnassignBanner = async (Banner) => {
    if (assignedBanners.some((banner) => banner.bannerId === Banner.bannerId)) {
      let array = [...assignedBanners];
      let newArray = array.filter(
        (banner) => banner.bannerId !== Banner.bannerId
      );
      setAssignedBanners(newArray);
    }
  };

  let bannerPagination = Array(Math.ceil(totalBannerCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  const ChangeBannerPage = (i) => {
    activeBannerPag.current = i;

    changeBannerData(
      activeBannerPag.current * pageLimit,
      (activeBannerPag.current + 1) * pageLimit
    );
  };

  const changeBannerData = async (first, sec) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Banner/GetBanners?skip=" +
          first +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setBanners(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /* Banners */

  /* Contents */
  const GetContents = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "Content/GetContents?skip=0&take=" + pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalContentCount(result.count);
        setContents(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);

  const AssignContent = async (Content) => {
    if (
      !assignedContents.some(
        (content) => content.contentId === Content.contentId
      )
    ) {
      let array = [...assignedContents];
      let newArray = array.concat(Content);
      setAssignedContents(newArray);
    }
  };

  const UnassignContent = async (Content) => {
    if (
      assignedContents.some(
        (content) => content.contentId === Content.contentId
      )
    ) {
      let array = [...assignedContents];
      let newArray = array.filter(
        (content) => content.contentId !== Content.contentId
      );
      setAssignedContents(newArray);
    }
  };

  let contentPagination = Array(Math.ceil(totalContentCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  const ChangeContentPage = (i) => {
    activeContentPag.current = i;

    changeContentData(
      activeContentPag.current * pageLimit,
      (activeContentPag.current + 1) * pageLimit
    );
  };

  const changeContentData = async (first, sec) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "Content/GetContents?skip=" +
          first +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setContents(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /* Contents */

  /* News */
  const GetNews = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "News/GetNews?skip=0&take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalNewsCount(result.count);
        setNews(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);
  const AssignNews = async (News) => {
    if (!assignedNews.some((n) => n.newsId === News.newsId)) {
      let array = [...assignedNews];
      let newArray = array.concat(News);
      setAssignedNews(newArray);
    }
  };
  const UnassignNews = async (News) => {
    if (assignedNews.some((n) => n.newsId === News.newsId)) {
      let array = [...assignedNews];
      let newArray = array.filter((n) => n.newsId !== News.newsId);
      setAssignedNews(newArray);
    }
  };

  let newsPagination = Array(Math.ceil(totalNewsCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const ChangeNewsPage = (i) => {
    activeNewsPag.current = i;

    changeNewsData(
      activeNewsPag.current * pageLimit,
      (activeNewsPag.current + 1) * pageLimit
    );
  };

  const changeNewsData = async (first, sec) => {
    try {
      const response = await fetch(
        Global.API_URL + "News/GetNews?skip=" + first + "&take=" + pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setNews(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /* News */

  /* Offers */
  const GetOffers = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "Offer/GetOffers?skip=0&take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        setOffers(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);

  const AssignOffers = async (Offers) => {
    if (!assignedOffers.some((offer) => offer.offersId === Offers.offersId)) {
      let array = [...assignedOffers];
      let newArray = array.concat(Offers);
      setAssignedOffers(newArray);
    }
  };
  const UnassignOffers = async (Offers) => {
    if (assignedOffers.some((offer) => offer.offersId === Offers.offersId)) {
      let array = [...assignedOffers];
      let newArray = array.filter(
        (offer) => offer.offersId !== Offers.offersId
      );
      setAssignedOffers(newArray);
    }
  };
  /* Offers */

  /* Reviews */
  const GetReviews = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "HotelRating/GetRatingsByHotelID?skip=0&take=10",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalReviewsCount(result.count);
        setReviews(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);
  const AssignReviews = async (Reviews) => {
    if (
      !assignedReviews.some(
        (review) => review.hotelRatingId === Reviews.hotelRatingId
      )
    ) {
      let array = [...assignedReviews];
      let newArray = array.concat(Reviews);
      setAssignedReviews(newArray);
    }
  };
  const UnassignReviews = async (Reviews) => {
    if (
      assignedReviews.some(
        (review) => review.hotelRatingId === Reviews.hotelRatingId
      )
    ) {
      let array = [...assignedReviews];
      let newArray = array.filter(
        (review) => review.hotelRatingId !== Reviews.hotelRatingId
      );
      setAssignedReviews(newArray);
    }
  };
  let reviewspagination = Array(Math.ceil(totalReviewsCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  const ChangeReviewsPage = (i) => {
    activeReviewsPag.current = i;

    changeReviewsData(
      activeReviewsPag.current * pageLimit,
      (activeReviewsPag.current + 1) * pageLimit
    );
  };

  const changeReviewsData = async (first, sec) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "HotelRating/GetRatingsByHotelID?skip=" +
          first +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setTotalReviewsCount(result.count);
        setReviews(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /* Reviews */

  /* Rooms */
  const GetRooms = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "Room/GetRoomsForDropDown",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setRooms(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);
  const AssignRoom = async (Room) => {
    if (!assignedRooms.some((room) => room.roomId === Room.roomId)) {
      let array = [...assignedRooms];
      let newArray = array.concat(Room);
      setAssignedRooms(newArray);
    }
  };
  const UnassignRoom = async (Room) => {
    if (assignedRooms.some((room) => room.roomId === Room.roomId)) {
      let array = [...assignedRooms];
      let newArray = array.filter((room) => room.roomId !== Room.roomId);
      setAssignedRooms(newArray);
    }
  };
  /* Rooms */

  /* Services */
  const GetServices = useCallback(async () => {
    try {
      const response = await fetch(
        Global.API_URL + "RoomService/GetRoomServicesDropdownList",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        let array = result.message;
        setServices(array);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userToken]);
  const AssignService = async (Service) => {
    if (
      !assignedServices.some(
        (service) => service.serviceId === Service.serviceId
      )
    ) {
      let array = [...assignedServices];
      let newArray = array.concat(Service);
      setAssignedServices(newArray);
    }
  };
  const UnassignService = async (Service) => {
    if (
      assignedServices.some(
        (service) => service.serviceId === Service.serviceId
      )
    ) {
      let array = [...assignedServices];
      let newArray = array.filter(
        (service) => service.serviceId !== Service.serviceId
      );
      setAssignedServices(newArray);
    }
  };
  /* Services */

  const InsertWidget = async () => {
    if (widgetName.trim() === "") {
      toast.error("Widget Name is required");
    } else if (widgetTypeId === 0) {
      toast.error("Widget Type is required");
    } else if (
      (widgetTypeId === 1 ||
        widgetTypeId === 5 ||
        widgetTypeId === 7 ||
        widgetTypeId === 8) &&
      widgetStyleId === 0
    ) {
      toast.error("Widget Style is required");
    } else if (widgetDescription.trim() === "") {
      toast.error("Widget Description is required");
    } else if (widgetTypeId === 1 && assignedAllowedItems.length === 0) {
      toast.error("At least one Allowed Item must be assigned");
    } else if (widgetTypeId === 7 && assignedRooms.length === 0) {
      toast.error("At least one Room must be assigned");
    } else if (widgetTypeId === 8 && assignedServices.length === 0) {
      toast.error("At least one Service must be assigned");
    } else {
      let assignedWidgetToAllowedItems = [],
        assignedWidgetToBanners = [],
        assignedWidgetToContents = [],
        assignedWidgetToNews = [],
        assignedWidgetToOffers = [],
        assignedWidgetToReviews = [],
        assignedWidgetToRooms = [],
        assignedWidgetToServices = [];
      assignedAllowedItems.forEach((allowedItem) => {
        assignedWidgetToAllowedItems.push(allowedItem.itemId);
      });
      assignedBanners.forEach((banner) => {
        assignedWidgetToBanners.push(banner.bannerId);
      });
      assignedContents.forEach((content) => {
        assignedWidgetToContents.push(content.contentId);
      });
      assignedNews.forEach((News) => {
        assignedWidgetToNews.push(News.newsId);
      });
      assignedOffers.forEach((offer) => {
        assignedWidgetToOffers.push(offer.offerId);
      });
      assignedReviews.forEach((review) => {
        assignedWidgetToReviews.push(review.hotelRatingId);
      });
      assignedServices.forEach((service) => {
        assignedWidgetToServices.push(service.serviceId);
      });
      assignedRooms.forEach((room) => {
        assignedWidgetToRooms.push(room.roomId);
      });
      try {
        const response = await fetch(Global.API_URL + "Widget/AddWidget", {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
          body: JSON.stringify({
            widgetTitle: widgetName.trim(),
            widgetDescription: widgetDescription.trim(),
            widgetUrl: widgetUrl,
            widgetTypeId: widgetTypeId,
            widgetStyleId: widgetStyleId,
            isPublished: isPublished,
            assignedWidgetToAllowedItems: assignedWidgetToAllowedItems,
            assignedWidgetToBanners: assignedWidgetToBanners,
            assignedWidgetToContents: assignedWidgetToContents,
            assignedWidgetToNews: assignedWidgetToNews,
            assignedWidgetToOffers: assignedWidgetToOffers,
            assignedWidgetToReviews: assignedWidgetToReviews,
            assignedWidgetToRooms: assignedWidgetToRooms,
            assignedWidgetToServices: assignedWidgetToServices,
          }),
        });
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/widget");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const UpdateWidget = async () => {
    if (widgetName === "") {
      toast.error("Widget Name is required");
    } else if (widgetTypeId === 0) {
      toast.error("Widget Type is required");
    } else if (
      (widgetTypeId === 1 ||
        widgetTypeId === 5 ||
        widgetTypeId === 7 ||
        widgetTypeId === 8) &&
      widgetStyleId === 0
    ) {
      toast.error("Widget Style is required");
    } else if (widgetDescription === "") {
      toast.error("Widget Description is required");
    } else if (widgetTypeId === 1 && assignedAllowedItems.length === 0) {
      toast.error("At least one Allowed Item must be assigned");
    } else if (widgetDescription.trim() === "") {
      toast.error("Widget Description is required");
    } else if (widgetTypeId === 1 && assignedAllowedItems.length === 0) {
      toast.error("At least one Allowed Item must be assigned");
    } else if (widgetTypeId === 7 && assignedRooms.length === 0) {
      toast.error("At least one Room must be assigned");
    } else if (widgetTypeId === 8 && assignedServices.length === 0) {
      toast.error("At least one Service must be assigned");
    } else {
      let assignedWidgetToAllowedItems = [],
        assignedWidgetToBanners = [],
        assignedWidgetToContents = [],
        assignedWidgetToNews = [],
        assignedWidgetToOffers = [],
        assignedWidgetToReviews = [],
        assignedWidgetToRooms = [],
        assignedWidgetToServices = [];
      assignedAllowedItems.forEach((allowedItem) => {
        assignedWidgetToAllowedItems.push(allowedItem.itemId);
      });
      assignedBanners.forEach((banner) => {
        assignedWidgetToBanners.push(banner.bannerId);
      });
      assignedContents.forEach((content) => {
        assignedWidgetToContents.push(content.contentId);
      });
      assignedNews.forEach((News) => {
        assignedWidgetToNews.push(News.newsId);
      });
      assignedOffers.forEach((offer) => {
        assignedWidgetToOffers.push(offer.offerId);
      });
      assignedReviews.forEach((review) => {
        assignedWidgetToReviews.push(review.hotelRatingId);
      });
      assignedServices.forEach((service) => {
        assignedWidgetToServices.push(service.serviceId);
      });
      assignedRooms.forEach((room) => {
        assignedWidgetToRooms.push(room.roomId);
      });
      try {
        const response = await fetch(
          Global.API_URL + "Widget/EditWidget?widgetid=" + widget_id,
          {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              widgetTitle: widgetName.trim(),
              widgetDescription: widgetDescription.trim(),
              widgetUrl: widgetUrl,
              widgetTypeId: widgetTypeId,
              widgetStyleId: widgetStyleId,
              isPublished: isPublished,
              assignedWidgetToAllowedItems: assignedWidgetToAllowedItems,
              assignedWidgetToBanners: assignedWidgetToBanners,
              assignedWidgetToContents: assignedWidgetToContents,
              assignedWidgetToNews: assignedWidgetToNews,
              assignedWidgetToOffers: assignedWidgetToOffers,
              assignedWidgetToReviews: assignedWidgetToReviews,
              assignedWidgetToRooms: assignedWidgetToRooms,
              assignedWidgetToServices: assignedWidgetToServices,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/widget");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const GetWidgetStyle = useCallback(
    async (TypeId) => {
      setWidgetTypeId(TypeId);
      setAssignedAllowedItems([]);
      setAssignedBanners([]);
      setAssignedContents([]);
      setAssignedNews([]);
      setAssignedOffers([]);
      setAssignedReviews([]);
      setAssignedRooms([]);
      setAssignedServices([]);
      // setWidgetStyleId(0);
      if (TypeId !== 0) {
        try {
          const response = await fetch(
            Global.API_URL +
              "WidgetStyle/GetWidgetStyleByWidgetTypeId?widgetTypeId=" +
              TypeId,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            let array = result.message;
            if (array.length > 0) {
              setWidgetStyles(array);
            }
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }

        if (TypeId === 1) {
          GetAllowedItems();
        } else if (TypeId === 2) {
          GetBanners();
        } else if (TypeId === 3) {
          GetContents();
        } else if (TypeId === 4) {
          GetNews();
        } else if (TypeId === 5) {
          GetOffers();
        } else if (TypeId === 6) {
          GetReviews();
        } else if (TypeId === 7) {
          GetRooms();
        } else {
          GetServices();
        }
      }
      if (
        parseInt(TypeId) !== 1 &&
        parseInt(TypeId) !== 5 &&
        parseInt(TypeId) !== 7 &&
        parseInt(TypeId) !== 8
      ) {
        setWidgetStyleId(0);
      }
    },
    [
      GetAllowedItems,
      GetBanners,
      GetContents,
      GetOffers,
      GetNews,
      GetReviews,
      GetRooms,
      GetServices,
      userToken,
    ]
  );
  useEffect(() => {
    const GetWidgetType = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "WidgetType/GetWidgetTypes",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          setWidgetTypes(array);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const GetWidgetById = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Widget/GetWidgetById?widgetid=" + widget_id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setWidgetName(result.message.widgetTitle);
          setIsPublished(result.message.isPublished);
          setWidgetDescription(result.message.widgetDescription);
          setWidgetTypeId(result.message.widgetTypeId);
          GetWidgetStyle(parseInt(result.message.widgetTypeId));
          setWidgetStyleId(result.message.widgetStyleId);
          setWidgetUrl(result.message.widgetUrl);
          if (result.message.assignedWidgetToAllowedItems) {
            setAssignedAllowedItems(
              result.message.assignedWidgetToAllowedItems
            );
          }
          if (result.message.assignedWidgetToBanners) {
            setAssignedBanners(result.message.assignedWidgetToBanners);
          }
          if (result.message.assignedWidgetToContents) {
            setAssignedContents(result.message.assignedWidgetToContents);
          }
          if (result.message.assignedWidgetToNews) {
            setAssignedNews(result.message.assignedWidgetToNews);
          }
          if (result.message.assignedWidgetToOffers) {
            setAssignedOffers(result.message.assignedWidgetToOffers);
          }
          if (result.message.assignedWidgetToReviews) {
            setAssignedReviews(result.message.assignedWidgetToReviews);
          }
          if (result.message.assignedWidgetToRooms) {
            setAssignedRooms(result.message.assignedWidgetToRooms);
          }
          if (result.message.assignedWidgetToServices) {
            setAssignedServices(result.message.assignedWidgetToServices);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetWidgetType();
    if (parseInt(widget_id) !== 0) {
      GetWidgetById();
    }
    setIsLoading(false);
  }, [widget_id, userToken, GetWidgetStyle]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            onClick={parseInt(widget_id) === 0 ? InsertWidget : UpdateWidget}
          >
            Submit
          </button>
          <Link to={"/widget"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Widget Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Widget name"
                      value={widgetName}
                      onChange={(e) => setWidgetName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>Widget Url</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Widget Url"
                      value={widgetUrl}
                      disabled
                      onChange={(e) => setWidgetUrl(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Widget Type<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={widgetTypeId}
                      onChange={(e) => GetWidgetStyle(parseInt(e.target.value))}
                    >
                      <option value={0}>Choose...</option>
                      {widgetTypes.map((widgetType, index) => (
                        <option value={widgetType.widgetTypeId} key={index}>
                          {widgetType.widgetTypeName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {widgetTypeId === 1 ||
                  widgetTypeId === 5 ||
                  widgetTypeId === 7 ||
                  widgetTypeId === 8 ? (
                    <div className="form-group mb-4 col-md-6">
                      <label>
                        Widget Style<span className="text-danger">*</span>
                      </label>
                      <select
                        id="inputState"
                        className="form-control"
                        value={widgetStyleId}
                        onChange={(e) =>
                          setWidgetStyleId(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>Choose...</option>
                        {widgetStyles.map((widgetStyle, index) => (
                          <option value={widgetStyle.widgetStyleId} key={index}>
                            {widgetStyle.widgetStyleName}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Description<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows="8"
                      placeholder="Description"
                      value={widgetDescription}
                      onChange={(e) => setWidgetDescription(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                {widgetTypeId === 1 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Allowed Items<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedAllowedItems(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedAllowedItems}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Allowed Items</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedAllowedItems(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {allowedItems.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Item</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allowedItems.map((item, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {item.itemId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {item.itemName}
                                          </span>
                                        </td>
                                        {!assignedAllowedItems.some(
                                          (assignAllowed) =>
                                            assignAllowed.itemId === item.itemId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignAllowItem(item)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedAllowedItems(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Item</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedAllowedItems.length > 0 ? (
                                assignedAllowedItems.map((item, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {item.itemId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {item.itemName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignAllowItem(item)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {widgetTypeId === 2 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Banners<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedBanners(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedBanners}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Banners</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedBanners(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {banners.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Banner</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {banners.map((banner, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {banner.bannerId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {banner.bannerName}
                                          </span>
                                        </td>
                                        {!assignedBanners.some(
                                          (assignBanner) =>
                                            assignBanner.bannerId ===
                                            banner.bannerId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignBanner(banner)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing{" "}
                                    {activeBannerPag.current * pageLimit + 1}
                                    of {totalBannerCount} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                      onClick={() =>
                                        activeBannerPag.current > 0 &&
                                        ChangeBannerPage(
                                          activeBannerPag.current - 1
                                        )
                                      }
                                    >
                                      <i className="fa fa-angle-double-left"></i>{" "}
                                      Previous
                                    </Link>
                                    <span>
                                      {bannerPagination.map((number, i) => (
                                        <Link
                                          key={i}
                                          className={`paginate_button  ${
                                            activeBannerPag.current === i
                                              ? "current"
                                              : ""
                                          } `}
                                          onClick={() => ChangeBannerPage(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>

                                    <Link
                                      className="paginate_button next"
                                      onClick={() =>
                                        activeBannerPag.current + 1 <
                                          bannerPagination.length &&
                                        changeBannerData(
                                          activeBannerPag.current + 1
                                        )
                                      }
                                    >
                                      Next{" "}
                                      <i className="fa fa-angle-double-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedBanners(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Banner</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedBanners.length > 0 ? (
                                assignedBanners.map((banner, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {banner.bannerId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {banner.bannerName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignBanner(banner)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {widgetTypeId === 3 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Contents<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedContents(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedContents}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Contents</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedContents(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {contents.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Content</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {contents.map((content, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {content.contentId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {content.contentTitle}
                                          </span>
                                        </td>
                                        {!assignedContents.some(
                                          (assignContent) =>
                                            assignContent.contentId ===
                                            content.contentId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignContent(content)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing{" "}
                                    {activeContentPag.current * pageLimit + 1}
                                    of {totalContentCount} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                      onClick={() =>
                                        activeContentPag.current > 0 &&
                                        ChangeContentPage(
                                          activeContentPag.current - 1
                                        )
                                      }
                                    >
                                      <i className="fa fa-angle-double-left"></i>{" "}
                                      Previous
                                    </Link>
                                    <span>
                                      {contentPagination.map((number, i) => (
                                        <Link
                                          key={i}
                                          className={`paginate_button  ${
                                            activeContentPag.current === i
                                              ? "current"
                                              : ""
                                          } `}
                                          onClick={() => ChangeContentPage(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>

                                    <Link
                                      className="paginate_button next"
                                      onClick={() =>
                                        activeContentPag.current + 1 <
                                          contentPagination.length &&
                                        ChangeContentPage(
                                          activeContentPag.current + 1
                                        )
                                      }
                                    >
                                      Next{" "}
                                      <i className="fa fa-angle-double-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedContents(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Content</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedContents.length > 0 ? (
                                assignedContents.map((content, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {content.contentId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {content.contentTitle}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignContent(content)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {widgetTypeId === 4 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        News<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedNews(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedNews}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>News</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedNews(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {news.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>News</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {news.map((n, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {n.newsId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {n.newsTitle}
                                          </span>
                                        </td>
                                        {!assignedNews.some(
                                          (assignNews) =>
                                            assignNews.newsId === n.newsId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() => AssignNews(n)}
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing{" "}
                                    {activeNewsPag.current * pageLimit + 1}
                                    of {totalNewsCount} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                      onClick={() =>
                                        activeNewsPag.current > 0 &&
                                        ChangeNewsPage(
                                          activeNewsPag.current - 1
                                        )
                                      }
                                    >
                                      <i className="fa fa-angle-double-left"></i>{" "}
                                      Previous
                                    </Link>
                                    <span>
                                      {newsPagination.map((number, i) => (
                                        <Link
                                          key={i}
                                          className={`paginate_button  ${
                                            activeNewsPag.current === i
                                              ? "current"
                                              : ""
                                          } `}
                                          onClick={() => ChangeNewsPage(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>

                                    <Link
                                      className="paginate_button next"
                                      onClick={() =>
                                        activeNewsPag.current + 1 <
                                          newsPagination.length &&
                                        ChangeNewsPage(
                                          activeNewsPag.current + 1
                                        )
                                      }
                                    >
                                      Next{" "}
                                      <i className="fa fa-angle-double-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedNews(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>News</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedNews.length > 0 ? (
                                assignedNews.map((n, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {n.newsId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {n.newsTitle}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignNews(n)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {widgetTypeId === 5 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Offers<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedOffers(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedOffers}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Offers</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedOffers(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {offers.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Offer</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {offers.map((offer, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {offer.offerId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {offer.offerName}
                                          </span>
                                        </td>
                                        {!assignedOffers.some(
                                          (assignOffer) =>
                                            assignOffer.offerId ===
                                            offer.offerId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignOffers(offer)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedOffers(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>News</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedOffers.length > 0 ? (
                                assignedOffers.map((offer, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {offer.offerId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {offer.offerName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignOffers(offer)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {widgetTypeId === 6 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Reviews<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedReviews(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedReviews}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Reviews</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedReviews(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {reviews.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th>Phone Number</th>
                                      <th>Rate</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {reviews.map((rate, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {rate.hotelRatingId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {rate.firstName +
                                              " " +
                                              rate.lastName}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {rate.hotelGuestEmail}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {rate.phoneNumber}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {rate.starRating}
                                          </span>
                                        </td>
                                        {!assignedReviews.some(
                                          (assignReview) =>
                                            assignReview.hotelRatingId ===
                                            rate.hotelRatingId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignReviews(rate)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info">
                                    Showing{" "}
                                    {activeReviewsPag.current * pageLimit + 1}
                                    of {totalReviewsCount} entries
                                  </div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Link
                                      className="paginate_button previous disabled"
                                      onClick={() =>
                                        activeReviewsPag.current > 0 &&
                                        ChangeReviewsPage(
                                          activeReviewsPag.current - 1
                                        )
                                      }
                                    >
                                      <i className="fa fa-angle-double-left"></i>{" "}
                                      Previous
                                    </Link>
                                    <span>
                                      {reviewspagination.map((number, i) => (
                                        <Link
                                          key={i}
                                          className={`paginate_button  ${
                                            activeReviewsPag.current === i
                                              ? "current"
                                              : ""
                                          } `}
                                          onClick={() => ChangeReviewsPage(i)}
                                        >
                                          {number}
                                        </Link>
                                      ))}
                                    </span>

                                    <Link
                                      className="paginate_button next"
                                      onClick={() =>
                                        activeReviewsPag.current + 1 <
                                          reviewspagination.length &&
                                        ChangeReviewsPage(
                                          activeReviewsPag.current + 1
                                        )
                                      }
                                    >
                                      Next{" "}
                                      <i className="fa fa-angle-double-right"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedReviews(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Rate</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedReviews.length > 0 ? (
                                assignedReviews.map((rate, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {rate.hotelRatingId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {rate.firstName + " " + rate.lastName}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {rate.hotelGuestEmail}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {rate.phoneNumber}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {rate.starRating}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignReviews(rate)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {widgetTypeId === 7 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Rooms<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedRooms(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedRooms}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Rooms</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedRooms(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {rooms.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Room Name</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rooms.map((room, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {room.roomId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {room.roomName}
                                          </span>
                                        </td>
                                        {!assignedRooms.some(
                                          (assignRoom) =>
                                            assignRoom.roomId === room.roomId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() => AssignRoom(room)}
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedRooms(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Room Name</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedRooms.length > 0 ? (
                                assignedRooms.map((room, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {room.roomId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {room.roomName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignRoom(room)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {widgetTypeId === 8 && (
                  <div className="row assigned-content">
                    <div className="form-group mb-4 col-md-12">
                      <h4>
                        Services<span className="text-danger">*</span>{" "}
                        <span
                          onClick={() => setIsOpenAssignedServices(true)}
                          className="add-icon"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="var(--primary)"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            {" "}
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />{" "}
                          </svg>
                        </span>
                      </h4>{" "}
                      <Modal
                        className="fade bd-example-modal-lg"
                        show={isOpenAssignedServices}
                        size="lg"
                      >
                        <Modal.Header>
                          <Modal.Title>Services</Modal.Title>
                          <Button
                            variant=""
                            className="btn-close"
                            onClick={() => setIsOpenAssignedServices(false)}
                          ></Button>
                        </Modal.Header>
                        <Modal.Body>
                          {services.length === 0 ? (
                            <div className="text-center">
                              <p className="m-0">There is no items yet</p>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <div
                                id="room_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                                  <thead>
                                    <tr role="row">
                                      <th>Id</th>
                                      <th>Service Name</th>
                                      <th className="bg-none"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {services.map((service, index) => (
                                      <tr
                                        role="row"
                                        className={
                                          index % 2 === 0 ? "even" : "odd"
                                        }
                                        key={index}
                                      >
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {service.serviceId}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="fs-16 font-w500 text-nowrap">
                                            {service.serviceName}
                                          </span>
                                        </td>
                                        {!assignedServices.some(
                                          (assignService) =>
                                            assignService.serviceId ===
                                            service.serviceId
                                        ) && (
                                          <td>
                                            <button
                                              type="submit"
                                              className="btn btn-outline-primary margin-right-15"
                                              onClick={() =>
                                                AssignService(service)
                                              }
                                            >
                                              <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill="none"
                                                  d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path d="M17 12h-3V8h-4v4H7l5 5zm2-9h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                              </svg>
                                            </button>
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={() => setIsOpenAssignedServices(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table role-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>Service Name</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {assignedServices.length > 0 ? (
                                assignedServices.map((service, index) => (
                                  <tr
                                    role="row"
                                    className={index % 2 === 0 ? "even" : "odd"}
                                    key={index}
                                  >
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {service.serviceId}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="fs-16 font-w500 text-nowrap">
                                        {service.serviceName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => UnassignService(service)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M14 11V17"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4 7H20"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2" className="empty-message">
                                    No records found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WidgetManage;
