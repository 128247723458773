import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";

const ContentManage = () => {
  const { content_id } = useParams();
  const navigate = useNavigate();
  const { userToken, userId } = useSelector((state) => state.credential);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve) => {
          loader.file.then(async (file) => {
            const formData = new FormData();
            formData.append("Images", file);
            formData.append("Sender", "A");
            formData.append("SenderID", userId);

            try {
              const response = await axios.post(
                Global.API_URL + "Others/UploadSingleImage",
                formData,
                {
                  headers: {
                    "bs-api-key": Global.bs_api_key,
                    AuthorizationUser: userToken,
                  },
                }
              );

              resolve({
                default: `${Global.PHOTO_URL}${response.data[0]}`,
              });
            } catch (ex) {
              console.log(ex);
            }
          });
        });
      },
    };
  }

  const AddContent = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    if (
      title.trim() === "" ||
      description.trim() === "" ||
      description === null ||
      (!file && image === "")
    ) {
      toast.error("All fields are required");
    } else {
      try {
        var formdata = new FormData();
        formdata.append("ContentTitle", title);
        formdata.append("ContentImage", file);
        formdata.append("ContentDescription", description);
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);

        const response = await fetch(Global.API_URL + "Content/AddContent", {
          method: "POST",
          headers: {
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
          body: formdata,
          redirect: "follow",
        });
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/content");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const EditContent = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    if (
      title.trim() === "" ||
      description.trim() === "" ||
      description === null ||
      (!file && image === "")
    ) {
      toast.error("All fields are required");
    } else {
      try {
        var formdata = new FormData();
        formdata.append("ContentTitle", title);
        formdata.append("ContentImage", file);
        formdata.append("ContentImageUrl", image);
        formdata.append("ContentDescription", description);
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);

        const response = await fetch(
          Global.API_URL + "Content/EditContent?contentid=" + content_id,
          {
            method: "PUT",
            headers: {
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: formdata,
            redirect: "follow",
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/content");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const UploadImage = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setImage(reader.result);
    };
  };

  useEffect(() => {
    const GetContent = async () => {
      if (parseInt(content_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Content/GetContentById?contentid=" + content_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            setTitle(result.message.contentTitle);
            setImage(result.message.contentImage);
            setDescription(result.message.contentDescription);
            setIsLoading(false);
            setDisableSubmit(false);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setIsLoading(false);
        setDisableSubmit(false);
      }
    };

    GetContent();
  }, [userToken, content_id]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={(e) => {
              parseInt(content_id) === 0 ? AddContent(e) : EditContent(e);
            }}
          >
            Submit
          </button>
          <Link
            to={"/content"}
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-12">
                    <label>
                      Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 col-md-12">
                    <label>
                      Image<span className="text-danger">*</span>
                    </label>
                    <br />
                    {image && (
                      <img
                        style={{ height: "200px" }}
                        src={file ? image : Global.PHOTO_URL + image}
                        alt="hotel"
                      />
                    )}
                    <br />
                    <br />
                    <input type="file" onChange={UploadImage} />
                  </div>
                  <div className="mb-3 col-md-12">
                    <label>
                      Content<span className="text-danger">*</span>
                    </label>
                    <CKEditor
                      config={{
                        extraPlugins: [uploadPlugin],
                      }}
                      
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContentManage;
