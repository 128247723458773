import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";

const MenuManage = () => {
  const { userToken } = useSelector((state) => state.credential);
  const { menu_id } = useParams();
  const [menuName, setMenuName] = useState("");
  const [menuOrder, setMenuOrder] = useState(0);
  const [menuUrl, setMenuUrl] = useState("");
  const [isPublished, setIsPublished] = useState(true);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const InsertMenu = async () => {
    if (menuName.trim() === "") {
      toast.error("Menu Name is required");
    } else if (menuUrl.trim() === "") {
      toast.error("Menu Url is required");
    } else {
      setIsLoading(true);
      setDisableSubmit(true);
      try {
        const response = await fetch(Global.API_URL + "Menu/AddMenu", {
          method: "Post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
          body: JSON.stringify({
            menuName: menuName,
            menuOrder: menuOrder,
            menuUrl: menuUrl,
            isPublished: isPublished,
          }),
        });
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/menu");
        } else {
          toast.error(result.message);
          setIsLoading(false);
          setDisableSubmit(false);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      setDisableSubmit(false);
    }
  };
  const UpdateMenu = async () => {
    if (menuName.trim() === "") {
      toast.error("Menu Name is required");
    } else if (menuUrl.trim() === "") {
      toast.error("Menu Url is required");
    } else {
      setIsLoading(true);
      setDisableSubmit(true);
      try {
        const response = await fetch(
          Global.API_URL + "Menu/EditMenu?menuid=" + menu_id,
          {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              menuName: menuName,
              menuOrder: menuOrder,
              menuUrl: menuUrl,
              isPublished: isPublished,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/menu");
        } else {
          toast.error(result.message);
          setIsLoading(false);
          setDisableSubmit(false);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      setDisableSubmit(false);
    }
  };
  useEffect(() => {
    const GetMenuById = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Menu/GetMenuById?menuid=" + menu_id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setMenuName(result.message.menuName);
          setMenuOrder(result.message.menuOrder);
          setMenuUrl(result.message.menuUrl);
          setIsPublished(result.message.isPublished);
          setIsLoading(false);
          setDisableSubmit(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (parseInt(menu_id) !== 0) {
      GetMenuById();
    } else {
      setIsLoading(false);
      setDisableSubmit(false);
    }
  }, [menu_id,userToken]);
  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={parseInt(menu_id) === 0 ? InsertMenu : UpdateMenu}
          >
            Submit
          </button>
          <Link to={"/menu"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Menu Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Menu name"
                      value={menuName}
                      onChange={(e) => setMenuName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Menu Url<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Url"
                      value={menuUrl}
                      onChange={(e) => setMenuUrl(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Menu Order<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Menu Order"
                      value={menuOrder}
                      min={0}
                      onChange={(e) => setMenuOrder(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MenuManage;
