import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Tab, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import { Global } from "../../../../Global";

const ChartBarApex = loadable(() => pMinDelay(import("./ChartBarApex"), 1000));
const ChartBarApex2 = loadable(() =>
  pMinDelay(import("./ChartBarApex2"), 1000)
);
const ChartBarApex3 = loadable(() =>
  pMinDelay(import("./ChartBarApex3"), 1000)
);

const ReservationStats = () => {
  const [chartkey, setChartkey] = useState(3);
  const [monthlyChart, setMonthlyChart] = useState({
    checkinMonths: [],
    checkoutMonths: [],
  });
  const [weeklyChart, setWeeklyChart] = useState({
    checkinWeeks: [],
    checkoutWeeks: [],
  });
  const [dailyChart, setDailyChart] = useState({
    checkinDays: [],
    checkoutDays: [],
  });
  useEffect(() => {
    const GetDashboardChart = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Dashboard/GetDashboardChart?chartkey=" + chartkey,
          {
            method: "Get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Global.HostAPI,
              "bs-api-key": Global.bs_api_key,
            },
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          if (chartkey === 1) {
            setDailyChart(result.message.dailyChart);
          } else if (chartkey === 2) {
            setWeeklyChart(result.message.weeklyChart);
          } else {
            setMonthlyChart(result.message.monthlyChart);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };
    GetDashboardChart();
  }, [chartkey]);

  return (
    <>
      <Tab.Container defaultActiveKey="Monthly">
        <div className="card">
          <div className="card-header border-0 flex-wrap">
            <h4 className="fs-20">Reservation Stats</h4>
            <div className="card-action coin-tabs">
              <Nav as="ul" className="nav nav-tabs">
                <Nav.Item
                  as="li"
                  className="nav-item"
                  onClick={() => setChartkey(1)}
                >
                  <Nav.Link eventKey="Daily">Daily</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className="nav-item"
                  onClick={() => setChartkey(2)}
                >
                  <Nav.Link eventKey="Weekly">Weekly</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className="nav-item"
                  onClick={() => setChartkey(3)}
                >
                  <Nav.Link className="nav-link" eventKey="Monthly">
                    Monthly
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="card-body pb-0">
            <div className="d-flex flex-wrap">
              <span className="me-sm-5 me-0 font-w500">
                <svg
                  className="me-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                >
                  <rect width="13" height="13" fill="#207b95" />
                </svg>
                Check In
              </span>

              <span className="me-sm-5 ms-0 font-w500">
                <svg
                  className="me-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                >
                  <rect width="13" height="13" fill="#df865d" />
                </svg>
                Check Out
              </span>
            </div>
            <Tab.Content>
              <Tab.Pane className="tab-pane" eventKey="Daily">
                <div id="chartBar" className="chartBar">
                  <ChartBarApex3 dailyChart={dailyChart} />
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="Weekly">
                <div id="chartBar1" className="chartBar">
                  <ChartBarApex2 weeklyChart={weeklyChart} />
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="Monthly">
                <div id="chartBar2" className="chartBar">
                  <ChartBarApex monthlyChart={monthlyChart} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};
export default ReservationStats;
