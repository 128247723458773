const { createSlice } = require("@reduxjs/toolkit");

const credentialSlice = createSlice({
  name: "credential",
  initialState: {
    isLogged: false,
    userId: 0,
    userToken: "",
    email: "",
    role: {
      manageNotifications: false,
      manageMedia: false,
      listMedia: false,
      deleteMedia: false,
      manageCustomer: false,
      listCustomer: false,
      listCountry: false,
      listcity: false,
      countryPublication: false,
      countrySanctioning: false,
      listHotels: false,
      manageHotels: false,
      deleteHotels: false,
      manageRooms: false,
      deleteRooms: false,
      listRooms: false,
      manageOffers: false,
      listOffers: false,
      deleteOffers: false,
      manageRegulations: false,
      deleteRegulations: false,
      listRegulations: false,
      manageUsers: false,
      listUsers: false,
      deleteUsers: false,
      manageServices: false,
      listServices: false,
      deleteServices: false,
      manageAllowedItems: false,
      listAllowedItems: false,
      deleteAllowedItems: false,
    },
  },
  reducers: {
    setCredential(state, action) {
      const isLogged = action.payload.isLogged;
      const userId = action.payload.userId;
      const userToken = action.payload.userToken;
      const email = action.payload.email;
      const role = action.payload.role;

      if (isLogged) {
        return (state = {
          isLogged: true,
          userId: userId,
          userToken: userToken,
          email: email,
          role: role,
        });
      } else {
        return (state = {
          isLogged: false,
          userId: userId,
          userToken: "",
          email: "",
          role: {
            manageNotifications: false,
            manageMedia: false,
            listMedia: false,
            deleteMedia: false,
            manageCustomer: false,
            listCustomer: false,
            listCountry: false,
            listcity: false,
            countryPublication: false,
            countrySanctioning: false,
            listHotels: false,
            manageHotels: false,
            deleteHotels: false,
            manageRooms: false,
            deleteRooms: false,
            listRooms: false,
            manageOffers: false,
            listOffers: false,
            deleteOffers: false,
            manageRegulations: false,
            deleteRegulations: false,
            listRegulations: false,
            manageUsers: false,
            listUsers: false,
            deleteUsers: false,
            manageServices: false,
            listServices: false,
            deleteServices: false,
            manageAllowedItems: false,
            listAllowedItems: false,
            deleteAllowedItems: false,
          },
        });
      }
    },
  },
});

export const { setCredential } = credentialSlice.actions;
export default credentialSlice.reducer;
