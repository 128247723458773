import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Global } from "../../../../Global";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next fas fa-arrow-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev fas fa-arrow-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

const RoomSlider = ({ roomImage, roomDescription }) => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 591,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider
        className="guest-carousel owl-carousel owl-carousel owl-loaded owl-drag owl-dot"
        {...settings}
      >
        {/* <div className="item">
          <div className="rooms">
            <img
              src={Global.PHOTO_URL + hotelImage}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </div> */}

        <div className="item">
          <div className="rooms">
            <img
              src={Global.PHOTO_URL + roomImage.roomImageURL}
              alt=""
              style={{ width: "100%" }}
            />
            <div className="img-content">
              <h4 className="fs-24 font-w600 text-white">Room</h4>
              <p className="text-white">{roomDescription}</p>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};
export default RoomSlider;
