import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Global } from "../../../../../Global";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const DropdownBlog = ({ bookingId }) => {
  const navigate = useNavigate();

  return (
    <Dropdown className="dropdown dropend ms-auto">
      <Dropdown.Toggle as="div" className="btn-link i-false">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
            stroke="#575757"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
            stroke="#575757"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
            stroke="#575757"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() =>
            navigate("/customer-booking/" + bookingId)
          }
        >
          View Details
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CustomerManage = () => {
  const { userToken } = useSelector((state) => state.credential);
  const { customer_id } = useParams();

  const [customer, setCustomer] = useState({
    billingAddress: [],
    bookings: [],
    doB: "",
    email: "",
    firstName: "",
    isLocked: false,
    isPublished: true,
    isVerified: true,
    lastLoginDate: "",
    lastName: "",
    phoneNumber: "",
    profilePicture: "",
    registeredDate: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  const [addressId, setAddressId] = useState(0);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  //billing address
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [value, setValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cityId, setCityId] = useState(0);
  const [isPublished, setIsPublished] = useState(true);
  const [isDefault, setIsDefault] = useState(true);
  const [cityName, setCityName] = useState("");
  const [countryName, setCountryName] = useState("");

  const AddAddress = async (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      value === undefined ||
      countryCode === "" ||
      cityId === 0
    ) {
      toast.error("All fields are required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else if (!isPossiblePhoneNumber(value)) {
      toast.error("Enter a valid phone number");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "BillingAddressBook/AddBillingAddressBook",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              email: email,
              phoneNumber: value,
              countryCode: countryCode,
              cityId: cityId,
              customerId: parseInt(customer_id),
              isPublished: isPublished,
              isDefault: isDefault,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          let array = [...customer.billingAddress];
          let index = array.findIndex((address) => address.isDefault);
          let newArray = array.concat({
            billingAddressId: result.newId,
            cityName: cityName,
            countryName: countryName,
            cityId: cityId,
            countryCode: countryCode,
            email: email,
            firstName: firstName,
            isDefault: isDefault,
            isPublished: isPublished,
            lastName: lastName,
            phoneNumber: value,
          });
          if (isDefault && index !== -1) newArray[index].isDefault = false;
          setCustomer((prevState) => ({
            ...prevState,
            billingAddress: newArray,
          }));
          setIsOpenAddress(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setValue();
          setCountryCode("");
          setCityId(0);
          setIsPublished(true);
          setIsDefault(true);
          setAddressId(0);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const EditAddress = async (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      value === undefined ||
      countryCode === "" ||
      cityId === 0
    ) {
      toast.error("All fields are required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else if (!isPossiblePhoneNumber(value)) {
      toast.error("Enter a valid phone number");
    } else {
      try {
        const response = await fetch(
          Global.API_URL +
            "BillingAddressBook/EditBookingAddressBook?addressbookid=" +
            addressId,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              email: email,
              phoneNumber: value,
              countryCode: countryCode,
              cityId: cityId,
              customerId: parseInt(customer_id),
              isPublished: isPublished,
              isDefault: isDefault,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          let array = [...customer.billingAddress];
          let defaultIndex = array.findIndex((address) => address.isDefault);
          let index = array.findIndex(
            (address) => address.billingAddressId === addressId
          );
          array[index].firstName = firstName;
          array[index].lastName = lastName;
          array[index].email = email;
          array[index].phoneNumber = value;
          array[index].cityId = cityId;
          array[index].cityName = cityName;
          array[index].countryCode = countryCode;
          array[index].countryName = countryName;
          array[index].isPublished = isPublished;
          array[index].isDefault = isDefault;
          if (isDefault && defaultIndex !== 1)
            array[defaultIndex].isDefault = false;
          setCustomer((prevState) => ({
            ...prevState,
            billingAddress: array,
          }));
          setIsOpenAddress(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setValue();
          setCountryCode("");
          setCityId(0);
          setIsPublished(true);
          setIsDefault(true);
          setAddressId(0);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const OpenAddressModalForEdit = (AddressBook) => {
    GetCitiesByCountryCode(
      AddressBook.countryCode,
      AddressBook.countryName,
      AddressBook.cityId
    );
    setAddressId(AddressBook.billingAddressId);
    setFirstName(AddressBook.firstName);
    setLastName(AddressBook.lastName);
    setEmail(AddressBook.email);
    setValue(AddressBook.phoneNumber);
    setCityId(AddressBook.cityId);
    setCountryCode(AddressBook.countryCode);
    setIsPublished(AddressBook.isPublished);
    setIsDefault(AddressBook.isDefault);
    setIsOpenAddress(true);
  };

  const CloseAddressModal = () => {
    setIsOpenAddress(false);
    setAddressId(0);
    setFirstName("");
    setLastName("");
    setEmail("");
    setValue("");
    setCityId(0);
    setCountryCode("");
    setCityName("");
    setCountryName("");
    setIsPublished(true);
    setIsDefault(true);
  };

  useEffect(() => {
    const GetCountries = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Country/GetListCountries",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setCountries(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetCountries();
  }, [userToken]);

  const GetCitiesByCountryCode = useCallback(
    async (code, name, city) => {
      if (code !== "") {
        try {
          const response = await fetch(
            Global.API_URL + "City/GetCitiesForCountry?countrycode=" + code,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            setCountryCode(code);
            setCountryName(name);
            setCityId(city);
            setCities(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCountryCode("");
        setCountryName("");
        setCityId(0);
        setCities([]);
      }
    },
    [userToken]
  );

  useEffect(() => {
    const GetCustomer = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Customer/GetCustomerByID?customerId=" + customer_id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let customerData = result.message;
          let offset, date, newDate;

          offset = new Date(customerData.doB).getTimezoneOffset();
          date = new Date(customerData.doB);
          newDate = new Date(date.getTime() - offset * 60 * 1000);
          customerData.doB = newDate;

          offset = new Date(customerData.lastLoginDate).getTimezoneOffset();
          date = new Date(customerData.lastLoginDate);
          newDate = new Date(date.getTime() - offset * 60 * 1000);
          customerData.lastLoginDate = newDate;

          offset = new Date(customerData.registeredDate).getTimezoneOffset();
          date = new Date(customerData.registeredDate);
          newDate = new Date(date.getTime() - offset * 60 * 1000);
          customerData.registeredDate = newDate;

          customerData.bookings.forEach((booking) => {
            offset = new Date(booking.checkinDate).getTimezoneOffset();
            date = new Date(booking.checkinDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            booking.checkinDate = newDate;

            offset = new Date(booking.checkoutDate).getTimezoneOffset();
            date = new Date(booking.checkoutDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            booking.checkoutDate = newDate;

            offset = new Date(booking.reservationDate).getTimezoneOffset();
            date = new Date(booking.reservationDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            booking.reservationDate = newDate;
          });

          setCustomer(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    GetCustomer();
  }, [userToken, customer_id]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <Link
            to={"/customer"}
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      {isLoading ? (
        <div className="card-body p-0 main-div-loader">
          <div className="d-flex justify-content-center loader">
            <div
              className="spinner-border "
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mt-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card overflow-hidden">
                  <div className="row m-0">
                    <div className="col-xl-12 p-0">
                      <div className="card-body">
                        <div className="guest-profile">
                          <div className="d-flex">
                            {customer.profilePicture !== "" && (
                              <img
                                src={Global.PHOTO_URL + customer.profilePicture}
                                alt=""
                              />
                            )}
                            <div>
                              <h2 className="font-w600">
                                {customer.firstName + " " + customer.lastName}
                              </h2>
                              <span className="text-secondary">
                                ID {customer_id}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap">
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">Email</span>
                              <span className="font-w500 fs-16">
                                {customer.email}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">Phone Number</span>
                              <span className="font-w500 fs-16">
                                {customer.phoneNumber}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">
                                Date of Birth
                              </span>
                              <span className="font-w500 fs-16">
                                {customer.doB.toLocaleString()}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">
                                Registered Date
                              </span>
                              <span className="font-w500 fs-16">
                                {customer.registeredDate.toLocaleString()}
                              </span>
                            </div>
                            <div className="mt-4">
                              <span className="d-block mb-2">
                                Last Login Date
                              </span>
                              <span className="font-w500 fs-16">
                                {customer.lastLoginDate.toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="facilities">
                          <div className="mb-3 mt-3">
                            <span className="d-block mb-3">Account Status</span>
                            <Link
                              to={"#"}
                              className={
                                customer.isVerified
                                  ? "btn btn-secondary light btn-lg"
                                  : "btn btn-primary light btn-lg"
                              }
                            >
                              {customer.isVerified ? "Verified" : "Unverified"}
                            </Link>
                            <Link
                              to={"#"}
                              className={
                                customer.isPublished
                                  ? "btn btn-secondary light btn-lg"
                                  : "btn btn-primary light btn-lg"
                              }
                            >
                              {customer.isPublished
                                ? "Activated"
                                : "Deactivated"}
                            </Link>
                            <Link
                              to={"#"}
                              className={
                                customer.isLocked
                                  ? "btn btn-primary light btn-lg"
                                  : "btn btn-secondary light btn-lg"
                              }
                            >
                              {customer.isLocked ? "Locked" : "Unlocked"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header border-0">
                    <h4 className="fs-20">Billing Addresses</h4>
                    <button
                      className="btn btn-secondary margin-right-15"
                      onClick={() => setIsOpenAddress(true)}
                    >
                      + New Address
                    </button>
                    <Modal
                      className="fade bd-example-modal-lg"
                      show={isOpenAddress}
                      size="lg"
                    >
                      <Modal.Header>
                        <Modal.Title>Billing Address</Modal.Title>
                        <Button
                          variant=""
                          className="btn-close"
                          onClick={CloseAddressModal}
                        ></Button>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="form-group mb-4 col-md-3">
                            <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isDefault}
                                  onChange={(e) =>
                                    setIsDefault(e.target.checked)
                                  }
                                />
                                Default
                              </label>
                            </div>
                          </div>
                          <div className="form-group mb-4 col-md-3">
                            <div className="form-check form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={isPublished}
                                  onChange={(e) =>
                                    setIsPublished(e.target.checked)
                                  }
                                />
                                Publish
                              </label>
                            </div>
                          </div>
                          <div className="form-group mb-4 col-md-8"></div>
                          <div className="form-group mb-4 col-md-6">
                            <label>
                              First Name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="first name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-4 col-md-6">
                            <label>
                              Last Name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="last name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-4 col-md-6">
                            <label>
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-4 col-md-6">
                            <label>
                              Phone Number<span className="text-danger">*</span>
                            </label>
                            <PhoneInput
                              placeholder="Phone Number"
                              country="LB"
                              value={value}
                              onChange={(e) => setValue(e)}
                              required={true}
                            />
                          </div>
                          <div className="form-group mb-4 col-md-6">
                            <label>
                              Country<span className="text-danger">*</span>
                            </label>
                            <select
                              id="inputState"
                              className="form-control"
                              value={countryCode}
                              onChange={(e) =>
                                GetCitiesByCountryCode(
                                  e.target.value,
                                  e.target.options[e.target.selectedIndex]
                                    .title,
                                  0
                                )
                              }
                            >
                              <option value="">Choose...</option>
                              {countries.map((country, index) => (
                                <option
                                  value={country.countryCode}
                                  key={index}
                                  title={country.countryName}
                                >
                                  {country.countryName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group mb-4 col-md-6">
                            <label>
                              City<span className="text-danger">*</span>
                            </label>
                            <select
                              id="inputState"
                              className="form-control"
                              value={cityId}
                              onChange={(e) => {
                                setCityId(parseInt(e.target.value));
                                setCityName(
                                  e.target.options[e.target.selectedIndex].title
                                );
                              }}
                            >
                              <option value={0}>Choose...</option>
                              {cities.map((city, index) => (
                                <option
                                  value={city.cityId}
                                  key={index}
                                  title={city.cityName}
                                >
                                  {city.cityName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={addressId === 0 ? AddAddress : EditAddress}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="danger light"
                          onClick={CloseAddressModal}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="card-body pt-0">
                    {customer.billingAddress.length === 0 ? (
                      <div className="text-center">
                        <p className="m-0">There is no billing addresses yet</p>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <div
                          id="room_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table customer-list-tbl dataTable no-footer">
                            <thead>
                              <tr role="row">
                                <th>Id</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Country</th>
                                <th>City</th>
                                <th>Default</th>
                                <th>Publish Status</th>
                                <th className="bg-none"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {customer.billingAddress.map((address, index) => (
                                <tr
                                  role="row"
                                  className={index % 2 === 0 ? "even" : "odd"}
                                  key={index}
                                >
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {address.billingAddressId}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {address.firstName}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {address.lastName}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {address.email}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {address.phoneNumber}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {address.countryName}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-16 font-w500 text-nowrap">
                                      {address.cityName}
                                    </span>
                                  </td>
                                  <td>
                                    {address.isDefault ? (
                                      <span className="font-w600 text-success">
                                        DEFAULT
                                      </span>
                                    ) : (
                                      <span className="font-w600 text-danger">
                                        NORMAL
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {address.isPublished ? (
                                      <span className="font-w600 text-success">
                                        <i
                                          className="fa fa-check"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span className="font-w600 text-danger">
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-secondary light"
                                      onClick={() =>
                                        OpenAddressModalForEdit(address)
                                      }
                                    >
                                      Edit Address
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header border-0">
                    <h4 className="fs-20">Purchase History</h4>
                  </div>
                  <div className="card-body pt-0">
                    {customer.bookings.length === 0 ? (
                      <div className="text-center">
                        <p className="m-0">There is no purchases yet</p>
                      </div>
                    ) : (
                      <Fragment>
                        {customer.bookings.map((booking, index) => (
                          <div className="row align-items-center" key={index}>
                            <div className="col-xl-4 col-sm-7">
                              <div className="purchase-history d-flex align-items-center">
                                <img
                                  src={Global.PHOTO_URL + booking.roomImage}
                                  alt=""
                                />
                                <div className="ms-3">
                                  <h4 className="guest-text font-w500">
                                    {booking.roomName}
                                  </h4>
                                  <span className="fs-14 d-block mb-2 text-secondary">
                                    #{booking.roomId}
                                  </span>
                                  <span className="fs-16 text-nowrap">
                                    {booking.reservationDate.toLocaleString()}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-2 col-sm-5 col-6">
                              <div>
                                <span className="d-block">Check In</span>
                                <span className="text-black fs-18 font-w500">
                                  {booking.checkinDate
                                    .toLocaleString()
                                    .substring(
                                      0,
                                      booking.checkinDate
                                        .toLocaleString()
                                        .indexOf(",")
                                    )}
                                </span>
                                <span className="fs-14 d-block">
                                  {booking.checkinDate
                                    .toLocaleString()
                                    .slice(
                                      booking.checkinDate
                                        .toLocaleString()
                                        .indexOf(",") + 1
                                    )}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-sm-4 col-6">
                              <div>
                                <span className="d-block">Check Out</span>
                                <span className="text-black fs-18 font-w500">
                                  {booking.checkoutDate
                                    .toLocaleString()
                                    .substring(
                                      0,
                                      booking.checkoutDate
                                        .toLocaleString()
                                        .indexOf(",")
                                    )}
                                </span>
                                <span className="fs-14 d-block">
                                  {booking.checkoutDate
                                    .toLocaleString()
                                    .slice(
                                      booking.checkoutDate
                                        .toLocaleString()
                                        .indexOf(",") + 1
                                    )}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-sm-4 col-6">
                              <div className="mt-sm-0 mt-3">
                                <span className="d-block mb-2 text-black">
                                  Price
                                </span>
                                <span className="font-w500 fs-18 text-black">
                                  ${booking.totalPrice}
                                  {/* <small className="fs-14 ms-2 text-secondary">
                              /night
                            </small> */}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-sm-4 col-6">
                              <div className="d-flex align-items-center mt-sm-0 mt-3">
                                <DropdownBlog bookingId={booking.bookingId} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default CustomerManage;
