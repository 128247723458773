import React, { Fragment, useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Global } from "../../../../Global";
import { toast } from "react-toastify";

import PageTitle from "../../../layouts/PageTitle";

const AppProfile = () => {
  const { userToken, userId } = useSelector((state) => state.credential);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [isPublished, setIsPublished] = useState(true);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const EditEmployee = async (e) => {
    e.preventDefault();

    if (!disableSubmit) {
      if (firstName.trim() === "" || lastName.trim() === "" || email === "") {
        toast.error("All fields are required");
      } else if (!email.includes("@") || !email.includes(".")) {
        toast.error("Enter a valid email");
      } else {
        setDisableSubmit(true);
        try {
          const response = await fetch(
            Global.API_URL + "Employee/EditUser?userid=" + userId,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
              body: JSON.stringify({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email: email.trim().toLocaleLowerCase(),
                hotelRoleId: roleId,
                isPublished: isPublished,
              }),
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            setDisableSubmit(false);
            toast.success(result.message);
          } else {
            setDisableSubmit(false);
            toast.error(result.message);
          }
        } catch (error) {
          setDisableSubmit(false);
          console.log(error);
        }
      }
    }
  };
  let strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.?*])(?=.{8,})"
  );

  const ChangePassword = async (e) => {
    e.preventDefault();
    if (
      oldPassword.trim() === "" ||
      newPassword.trim() === "" ||
      confirmPassword.trim() === ""
    ) {
      toast.error("All fields are required");
    } else if (!strongPasswordRegex.test(newPassword)) {
      toast.error(
        "Password must be minimum 8 characters consisting of numbers, uppercase and lowercase letters and symbols"
      );
    } else if (newPassword.trim() !== confirmPassword.trim()) {
      toast.error("Password doesn't match");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "Employee/ChangeEmployeePassword?EmployeeId=" + userId + "&Password=" + newPassword ,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          setNewPassword("");
          setOldPassword("");
          setConfirmPassword("");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetEmployee = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Employee/GetEmployeesById?userid=" + userId,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setFirstName(result.message.firstName);
          setLastName(result.message.lastName);
          setEmail(result.message.email);
          setRoleId(result.message.roleId);
          setIsPublished(result.message.isPublished);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetEmployee();
  }, [userToken, userId]);
  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      {firstName + " " + lastName}
                    </h4>
                    {/* <p>UX / UI Designer</p> */}
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{email}</h4>
                    {/* <p>Email</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-xl-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-statistics">
                    <div className="text-center">
                      <div className="row">
                        <div className="col">
                          <h3 className="m-b-0">150</h3>
                          <span>Follower</span>
                        </div>
                        <div className="col">
                          <h3 className="m-b-0">140</h3> <span>Place Stay</span>
                        </div>
                        <div className="col">
                          <h3 className="m-b-0">45</h3> <span>Reviews</span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <Link
                          to="/post-details"
                          className="btn btn-primary mb-1 me-1"
                        >
                          Follow
                        </Link>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1"
                          onClick={() => dispatch({ type: "sendMessage" })}
                        >
                          Send Message
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h5 className="text-primary">Today Highlights</h5>
                </div>
                <div className="card-body pt-3">
                  <div className="profile-blog ">
                    <img
                      src={profile01}
                      alt="profile"
                      className="img-fluid  mb-4 w-100 "
                    />
                    <Link to="/post-details">
                      {" "}
                      <h4>Darwin Creative Agency Theme</h4>{" "}
                    </Link>
                    <p className="mb-0">
                      A small river named Duden flows by their place and
                      supplies it with the necessary regelialia. It is a
                      paradisematic country, in which roasted parts of sentences
                      fly into your mouth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h5 className="text-primary ">Interest</h5>
                </div>
                <div className="card-body pt-3">
                  <div className="profile-interest ">
                    <LightGallery
                      speed={500}
                      plugins={[lgThumbnail, lgZoom]}
                      elementClassNames="row sp4"
                    >
                      {galleryBlog.map((item, index) => (
                        <div
                          data-src={item.image}
                          className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1"
                          key={index}
                        >
                          <img
                            src={item.image}
                            style={{ width: "100%" }}
                            alt="gallery"
                          />
                        </div>
                      ))}
                    </LightGallery>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h5 className="text-primary">Our Latest News</h5>
                </div>
                <div className="card-body pt-3">
                  <div className="profile-news">
                    <div className="media pt-3 pb-3">
                      <img
                        src={profile05}
                        alt=""
                        className="me-3 rounded"
                        width={75}
                      />
                      <div className="media-body">
                        <h5 className="m-b-5">
                          <Link to="/post-details" className="text-black">
                            Collection of textile samples
                          </Link>
                        </h5>
                        <p className="mb-0">
                          I shared this on my fb wall a few months back, and I
                          thought.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="media pt-3 pb-3">
                      <img
                        src={profile06}
                        alt=""
                        className="me-3 rounded"
                        width={75}
                      />
                      <div className="media-body">
                        <h5 className="m-b-5">
                          <Link to="/post-details" className="text-black">
                            Collection of textile samples
                          </Link>
                        </h5>
                        <p className="mb-0">
                          I shared this on my fb wall a few months back, and I
                          thought.
                        </p>
                      </div>
                    </div>
                    <div className="media pt-3 ">
                      <img
                        src={profile07}
                        alt=""
                        className="me-3 rounded"
                        width={75}
                      />
                      <div className="media-body">
                        <h5 className="m-b-5">
                          <Link to="/post-details" className="text-black">
                            Collection of textile samples
                          </Link>
                        </h5>
                        <p className="mb-0">
                          I shared this on my fb wall a few months back, and I
                          thought.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey="account">
                    <Nav as="ul" className="nav nav-tabs">
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#profile-settings" eventKey="account">
                          Profile
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#profile-settings" eventKey="Setting">
                          Setting
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane id="profile-settings" eventKey="account">
                        <div className="pt-3">
                          <div className="settings-form">
                            <h4 className="text-primary">Account Detail</h4>
                            <form onSubmit={(e) => EditEmployee(e)}>
                              <div className="row">
                                <div className="form-group mb-4 col-md-6">
                                  <label className="form-label">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="First Name"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group mb-4 col-md-6">
                                  <label className="form-label">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="form-control"
                                    value={lastName}
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group mb-4 col-md-6">
                                  <label className="form-label">Email</label>
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <button className="btn btn-primary" type="submit">
                                Update
                              </button>
                            </form>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane id="profile-settings" eventKey="Setting">
                        <div className="pt-3">
                          <div className="settings-form">
                            <h4 className="text-primary">Change Password</h4>
                            <form onSubmit={(e) => ChangePassword(e)}>
                              <div className="row">
                                <div className="form-group mb-4 col-md-12">
                                  <label className="form-label">
                                    Old Password
                                  </label>
                                  <input
                                    type="password"
                                    placeholder="Old Password"
                                    className="form-control"
                                    value={oldPassword}
                                    onChange={(e) =>
                                      setOldPassword(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group mb-4 col-md-12">
                                  <label className="form-label">
                                    New Password
                                  </label>
                                  <input
                                    type="password"
                                    placeholder="New Password"
                                    className="form-control"
                                    value={newPassword}
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group mb-4 col-md-12">
                                  <label className="form-label">
                                    confirm Password
                                  </label>
                                  <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    className="form-control"
                                    value={confirmPassword}
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <button className="btn btn-primary" type="submit">
                                Change Password
                              </button>
                            </form>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
