import React, { Fragment, useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const DetailManage = () => {
  const { userToken, userId } = useSelector((state) => state.credential);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [hotelName, setHotelName] = useState("");
  const [mapLocation, setMapLocation] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cityId, setCityId] = useState(0);
  const [isPublished, setIsPublished] = useState(true);
  const [image, setImage] = useState("");

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const EditHotel = async (e) => {
    e.preventDefault();
    if (
      hotelName.trim() === "" ||
      mapLocation.trim() === "" ||
      countryCode === "" ||
      cityId === 0 ||
      image === ""
    ) {
      toast.error("All fields are required");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "Hotel/EditHotel?hotelid=1",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              hotelName: hotelName,
              countryCode: countryCode,
              cityID: cityId,
              mapsLocation: mapLocation,
              isPublished: isPublished,
              image: image,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
        
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDrop = (e) => {
    setDisableSubmit(true);
    let formdata = new FormData();
    formdata.append("Images", e.target.files[0]);
    formdata.append("Sender", "A");
    formdata.append("SenderID", userId);

    fetch(Global.API_URL+"Others/UploadSingleImage", {
      method: "POST",
      headers: {
        "bs-api-key": Global.bs_api_key,
        AuthorizationUser: userToken,
      },
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        setImage(result[0]);
        setDisableSubmit(false);
      })
      .catch((error) => console.log("error", error));
  };

  const GetCitiesByCountryCode = useCallback(
    async (code, city) => {
      if (code !== "") {
        try {
          const response = await fetch(
            Global.API_URL + "City/GetCitiesForCountry?countrycode=" + code,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            setCountryCode(code);
            setCityId(city);
            setCities(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCountryCode("");
        setCityId(0);
        setCities([]);
      }
    },
    [userToken]
  );

  useEffect(() => {
    const GetHotel = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Hotel/GetHotelById?hotelid=1",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setHotelName(result.message.hotel.hotelName);
          setCountryCode(result.message.hotel.countryCode);
          setMapLocation(result.message.hotel.mapsLocation);
          setCityId(result.message.hotel.cityId);
          setIsPublished(result.message.hotel.isPublished);
          setImage(result.message.hotel.imageUrl);

          GetCitiesByCountryCode(
            result.message.hotel.countryCode,
            result.message.hotel.cityId
          );
          setIsLoading(false);
      setDisableSubmit(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
      setDisableSubmit(false);
    };

    GetHotel();
  }, [ userToken, GetCitiesByCountryCode]);

  useEffect(() => {
    const GetCountries = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Country/GetListCountries",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setCountries(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetCountries();
  }, [userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={(e) => EditHotel(e)}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Hotel Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      value={hotelName}
                      onChange={(e) => setHotelName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Map Location<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="location"
                      value={mapLocation}
                      onChange={(e) => setMapLocation(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Country<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={countryCode}
                      onChange={(e) =>
                        GetCitiesByCountryCode(e.target.value, 0)
                      }
                    >
                      <option value="">Choose...</option>
                      {countries.map((country, index) => (
                        <option value={country.countryCode} key={index}>
                          {country.countryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      City<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={cityId}
                      onChange={(e) => setCityId(parseInt(e.target.value))}
                    >
                      <option value={0}>Choose...</option>
                      {cities.map((city, index) => (
                        <option value={city.cityId} key={index}>
                          {city.cityName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3 col-md-12">
                    <label>
                      Image<span className="text-danger">*</span>
                    </label>
                    <br />
                    {image !== "" && (
                      <img
                        style={{ height: "200px" }}
                        src={Global.PHOTO_URL + image}
                        alt="hotel"
                      />
                    )}
                    <br />
                    <br />
                    <input type="file" onChange={handleDrop} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailManage;
