import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const HotelSlideManage = () => {
  const { slide_id } = useParams();
  const navigate = useNavigate();
  const { userToken, userId } = useSelector((state) => state.credential);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [slideTitle, setSlideTitle] = useState("");
  const [slideUrl, setSlideUrl] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [slideImage, setSlideImage] = useState("");
  const [slideImageMobile, setSlideImageMobile] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isPublished, setIsPublished] = useState(true);
  const [image, setImage] = useState("");
  const [imageMoile, setImageMobile] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [isNewMobile, setIsNewMobile] = useState(false);

  const InsertSlide = async () => {
    if (slideTitle.trim() === "") {
      toast.error("Slide Title is required");
    } else if (slideUrl.trim() === "") {
      toast.error("Slide Url is required");
    } else if (slideImage === "") {
      toast.error("Slide Image is required");
    } else if (slideImageMobile === "") {
      toast.error("Slide Image Mobile is required");
    } else if (startDate === "") {
      toast.error("Start Date  is required");
    } else if (endDate === "") {
      toast.error("End Date  is required");
    } else if (startDate > endDate) {
      toast.error("Start Date must be less than end date");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("AuthorizationUser", userToken);
        myHeaders.append("bs-api-key", Global.bs_api_key);

        let formdata = new FormData();
        formdata.append("SlideTitle", slideTitle.trim());
        formdata.append("SlideUrl", slideUrl.trim());
        formdata.append("SlideImage", slideImage);
        formdata.append("SlideMobileImage",slideImageMobile);
        formdata.append("ButtonTitle", buttonTitle);
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);
        formdata.append("SlideStartDate", startDate);
        formdata.append("SlideEndDate", endDate);
        formdata.append("IsPublished", isPublished);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
        };

        let response = await fetch(
          Global.API_URL + "Slide/AddSlide",
          requestOptions
        );
        let result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            toast.success(result.message);
            navigate("/slide");
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const UpdateSlide = async () => {
    if (slideTitle.trim() === "") {
      toast.error("Slide Title is required");
    } else if (slideUrl.trim() === "") {
      toast.error("Slide Url is required");
    } else if (slideImage === "") {
      toast.error("Slide Image is required");
    } else if (slideImageMobile === "") {
      toast.error("Slide Image Mobile is required");
    } else if (startDate === "") {
      toast.error("Start Date  is required");
    } else if (endDate === "") {
      toast.error("End Date  is required");
    } else if (startDate > endDate) {
      toast.error("Start Date must be less than end date");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("AuthorizationUser", userToken);
        myHeaders.append("bs-api-key", Global.bs_api_key);
        let formdata = new FormData();
        formdata.append("SlideTitle", slideTitle.trim());
        formdata.append("SlideUrl", slideUrl.trim());
        formdata.append("ButtonTitle", buttonTitle);
        formdata.append("SlideImage", isNew ? slideImage : null);
        formdata.append("SlideImageUrl", isNew ? "" : slideImage);
        formdata.append(
          "SlideMobileImage",
          isNewMobile ? slideImageMobile : null
        );
        formdata.append(
          "SlideMobileImageUrl",
          isNewMobile ? "" : slideImageMobile
        );
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);
        formdata.append("SlideStartDate", startDate);
        formdata.append("SlideEndDate", endDate);
        formdata.append("IsPublished", isPublished);

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: formdata,
        };

        let response = await fetch(
          Global.API_URL + "Slide/EditSlide?slideid=" + slide_id,
          requestOptions
        );
        let result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            toast.success(result.message);
            navigate("/slide");
          } else {
            toast.error(result.message);
          }
        } else {
          console.log(result);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleDrop = (e) => {
    setDisableSubmit(true);
    const selectedFile = e.target.files[0];
    setSlideImage(selectedFile);
    setIsNew(true);
    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setImage(reader.result);
    };
    setDisableSubmit(false);
  };
  const handleMobileDrop = (e) => {
    setDisableSubmit(true);
    const selectedFile = e.target.files[0];
    setSlideImageMobile(selectedFile);
    setIsNewMobile(true);
    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setImageMobile(reader.result);
    };
    setDisableSubmit(false);
  };
  useEffect(() => {
    const GetSlides = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Slide/GetSlideById?slideid=" + slide_id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        console.log(result)
        if (result.code === 0) {
          setSlideTitle(result.message.slideTitle);
          setSlideUrl(result.message.slideUrl);
          setButtonTitle(result.message.buttonTitle);
          setSlideImage(result.message.slideImage);
          setStartDate(result.message.slideStartDate);
          setEndDate(result.message.slideEndDate);
          setIsPublished(result.message.isPublished);
          setSlideImageMobile(result.message.slideMobileImage)
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      setDisableSubmit(false);
    };
    if (parseInt(slide_id) !== 0) {
      GetSlides();
    } else {
      setIsLoading(false);
      setDisableSubmit(false);
    }
  }, [slide_id, userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={parseInt(slide_id) === 0 ? InsertSlide : UpdateSlide}
          >
            Submit
          </button>
          <Link to={"/slide"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Slide Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Slide Title"
                      value={slideTitle}
                      onChange={(e) => setSlideTitle(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Slide Url<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Slide Url"
                      value={slideUrl}
                      onChange={(e) => setSlideUrl(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Button Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Title"
                      value={buttonTitle}
                      onChange={(e) => setButtonTitle(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Start Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder="Button Title"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      End Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder="Button Title"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label>
                      Slide Image<span className="text-danger">*</span>
                    </label>
                    <br />
                    {isNew ? (
                      <img
                        style={{ height: "200px" }}
                        src={image}
                        alt="hotel"
                      />
                    ) : (
                      slideImage && (
                        <img
                          style={{ height: "200px" }}
                          src={Global.PHOTO_URL + slideImage}
                          alt="hotel"
                        />
                      )
                    )}
                    <br />
                    <br />
                    <input type="file" onChange={handleDrop} />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label>
                      Slide Mobile Image<span className="text-danger">*</span>
                    </label>
                    <br />
                    {isNewMobile ? (
                      <img
                        style={{ height: "200px" }}
                        src={imageMoile}
                        alt="hotel"
                      />
                    ) : (
                      slideImageMobile && (
                        <img
                          style={{ height: "200px" }}
                          src={Global.PHOTO_URL + slideImageMobile}
                          alt="hotel"
                        />
                      )
                    )}
                    <br />
                    <br />
                    <input type="file" onChange={handleMobileDrop} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HotelSlideManage;
