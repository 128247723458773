import React, { useCallback, useEffect, useState } from "react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddBooking = () => {
  const { userToken } = useSelector((state) => state.credential);
  const [isLoading, setIsLoading] = useState(true);

  const [roomId, setRoomId] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cityId, setCityId] = useState(0);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const [rooms, setRooms] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const navigate = useNavigate();

  const [isTravelingForWork, setIsTravelingForWork] = useState(false);
  const [isWithBreakfast, setIsWithBreakfast] = useState(false);

  const SubmitBooking = async () => {
    if (firstName.trim() === "") {
      toast.error("First Name is required");
    } else if (lastName.trim() === "") {
      toast.error("Last Name is required");
    } else if (email.trim() === "") {
      toast.error("Email is required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else if (phone.trim() === "") {
      toast.error("Phone is required");
    } else if (countryCode === "") {
      toast.error("Country is required");
    } else if (cityId === 0) {
      toast.error("City is required");
    } else if (roomId === 0) {
      toast.error("Room is required");
    } else if (checkIn === "") {
      toast.error("Check In Date is required");
    } else if (checkOut === "") {
      toast.error("Check Out Date is required");
    } else if (checkIn > checkOut) {
      toast.error("check In must be before checkout");
    } else {
      console.log(
        JSON.stringify({
          roomId: roomId,
          bookingAddress: {
            firstName: firstName,
            lastName: lastName,
            email: email.trim().toLocaleLowerCase(),
            phone: phone,
            countryCode: countryCode,
            cityId: cityId,
            customerId: 0,
            guestId: 0,
          },
          paymentMethodId: 2,
          checkinDate: checkIn,
          checkoutDate: checkOut,
          isIncluded: isWithBreakfast,
          isTravelingForWork: isTravelingForWork,
        })
      );
      try {
        const response = await fetch(
          Global.API_URL + "Booking/AddBookingAdmin",
          {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              roomId: roomId,
              bookingAddress: {
                firstName: firstName,
                lastName: lastName,
                email: email.trim().toLocaleLowerCase(),
                phone: phone,
                countryCode: countryCode,
                cityId: cityId,
                customerId: 0,
                guestId: 0,
              },
              paymentMethodId: 2,
              checkinDate: checkIn,
              checkoutDate: checkOut,
              isIncluded: isWithBreakfast,
              isTravelingForWork: isTravelingForWork,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/booking");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const GetCitiesByCountryCode = useCallback(
    async (code) => {
      if (code !== "") {
        try {
          const response = await fetch(
            Global.API_URL + "City/GetCitiesForCountry?countrycode=" + code,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            setCountryCode(code);
            setCityId(0);
            setCities(result.message);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setCountryCode("");
        setCityId(0);
        setCities([]);
      }
    },
    [userToken]
  );
  useEffect(() => {
    const GetRooms = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Room/GetRoomsForDropDown",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          setRooms(array);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const GetCountries = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Country/GetListCountries",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setCountries(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetCountries();
    GetRooms();
    setIsLoading(false);
  }, [userToken]);
  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            onClick={() => SubmitBooking()}
          >
            Submit
          </button>
          <Link to={"/booking"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isWithBreakfast}
                          onChange={(e) => setIsWithBreakfast(e.target.checked)}
                        />
                        Add breakfast
                      </label>
                    </div>
                  </div>

                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Phone<span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Country<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={countryCode}
                      onChange={(e) =>
                        GetCitiesByCountryCode(e.target.value, 0)
                      }
                    >
                      <option value="">Choose...</option>
                      {countries.map((c, index) => (
                        <option value={c.countryCode} key={index}>
                          {c.countryName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      City<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={cityId}
                      onChange={(e) => setCityId(parseInt(e.target.value))}
                    >
                      <option value={0}>Choose...</option>
                      {cities.map((c, index) => (
                        <option value={c.cityId} key={index}>
                          {c.cityName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Rooms<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={roomId}
                      onChange={(e) => setRoomId(parseInt(e.target.value))}
                    >
                      <option value={0}>Choose...</option>
                      {rooms.map((room, index) => (
                        <option value={room.roomId} key={index}>
                          {room.roomName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-4">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isTravelingForWork}
                          onChange={(e) =>
                            setIsTravelingForWork(e.target.checked)
                          }
                        />
                        Traveling for work
                      </label>
                    </div>
                  </div>

                  <div className="form-group mb-4 col-md-6">
                    <label>Check In</label>
                    <span className="text-danger">*</span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder="StartDate"
                      value={checkIn}
                      onChange={(e) => setCheckIn(e.target.value)}
                      min={new Date().toISOString().split('.')[0]}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>Check Out</label>
                    <span className="text-danger">*</span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      placeholder="EndDate"
                      value={checkOut}
                      onChange={(e) => setCheckOut(e.target.value)}
                      min={new Date().toISOString().split('.')[0]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddBooking;
