import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const ContactUs = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { userToken } = useSelector((state) => state.credential);

  const EditContactUs = async (e) => {
    e.preventDefault();
    if (title.trim() === "" || body.trim() === "") {
      toast.error("All fields are required");
    } else {
      setDisableSubmit(true);
      try {
        const response = await fetch(
          Global.API_URL + "ContactUs/EditContactUs?hotelid=1",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              title: title,
              body: body,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
        setDisableSubmit(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetBooking = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "ContactUs/GetContactUs?contactusid=1",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        console.log(result)
        if (result.code === 0) {
          setTitle(result.message.title);
          setBody(result.message.body);
          setDisableSubmit(false);
          setIsLoading(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetBooking();
  }, [userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={(e) => EditContactUs(e)}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Body<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="body"
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
