import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const NewsManage = () => {
  const { news_id } = useParams();
  const navigate = useNavigate();
  const { userToken, userId } = useSelector((state) => state.credential);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [newsTitle, setNewsTitle] = useState("");
  const [newsDescription, setNewsDescription] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [newsShortDescription, setNewsShortDescription] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [image, setImage] = useState("");

  const InsertNews = async () => {
    if (newsTitle.trim() === "") {
      toast.error("News Title is required");
    } else if (newsDescription.trim() === "") {
      toast.error("Description is required");
    } else if (newsShortDescription.trim() === "") {
      toast.error("Short Description is required");
    } else if (newsImage === "") {
      toast.error("Image is required");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("AuthorizationUser", userToken);
        myHeaders.append("bs-api-key", Global.bs_api_key);

        let formdata = new FormData();
        formdata.append("NewsTitle", newsTitle.trim());
        formdata.append("NewsImage", newsImage);
        formdata.append("NewsDescription", newsDescription.trim());
        formdata.append("NewsShortDescription", newsShortDescription.trim());
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
        };

        let response = await fetch(
          Global.API_URL + "News/AddNews",
          requestOptions
        );
        let result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            toast.success(result.message);
            navigate("/news");
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const EditNews = async () => {
    if (newsTitle.trim() === "") {
      toast.error("News Title is required");
    } else if (newsDescription.trim() === "") {
      toast.error("Description is required");
    } else if (newsShortDescription.trim() === "") {
      toast.error("Short Description is required");
    } else if (newsImage === "") {
      toast.error("Image is required");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("AuthorizationUser", userToken);
        myHeaders.append("bs-api-key", Global.bs_api_key);

        let formdata = new FormData();
        formdata.append("NewsTitle", newsTitle.trim());
        formdata.append("NewsImage", isNew ? newsImage : null);
        formdata.append("NewsImageUrl", isNew ? "" : newsImage);
        formdata.append("NewsDescription", newsDescription.trim());
        formdata.append("NewsShortDescription", newsShortDescription.trim());
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);

        var requestOptions = {
          method: "Put",
          headers: myHeaders,
          body: formdata,
        };

        let response = await fetch(
          Global.API_URL + "News/EditNews?newsid=" + news_id,
          requestOptions
        );
        let result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            toast.success(result.message);
            navigate("/news");
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleDrop = (e) => {
    setDisableSubmit(true);
    const selectedFile = e.target.files[0];
    setNewsImage(selectedFile);
    setIsNew(true);
    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setImage(reader.result);
    };
    setDisableSubmit(false);
  };

  useEffect(() => {
    const GetNews = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "News/GetNewsById?newsid=" + news_id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setNewsTitle(result.message.newsTitle);
          setNewsDescription(result.message.newsDescription);
          setNewsShortDescription(result.message.newsShortDescription);
          setNewsImage(result.message.newsImage);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
      setDisableSubmit(false);
    };
    if (parseInt(news_id) !== 0) {
      GetNews();
    } else {
      setDisableSubmit(false);
      setIsLoading(false);
    }
  }, [userToken,news_id]);
  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={parseInt(news_id) === 0 ? InsertNews : EditNews}
          >
            Submit
          </button>
          <Link
            to={"/news"}
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      News Title<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={newsTitle}
                      onChange={(e) => setNewsTitle(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      News Description<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      value={newsDescription}
                      onChange={(e) => setNewsDescription(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      News Short Description
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Short Description"
                      value={newsShortDescription}
                      onChange={(e) => setNewsShortDescription(e.target.value)}
                    />
                  </div>

                  <div className="mb-3 col-md-12">
                    <label>
                      News Image<span className="text-danger">*</span>
                    </label>
                    <br />
                    {isNew ? (
                      <img
                        style={{ height: "200px" }}
                        src={image}
                        alt="hotel"
                      />
                    ) : (
                      newsImage && (
                        <img
                          style={{ height: "200px" }}
                          src={Global.PHOTO_URL + newsImage}
                          alt="hotel"
                        />
                      )
                    )}
                    <br />
                    <br />
                    <input type="file" onChange={handleDrop} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewsManage;
