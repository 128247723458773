import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";

const RoleManage = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [hotelRoleName, setRoleName] = useState("");
  const [deleteAllowedItems, setDeleteAllowedItems] = useState(true);
  const [deleteRoles, setDeleteHotels] = useState(true);
  const [deleteMedia, setDeleteMedia] = useState(true);
  const [deleteOffers, setDeleteOffers] = useState(true);
  const [deleteRegulations, setDeleteRegulations] = useState(true);
  const [deleteRooms, setDeleteRooms] = useState(true);
  const [deleteServices, setDeleteServices] = useState(true);
  const [deleteUsers, setDeleteUsers] = useState(true);
  const [isPublished, setIsPublished] = useState(true);
  const [listAllowedItems, setListAllowedItems] = useState(true);
  const [listCustomer, setListCustomer] = useState(true);
  const [listRoles, setListRoles] = useState(true);
  const [listMedia, setListMedia] = useState(true);
  const [listOffers, setListOffers] = useState(true);
  const [listRegulations, setListRegulations] = useState(true);
  const [listRooms, setListRooms] = useState(true);
  const [listServices, setListServices] = useState(true);
  const [listUsers, setListUsers] = useState(true);
  const [manageAllowedItems, setManageAllowedItems] = useState(true);
  const [manageCustomer, setManageCustomer] = useState(true);
  const [manageRoles, setManageRoles] = useState(true);
  const [manageMedia, setManageMedia] = useState(true);
  const [manageNotifications, setManageNotifications] = useState(true);
  const [manageOffers, setManageOffers] = useState(true);
  const [manageRegulations, setManageRegulations] = useState(true);
  const [manageRooms, setManageRooms] = useState(true);
  const [manageServices, setManageServices] = useState(true);
  const [manageUsers, setManageUsers] = useState(true);

  const {  role_id } = useParams();
  const navigate = useNavigate();

  const AddRole = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);

    if (hotelRoleName.trim() === "") {
      toast.error("Role name field is required");
    } else {
      try {
        const response = await fetch(Global.API_URL + "HotelRole", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
          body: JSON.stringify({
            hotelRoleName: hotelRoleName,
            isPublished: isPublished,
            manageNotifications: manageNotifications,
            manageMedia: manageMedia,
            listMedia: listMedia,
            deleteMedia: deleteMedia,
            manageCustomer: manageCustomer,
            listCustomer: listCustomer,
            listRoles: listRoles,
            manageRoles: manageRoles,
            deleteRoles: deleteRoles,
            manageRooms: manageRooms,
            deleteRooms: deleteRooms,
            listRooms: listRooms,
            manageOffers: manageOffers,
            listOffers: listOffers,
            deleteOffers: deleteOffers,
            manageRegulations: manageRegulations,
            deleteRegulations: deleteRegulations,
            listRegulations: listRegulations,
            manageUsers: manageUsers,
            listUsers: listUsers,
            deleteUsers: deleteUsers,
            manageServices: manageServices,
            listServices: listServices,
            deleteServices: deleteServices,
            manageAllowedItems: manageAllowedItems,
            listAllowedItems: listAllowedItems,
            deleteAllowedItems: deleteAllowedItems,
          }),
        });
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/role");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const EditRole = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    try {
      const response = await fetch(
        Global.API_URL + "HotelRole/EditRole?roleid=" + role_id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
          body: JSON.stringify({
            hotelRoleName: hotelRoleName,
            isPublished: isPublished,
            manageNotifications: manageNotifications,
            manageMedia: manageMedia,
            listMedia: listMedia,
            deleteMedia: deleteMedia,
            manageCustomer: manageCustomer,
            listCustomer: listCustomer,
            listRoles: listRoles,
            manageRoles: manageRoles,
            deleteRoles: deleteRoles,
            manageRooms: manageRooms,
            deleteRooms: deleteRooms,
            listRooms: listRooms,
            manageOffers: manageOffers,
            listOffers: listOffers,
            deleteOffers: deleteOffers,
            manageRegulations: manageRegulations,
            deleteRegulations: deleteRegulations,
            listRegulations: listRegulations,
            manageUsers: manageUsers,
            listUsers: listUsers,
            deleteUsers: deleteUsers,
            manageServices: manageServices,
            listServices: listServices,
            deleteServices: deleteServices,
            manageAllowedItems: manageAllowedItems,
            listAllowedItems: listAllowedItems,
            deleteAllowedItems: deleteAllowedItems,
          }),
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        toast.success(result.message);
        navigate("/role");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const GetRole = async () => {
      if (parseInt(role_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "HotelRole/GetRoleById?roleId=" + role_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
              },
            }
          );

          const result = await response.json();
          if (result.code === 0) {
            setRoleName(result.message.hotelRoleName);
            setDeleteAllowedItems(result.message.deleteAllowedItems);
            setDeleteHotels(result.message.deleteRoles);
            setDeleteMedia(result.message.deleteMedia);
            setDeleteOffers(result.message.deleteOffers);
            setDeleteRegulations(result.message.deleteRegulations);
            setDeleteRooms(result.message.deleteRooms);
            setDeleteServices(result.message.deleteServices);
            setDeleteUsers(result.message.deleteUsers);
            setIsPublished(result.message.isPublished);
            setListAllowedItems(result.message.listAllowedItems);
            setListCustomer(result.message.listCustomer);
            setListRoles(result.message.listRoles);
            setListMedia(result.message.listMedia);
            setListOffers(result.message.listOffers);
            setListRegulations(result.message.listRegulations);
            setListRooms(result.message.listRooms);
            setListServices(result.message.listServices);
            setListUsers(result.message.listUsers);
            setManageAllowedItems(result.message.manageAllowedItems);
            setManageCustomer(result.message.manageCustomer);
            setManageRoles(result.message.manageRoles);
            setManageMedia(result.message.manageMedia);
            setManageNotifications(result.message.manageNotifications);
            setManageOffers(result.message.manageOffers);
            setManageRegulations(result.message.manageRegulations);
            setManageRooms(result.message.manageRooms);
            setManageServices(result.message.manageServices);
            setManageUsers(result.message.manageUsers);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    GetRole();
  }, [ role_id, navigate]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            onClick={(e) =>
              parseInt(role_id) === 0 ? AddRole(e) : EditRole(e)
            }
            disabled={disableSubmit}
          >
            Submit
          </button>
          <Link
            to={"/role"}
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <label>
                    Role Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Role Name"
                    value={hotelRoleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <div className="form-check form-check-inline mt-6">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={isPublished}
                        checked={isPublished}
                        onChange={(e) =>
                          setIsPublished(
                            e.target.value === "true" ? false : true
                          )
                        }
                      />
                      Published
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <h4>User</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listUsers}
                      checked={listUsers}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListUsers(false),
                            setManageUsers(false),
                            setDeleteUsers(false))
                          : setListUsers(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      User List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageUsers}
                      checked={manageUsers}
                      onChange={(e) =>
                        listUsers
                          ? e.target.value === "true"
                            ? (setManageUsers(false), setDeleteUsers(false))
                            : setManageUsers(true)
                          : e.target.value === "true"
                          ? (setManageUsers(false), setDeleteUsers(false))
                          : (setManageUsers(true), setListUsers(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage User
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteUsers}
                      checked={deleteUsers}
                      onChange={(e) =>
                        manageUsers
                          ? setDeleteUsers(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteUsers(false)
                          : (setDeleteUsers(true),
                            setManageUsers(true),
                            setListUsers(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Publishment User
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <h4>Roles</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listRoles}
                      checked={listRoles}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListRoles(false),
                            setManageRoles(false),
                            setDeleteHotels(false))
                          : setListRoles(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Roles List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageRoles}
                      checked={manageRoles}
                      onChange={(e) =>
                        listRoles
                          ? e.target.value === "true"
                            ? (setManageRoles(false), setDeleteHotels(false))
                            : setManageRoles(true)
                          : e.target.value === "true"
                          ? (setManageRoles(false), setDeleteHotels(false))
                          : (setManageRoles(true), setListRoles(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Roles
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteRoles}
                      checked={deleteRoles}
                      onChange={(e) =>
                        manageRoles
                          ? setDeleteHotels(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteHotels(false)
                          : (setDeleteHotels(true),
                            setManageRoles(true),
                            setListRoles(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Publishment Roles
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <h4>Rooms</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listRooms}
                      checked={listRooms}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListRooms(false),
                            setManageRooms(false),
                            setDeleteRooms(false))
                          : setListRooms(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Room List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageRooms}
                      checked={manageRooms}
                      onChange={(e) =>
                        listRooms
                          ? e.target.value === "true"
                            ? (setManageRooms(false), setDeleteRooms(false))
                            : setManageRooms(true)
                          : e.target.value === "true"
                          ? (setManageRooms(false), setDeleteRooms(false))
                          : (setManageRooms(true), setListRooms(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Rooms
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteRooms}
                      checked={deleteRooms}
                      onChange={(e) =>
                        manageRooms
                          ? setDeleteRooms(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteRooms(false)
                          : (setDeleteRooms(true),
                            setManageRooms(true),
                            setListRooms(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Publishment Rooms
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <h4>Customer</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listCustomer}
                      checked={listCustomer}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListCustomer(false), setManageCustomer(false))
                          : setListCustomer(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Customer List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageCustomer}
                      checked={manageCustomer}
                      onChange={(e) =>
                        listCustomer
                          ? e.target.value === "true"
                            ? setManageCustomer(false)
                            : setManageCustomer(true)
                          : e.target.value === "true"
                          ? setManageCustomer(false)
                          : (setManageCustomer(true), setListCustomer(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Customer
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <h4>Allowed Items</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listAllowedItems}
                      checked={listAllowedItems}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListAllowedItems(false),
                            setManageAllowedItems(false),
                            setDeleteAllowedItems(false))
                          : setListAllowedItems(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Allowed Items List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageAllowedItems}
                      checked={manageAllowedItems}
                      onChange={(e) =>
                        listAllowedItems
                          ? e.target.value === "true"
                            ? (setManageAllowedItems(false),
                              setDeleteAllowedItems(false))
                            : setManageAllowedItems(true)
                          : e.target.value === "true"
                          ? (setManageAllowedItems(false),
                            setDeleteAllowedItems(false))
                          : (setManageAllowedItems(true),
                            setListAllowedItems(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Allowed Items
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteAllowedItems}
                      checked={deleteAllowedItems}
                      onChange={(e) =>
                        manageAllowedItems
                          ? setDeleteAllowedItems(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteAllowedItems(false)
                          : (setDeleteAllowedItems(true),
                            setManageAllowedItems(true),
                            setListAllowedItems(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Publishment Allowed Items
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <h4>Media</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listMedia}
                      checked={listMedia}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListMedia(false),
                            setManageMedia(false),
                            setDeleteMedia(false))
                          : setListMedia(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Media List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageMedia}
                      checked={manageMedia}
                      onChange={(e) =>
                        listMedia
                          ? e.target.value === "true"
                            ? (setManageMedia(false), setDeleteMedia(false))
                            : setManageMedia(true)
                          : e.target.value === "true"
                          ? (setManageMedia(false), setDeleteMedia(false))
                          : (setManageMedia(true), setListMedia(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Media
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteMedia}
                      checked={deleteMedia}
                      onChange={(e) =>
                        manageMedia
                          ? setDeleteMedia(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteMedia(false)
                          : (setDeleteMedia(true),
                            setManageMedia(true),
                            setListMedia(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Delete Media
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <h4>Services</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listServices}
                      checked={listServices}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListServices(false),
                            setManageServices(false),
                            setDeleteServices(false))
                          : setListServices(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Services List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageServices}
                      checked={manageServices}
                      onChange={(e) =>
                        listServices
                          ? e.target.value === "true"
                            ? (setManageServices(false),
                              setDeleteServices(false))
                            : setManageServices(true)
                          : e.target.value === "true"
                          ? (setManageServices(false), setDeleteServices(false))
                          : (setManageServices(true), setListServices(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Services
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteServices}
                      checked={deleteServices}
                      onChange={(e) =>
                        manageServices
                          ? setDeleteServices(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteServices(false)
                          : (setDeleteServices(true),
                            setManageServices(true),
                            setListServices(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Publishment Services
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <h4>Offers</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listOffers}
                      checked={listOffers}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListOffers(false),
                            setManageOffers(false),
                            setDeleteOffers(false))
                          : setListOffers(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Offers List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageOffers}
                      checked={manageOffers}
                      onChange={(e) =>
                        listOffers
                          ? e.target.value === "true"
                            ? (setManageOffers(false), setDeleteOffers(false))
                            : setManageOffers(true)
                          : e.target.value === "true"
                          ? (setManageOffers(false), setDeleteOffers(false))
                          : (setManageOffers(true), setListOffers(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Offers
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteOffers}
                      checked={deleteOffers}
                      onChange={(e) =>
                        manageOffers
                          ? setDeleteOffers(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteOffers(false)
                          : (setDeleteOffers(true),
                            setManageOffers(true),
                            setListOffers(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Publishment Offers
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <h4>Regulations</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={listRegulations}
                      checked={listRegulations}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? (setListRegulations(false),
                            setManageRegulations(false),
                            setDeleteRegulations(false))
                          : setListRegulations(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Regulations List
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox2"
                      value={manageRegulations}
                      checked={manageRegulations}
                      onChange={(e) =>
                        listRegulations
                          ? e.target.value === "true"
                            ? (setManageRegulations(false),
                              setDeleteRegulations(false))
                            : setManageRegulations(true)
                          : e.target.value === "true"
                          ? (setManageRegulations(false),
                            setDeleteRegulations(false))
                          : (setManageRegulations(true),
                            setListRegulations(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox2"
                    >
                      Manage Regulations
                    </label>
                  </div>
                </div>
                <div className="col-xl-4  col-6">
                  <div className="form-check custom-checkbox mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox1"
                      value={deleteRegulations}
                      checked={deleteRegulations}
                      onChange={(e) =>
                        manageRegulations
                          ? setDeleteRegulations(
                              e.target.value === "true" ? false : true
                            )
                          : e.target.value === "true"
                          ? setDeleteRegulations(false)
                          : (setDeleteRegulations(true),
                            setManageRegulations(true),
                            setListRegulations(true))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox1"
                    >
                      Publishment Regulations
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <h4>Notifications</h4>
                <div className="col-xl-4 col-6">
                  <div className="form-check custom-checkbox mb-3 checkbox-success">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckBox3"
                      value={manageNotifications}
                      checked={manageNotifications}
                      onChange={(e) =>
                        e.target.value === "true"
                          ? setManageNotifications(false)
                          : setManageNotifications(true)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckBox3"
                    >
                      Manage Notifications
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RoleManage;
