import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";
import uploadFile from "../../../images/uploadFile.jpg";
import axios from "axios";
import { useSelector } from "react-redux";

const ServiceManage = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceImage, setServiceImage] = useState("");
  const [serviceIcon, setServiceIcon] = useState("");
  const [isPublished, setIsPublished] = useState(true);
  const { userToken, userId } = useSelector((state) => state.credential);

  const { id, serviceId } = useParams();
  const navigate = useNavigate();

  const validForm =
    serviceName.trim() !== "" && serviceIcon !== "" && serviceImage !== "";
  const Submit = async (e) => {
    e.preventDefault();
    if (!validForm) {
      toast.error("Fill all the  fields");
    } else {
      if (parseInt(serviceId) === 0) {
        try {
          const response = await fetch(
            Global.API_URL + "RoomService/AddRoomService",
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
              body: JSON.stringify({
                roomServiceName: serviceName,
                roomServiceIcon: serviceIcon,
                isPublished: isPublished,
                roomServiceImage: serviceImage,
              }),
            }
          );
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              toast.success(result.message);
              navigate("/room-service");
            } else {
              toast.error(result.message);
            }
          } else {
            const result = await response.json();
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      } else {
        try {
          const response = await fetch(
            Global.API_URL + "RoomService?serviceid=" + serviceId,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
              body: JSON.stringify({
                roomServiceName: serviceName,
                roomServiceIcon: serviceIcon,
                isPublished: isPublished,
                roomServiceImage: serviceImage,
              }),
            }
          );
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              toast.success(result.message);
              navigate("/room-service");
            } else {
              toast.error(result.message);
            }
          } else {
            const result = await response.json();
            toast.error(result.message);
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
        }
      }
    }
    setDisableSubmit(false);
  };
  const handleIcon = async (e) => {
    const formData = new FormData();
    formData.append("Images", e.target.files[0]);
    formData.append("Sender", "A");
    formData.append("SenderID", userId);

    try {
      const response = await axios.post(
        Global.API_URL + "Others/UploadSingleImage",
        formData,
        {
          headers: {
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      setServiceIcon(response.data[0]);
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleImage = async (e) => {
    const formData = new FormData();
    formData.append("Images", e.target.files[0]);
    formData.append("Sender", "A");
    formData.append("SenderID", userId);

    try {
      const response = await axios.post(
        Global.API_URL + "Others/UploadSingleImage",
        formData,
        {
          headers: {
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
        }
      );
      setServiceImage(response.data[0]);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    if (parseInt(serviceId) !== 0) {
      const GetService = async () => {
        try {
          const response = await fetch(
            Global.API_URL +
              "RoomService/GetRoomServiceById?roomserviceId=" +
              serviceId,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
              },
            }
          );
          if (response.ok) {
            const result = await response.json();
            if (result.code === 0) {
              setServiceName(result.message.roomServiceName);
              setServiceIcon(result.message.roomServiceIcon);
              setServiceImage(result.message.roomServiceImage);
              setIsPublished(result.message.isPublished);
            } else {
              toast.error(result.message);
            }
          } else {
            const result = await response.json();
            toast.error(result.message);
            navigate("/room-service");
          }
        } catch (error) {
          toast.error(error.Message || "Something went wrong");
          navigate("/room-service");
        }
      };

      GetService();
    }
  }, [serviceId, navigate, id]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            onClick={(e) => Submit(e)}
            disabled={disableSubmit}
          >
            Submit
          </button>
          <Link to={"/room-service"} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <label>
                    Service Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Service Name"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                  />
                </div>
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <div className="form-check form-check-inline mt-6">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={isPublished}
                        checked={isPublished}
                        onChange={(e) =>
                          setIsPublished(
                            e.target.value === "true" ? false : true
                          )
                        }
                      />
                      Published
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <label>
                    Service Image <span className="text-danger">*</span>
                  </label>
                  <br />
                  <label htmlFor="serviceImage">
                    <img
                      src={
                        serviceImage && serviceImage !== ""
                          ? Global.PHOTO_URL + serviceImage
                          : uploadFile
                      }
                      width="250"
                      alt="serviceImage"
                    />
                  </label>
                  <input
                    type="file"
                    name="serviceImage"
                    id="serviceImage"
                    onChange={handleImage}
                    style={{ visibility: "hidden" }}
                  ></input>
                </div>
                <div className="form-group mb-5 col-md-6 col-lg-6 ">
                  <label>
                    Service Icon <span className="text-danger">*</span>
                  </label>
                  <br />
                  <label htmlFor="serviceIcon">
                    <img
                      src={
                        serviceIcon && serviceIcon !== ""
                          ? Global.PHOTO_URL + serviceIcon
                          : uploadFile
                      }
                      width="250"
                      alt="serviceIcon"
                    />
                  </label>
                  <input
                    type="file"
                    name="serviceIcon"
                    id="serviceIcon"
                    onChange={handleIcon}
                    style={{ visibility: "hidden" }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceManage;
