import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";

/// Layout
import Nav from "./layouts/nav";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DetailManage from "./components/Dashboard/Hotel/HotelDetails/DetailManage";
import HotelRoleList from "./components/Dashboard/Hotel/HotelDetails/RoleList";
import HotelRoleManage from "./components/Dashboard/Hotel/HotelDetails/RoleManage";
import WidgetList from "./components/Dashboard/Hotel/HotelWidgets/WidgetList";
import WidgetManage from "./components/Dashboard/Hotel/HotelWidgets/WidgetManage";
import BannerList from "./components/Dashboard/Hotel/HotelBanner/BannerList";
import BannerManage from "./components/Dashboard/Hotel/HotelBanner/BannerManage";

import ContactUs from "./components/Dashboard/Hotel/HotelNews/ContactUs";

import NewsList from "./components/Dashboard/Hotel/HotelNews/NewsList";
import NewsManage from "./components/Dashboard/Hotel/HotelNews/NewsManage";

import MenuList from "./components/Dashboard/Hotel/HotelMenu/MenuList";
import MenuManage from "./components/Dashboard/Hotel/HotelMenu/MenuManage";

import RoomTypeList from "./components/Dashboard/RoomTypeList";
import RoomTypeManage from "./components/Dashboard/RoomTypeManage";
import ServiceList from "./components/Dashboard/ServiceList";
import ServiceManage from "./components/Dashboard/ServiceManage";

import AllowedItemList from "./components/Dashboard/AllowedItemList";
import ManageAllowedItem from "./components/Dashboard/AllowedItemManage";

import RestrictedItemList from "./components/Dashboard/RestrictedItemList";
import RestrictedItemManage from "./components/Dashboard/RestrictedItemManage";

import BedTypeList from "./components/Dashboard/BedTypeList";
import BedTypeManage from "./components/Dashboard/BedTypeManage";


import BookingList from "./components/Dashboard/Hotel/HotelDetails/BookingList";
import AllBookingList from "./components/Dashboard/Hotel/HotelDetails/AllBookingList";
import BookingManage from "./components/Dashboard/Hotel/HotelDetails/BookingManage";
import CustomerList from "./components/Dashboard/Hotel/HotelDetails/CustomerList";
import CustomerManage from "./components/Dashboard/Hotel/HotelDetails/CustomerManage";
import CustomerAdd from "./components/Dashboard/Hotel/HotelDetails/CustomerAdd";
import MediaList from "./components/Dashboard/Hotel/HotelDetails/MediaList";
import MediaAdd from "./components/Dashboard/Hotel/HotelDetails/MediaAdd";
import OfferList from "./components/Dashboard/Hotel/HotelDetails/OfferList";
import OfferManage from "./components/Dashboard/Hotel/HotelDetails/OfferManage";
import RoomList from "./components/Dashboard/Hotel/HotelDetails/RoomList";
import HotelUserList from "./components/Dashboard/Hotel/HotelDetails/UserList";
import HotelUserManage from "./components/Dashboard/Hotel/HotelDetails/UserManage";
import RoomManage from "./components/Dashboard/Hotel/HotelDetails/RoomManage";
import ContentList from "./components/Dashboard/Hotel/HotelDetails/ContentList";
import ContentManage from "./components/Dashboard/Hotel/HotelDetails/ContentManage";
import HotelRatingList from "./components/Dashboard/Hotel/HotelDetails/HotelRatingList";
import HotelRatingManage from "./components/Dashboard/Hotel/HotelDetails/HotelRatingManage";

import Reviews from "./components/Dashboard/Reviews";
import PageList from "./components/Dashboard/Hotel/HotelPages/PageList";
import PageManage from "./components/Dashboard/Hotel/HotelPages/PageManage";

import HotelSlideList from "./components/Dashboard/Hotel/HotelSlide/HotelSlideList";
import HotelSlideManage from "./components/Dashboard/Hotel/HotelSlide/HotelSlideManage";





/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Contact from "./components/AppsMenu/Email/Inbox/Contact";
import Calendar from "./components/AppsMenu/Calendar/Calendar";

//Redux
import Todo from "./pages/Todo";

import Error400 from "./pages/Error400";

// import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import SubscribersList from "./components/Dashboard/Hotel/HotelDetails/SubscribersList";
import AddBooking from "./components/Dashboard/Hotel/HotelDetails/AddBooking";

const Markup = () => {
  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },
    { url: "role", component: <HotelRoleList /> },
    { url: "role/:role_id", component: <HotelRoleManage /> },
    { url: "room-type", component: <RoomTypeList /> },
    { url: "room-type/:id", component: <RoomTypeManage /> },
    { url: "room-service", component: <ServiceList /> },
    { url: "room-service/:serviceId", component: <ServiceManage /> },
    { url: "room-allowed-item", component: <AllowedItemList /> },
    {
      url: "room-allowed-item/:allowedItemId",
      component: <ManageAllowedItem />,
    },
    { url: "room-restricted-item", component: <RestrictedItemList /> },
    {
      url: "room-restricted-item/:restrictedItemId",
      component: <RestrictedItemManage />,
    },
    { url: "room-bed-type", component: <BedTypeList /> },
    { url: "room-bed-type/:bedtype_id", component: <BedTypeManage /> },
    { url: "hotel", component: <DetailManage /> },
    { url: "room", component: <RoomList /> },
    { url: "room/:room_id", component: <RoomManage /> },
    { url: "booking", component: <BookingList /> },
    {
      url: "bookings",
      component: <AllBookingList />,
    },
    {
      url: "booking/:booking_id",
      component: <BookingManage />,
    },
    {
      url: "booking/new-booking",
      component: <AddBooking />,
    },
    {
      url: "customer-booking/:booking_id",
      component: <BookingManage />,
    },
    {
      url: "booking/:booking_id/rating/rating/rating_id",
      component: <BookingManage />,
    },
    { url: "customer", component: <CustomerList /> },
    {
      url: "customer/:customer_id",
      component: <CustomerManage />,
    },
    { url: "customer/add", component: <CustomerAdd /> },

    { url: "subscribers", component: <SubscribersList /> },

    { url: "employee", component: <HotelUserList /> },
    {
      url: "employee/:employee_id",
      component: <HotelUserManage />,
    },
    { url: "content", component: <ContentList /> },
    {
      url: "content/:content_id",
      component: <ContentManage />,
    },
    { url: "rating", component: <HotelRatingList /> },
    {
      url: "rating/:rating_id",
      component: <HotelRatingManage />,
    },
    { url: "offer", component: <OfferList /> },
    { url: "offer/:offer_id", component: <OfferManage /> },
    { url: "media", component: <MediaList /> },
    { url: "media/add", component: <MediaAdd /> },
    { url: "widget", component: <WidgetList /> },
    { url: "widget/:widget_id", component: <WidgetManage /> },
    { url: "banner", component: <BannerList /> },
    { url: "banner/:banner_id", component: <BannerManage /> },
    { url: "contact-us", component: <ContactUs /> },
    { url: "news", component: <NewsList /> },
    { url: "news/:news_id", component: <NewsManage /> },

    { url: "menu", component: <MenuList /> },
    { url: "menu/:menu_id", component: <MenuManage /> },
    { url: "page", component: <PageList /> },
    { url: "page/:page_id", component: <PageManage /> },
    { url: "slide", component: <HotelSlideList /> },
    { url: "slide/:slide_id", component: <HotelSlideManage /> },
    { url: "reviews", component: <Reviews /> },


    /// Apps
    { url: "app-profile", component: <AppProfile /> },
    { url: "notification-inbox", component: <Inbox /> },
    { url: "contact", component: <Contact /> },
    { url: "app-calender", component: <Calendar /> },

    ///Redux
    { url: "todo", component: <Todo /> },
  ];

  // let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];

  // let pagePath = path.split("-").includes("page");
  return (
    <>
      <Routes>
        <Route path="page-error-400" element={<Error400 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default Markup;
