import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const BannerManage = () => {
  const {  banner_id } = useParams();
  const navigate = useNavigate();
  const { userToken, userId } = useSelector((state) => state.credential);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [bannerName, setBannerName] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [bannerStartDate, setBannerStartDate] = useState("");
  const [bannerEndDate, setBannerEndDate] = useState("");
  const [image, setImage] = useState("");
  const [isNew, setIsNew] = useState(false);

  const InsertBanner = async () => {
    if (bannerName.trim() === "") {
      toast.error("Banner Name is required");
    } else if (bannerUrl.trim() === "") {
      toast.error("Banner Url is required");
    } else if (bannerImage === "") {
      toast.error("Banner Image is required");
    } else if (bannerStartDate === "") {
      toast.error("Start Date is required");
    } else if (bannerEndDate === "") {
      toast.error("End Date is required");
    } else if (bannerEndDate < bannerStartDate) {
      toast.error("Start Date must be greater than End Date");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("AuthorizationUser", userToken);
        myHeaders.append("bs-api-key", Global.bs_api_key);

        let formdata = new FormData();
        formdata.append("BannerName", bannerName.trim());
        formdata.append("BannerUrl", bannerUrl.trim());
        formdata.append("BannerImage", bannerImage);
        formdata.append("StartDate", bannerStartDate);
        formdata.append("EndDate", bannerEndDate);
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
        };

        let response = await fetch(
          Global.API_URL + "Banner/AddBanner",
          requestOptions
        );
        let result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            toast.success(result.message);
            navigate("/banner");
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const UpdateBanner = async () => {
    if (bannerName.trim() === "") {
      toast.error("Banner Name is required");
    } else if (bannerUrl.trim() === "") {
      toast.error("Banner Url is required");
    } else if (bannerImage === "") {
      toast.error("Banner Image is required");
    } else if (bannerStartDate === "") {
      toast.error("Start Date is required");
    } else if (bannerEndDate === "") {
      toast.error("End Date is required");
    } else if (bannerEndDate < bannerStartDate) {
      toast.error("Start Date must be greater than End Date");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("AuthorizationUser", userToken);
        myHeaders.append("bs-api-key", Global.bs_api_key);
        let formdata = new FormData();
        formdata.append("BannerName", bannerName.trim());
        formdata.append("BannerUrl", bannerUrl.trim());
        formdata.append("BannerImage", isNew ? bannerImage : null);
        formdata.append("BannerImageUrl", isNew ? "" : bannerImage);
        formdata.append("StartDate", bannerStartDate);
        formdata.append("EndDate", bannerEndDate);
        formdata.append("Sender", "A");
        formdata.append("SenderId", userId);

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: formdata,
        };

        let response = await fetch(
          Global.API_URL + "Banner/EditBanner?bannerid=" + banner_id,
          requestOptions
        );
        let result = await response.json();
        if (response.ok) {
          if (result.code === 0) {
            toast.success(result.message);
            navigate("/banner");
          } else {
            toast.error(result.message);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleDrop = (e) => {
    setDisableSubmit(true);
    const selectedFile = e.target.files[0];
    setBannerImage(selectedFile);
    setIsNew(true);
    // Read the file and convert it to a data URL
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setImage(reader.result);
    };
    setDisableSubmit(false);
  };
  useEffect(() => {
    const GetBanners = async () => {
      try {
        const response = await fetch(
          Global.API_URL + "Banner/GetBannerById?bannerid=" + banner_id,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          setBannerName(result.message.bannerName);
          setBannerUrl(result.message.bannerUrl);
          setBannerImage(result.message.bannerImage);
          setBannerStartDate(result.message.startDate);
          setBannerEndDate(result.message.endDate);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      setDisableSubmit(false);
    };
    if (parseInt(banner_id) !== 0) {
      GetBanners();
    }else{
        setIsLoading(false);
        setDisableSubmit(false);
    }
 
  }, [banner_id, userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={parseInt(banner_id) === 0 ? InsertBanner : UpdateBanner}
          >
            Submit
          </button>
          <Link
            to={"/banner"}
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Banner Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Banner name"
                      value={bannerName}
                      onChange={(e) => setBannerName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Banner Url<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Banner Url"
                      value={bannerUrl}
                      onChange={(e) => setBannerUrl(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Start Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      min="1980-01-01T14:47:57"
                      value={bannerStartDate}
                      onChange={(e) => setBannerStartDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      End Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      min="1980-01-01T14:47:57"
                      value={bannerEndDate}
                      onChange={(e) => setBannerEndDate(e.target.value)}
                    />
                  </div>

                  <div className="mb-3 col-md-12">
                    <label>
                      Banner Image<span className="text-danger">*</span>
                    </label>
                    <br />
                    {isNew ? (
                      <img
                        style={{ height: "200px" }}
                        src={image}
                        alt="hotel"
                      />
                    ) : (
                      bannerImage && (
                        <img
                          style={{ height: "200px" }}
                          src={Global.PHOTO_URL + bannerImage}
                          alt="hotel"
                        />
                      )
                    )}
                    <br />
                    <br />
                    <input type="file" onChange={handleDrop} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BannerManage;
