import React, { useState, useRef, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";

const DropdownBlog = ({ bookingId }) => {
    const navigate = useNavigate();
  
    const EditClick = () => {
      navigate("/booking/" + bookingId);
    };
  
    return (
      <Dropdown className="dropdown">
        <Dropdown.Toggle
          as="div"
          className="btn-link i-false"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu">
          <Dropdown.Item className="dropdown-item" onClick={EditClick}>
            View Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

const AllBookingList = () => {
    const { userToken } = useSelector((state) => state.credential);

    const [isLoading, setIsLoading] = useState(true);
    const [bookings, setBookings] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
  
    const pageLimit = 10;
    const activePag = useRef(0);
  
    // const [selectBtn, setSelectBtn] = useState("Newest");
  
    let paggination = Array(Math.ceil(totalCount / pageLimit))
      .fill()
      .map((_, i) => i + 1);
  
    // Active paggination & chage data
    const ChangePage = (i) => {
      activePag.current = i;
  
      changeData(
        activePag.current * pageLimit,
        (activePag.current + 1) * pageLimit
      );
    };
  
    const changeData = async (first, sec) => {
      setIsLoading(true);
      try {
        const response = await fetch(
          Global.API_URL +
            "Booking/GetBookings?Skip=" +
            first +
            "&Take=" +
            pageLimit,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          setTotalCount(result.count);
          setBookings(array);
          setIsLoading(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
        const GetBookings = async () => {
          try {
            const response = await fetch(
              Global.API_URL +
                "Booking/GetBookings?Skip=0&Take=10",
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "bs-api-key": Global.bs_api_key,
                  AuthorizationUser: userToken,
                },
              }
            );
            const result = await response.json();
            if (result.code === 0) {
              let array = result.message;
              setTotalCount(result.count);
              setBookings(array);
            } else {
              toast.error(result.message);
            }
          } catch (error) {
            console.log(error);
          }
          setIsLoading(false);
        };
    
        GetBookings();
      }, [userToken]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <Link
            to="/booking"
            className="btn btn-secondary margin-right-15"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body p-0">
                <div className="table-responsive">
                  <div
                    id="room_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table card-table display mb-4 shadow-hover dataTablesCard booking-table booking-list-tbl dataTable no-footer">
                      <thead>
                        <tr role="row">
                          <th>Id</th>
                          <th>Room Name</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Check In</th>
                          <th>Check Out</th>
                          <th>Total</th>
                          <th>Status</th>
                          <th className="bg-none"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookings.map((booking, index) => (
                          <tr
                            role="row"
                            className={index % 2 === 0 ? "even" : "odd"}
                            key={index}
                          >
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.bookingId}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.roomName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.firstName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.lastName}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.email}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.phoneNumber}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {new Date(booking.checkinDate).toLocaleString()}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {new Date(booking.checkoutDate).toLocaleString()}
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.totalPrice} USD
                              </span>
                            </td>
                            <td>
                              <span className="fs-16 font-w500 text-nowrap">
                                {booking.bookingStatus}
                              </span>
                            </td>
                            <td>
                              <DropdownBlog
                                bookingId={booking.bookingId}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                      <div className="dataTables_info">
                        Showing {activePag.current * pageLimit + 1}
                        of {totalCount} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers mb-0"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to={"/bookings"}
                          onClick={() =>
                            activePag.current > 0 &&
                            ChangePage(activePag.current - 1)
                          }
                        >
                          <i className="fa fa-angle-double-left"></i> Previous
                        </Link>
                        <span>
                          {paggination.map((number, i) => (
                            <Link
                              key={i}
                              to={"/bookings"}
                              className={`paginate_button  ${
                                activePag.current === i ? "current" : ""
                              } `}
                              onClick={() => ChangePage(i)}
                            >
                              {number}
                            </Link>
                          ))}
                        </span>

                        <Link
                          className="paginate_button next"
                          to={"/bookings"}
                          onClick={() =>
                            activePag.current + 1 < paggination.length &&
                            ChangePage(activePag.current + 1)
                          }
                        >
                          Next <i className="fa fa-angle-double-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AllBookingList