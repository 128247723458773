import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Global } from "../../../../../Global";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const UserManage = () => {
  const navigate = useNavigate();
  const { employee_id } = useParams();
  const { userToken } = useSelector((state) => state.credential);

  const [isLoading, setIsLoading] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [hotelRoleId, setHotelRoleId] = useState(0);
  const [isPublished, setIsPublished] = useState(true);

  const [roles, setRoles] = useState([]);

  const AddEmployee = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email === "" ||
      hotelRoleId === 0
    ) {
      toast.error("All fields are required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "Employee/CreateEmployee",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              email: email,
              hotelRoleId: hotelRoleId,
              isPublished: isPublished,
            }),
          }
        );

        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/employee");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const EditEmployee = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email === "" ||
      hotelRoleId === 0
    ) {
      toast.error("All fields are required");
    } else if (!email.includes("@") || !email.includes(".")) {
      toast.error("Enter a valid email");
    } else {
      try {
        const response = await fetch(
          Global.API_URL + "Employee/EditUser?userid=" + employee_id,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
            body: JSON.stringify({
              firstName: firstName,
              lastName: lastName,
              email: email,
              hotelRoleId: hotelRoleId,
              isPublished: isPublished,
            }),
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/employee");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetEmployee = async () => {
      if (parseInt(employee_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Employee/GetEmployeesById?userid=" + employee_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            setFirstName(result.message.firstName);
            setLastName(result.message.lastName);
            setEmail(result.message.email);
            setHotelRoleId(result.message.roleId);
            setIsPublished(result.message.isPublished);
            setIsLoading(false);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setIsLoading(false);
      }
    };

    GetEmployee();
  }, [userToken, employee_id]);

  useEffect(() => {
    const GetRoles = async () => {
      try {
        const response = await fetch(
          Global.API_URL +
            "HotelRole/GetRolesList?Skip=0&Take=10",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
              AuthorizationUser: userToken,
            },
          }
        );
        const result = await response.json();
        if (result.code === 0) {
          let array = result.message;
          setRoles(array);
        } else if(result.code === 1) {
          setRoles([]);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetRoles();
  }, [userToken ]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={(e) =>
              parseInt(employee_id) === 0 ? AddEmployee(e) : EditEmployee(e)
            }
          >
            Submit
          </button>
          <Link
            to={"/employee"}
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Email Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label>
                      Role<span className="text-danger">*</span>
                    </label>
                    <select
                      id="inputState"
                      className="form-control"
                      value={hotelRoleId}
                      onChange={(e) => setHotelRoleId(e.target.value)}
                    >
                      <option value="">Choose...</option>
                      {roles.map((role, index) => (
                        <option value={role.id} key={index}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserManage;
