import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { Global } from "../../../Global";
import { toast } from "react-toastify";

const DropdownBlog = ({
  roomAllowedItemId,
  IsPublished,
  hotelId,
  ChangePublishStatus,
  navigate,
}) => {
  return (
    <Dropdown className="dropdown">
      <Dropdown.Toggle as="div" className="btn-link i-false">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
            stroke="#262626"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu">
        <Dropdown.Item
          className="dropdown-item"
          onClick={() => navigate("/room-allowed-item/" + roomAllowedItemId)}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown-item"
          onClick={() => ChangePublishStatus(roomAllowedItemId)}
        >
          {IsPublished ? "Unpublish" : "Publish"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const AllowedItemList = () => {
  const [loading, setLoading] = useState(true);
  const [allowedItem, setAllowedItem] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { id } = useParams();
  const pageLimit = 10;
  const activePag = useRef(0);
  const navigate = useNavigate();

  const changeData = (first, sec) => {
    GetMoreAllowedItem(first, sec);
  };
  const ChangePublishStatus = async (allowedItemId) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "RoomAllowedItem/ChangeStatusRoomAllowedItem?itemid=" +
          allowedItemId,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );
      if (response.ok) {
        const result = await response.json();

        if (result.code === 0) {
          toast.success(result.message);
          setLoading(true);
          try {
            const response = await fetch(
              Global.API_URL +
                "RoomAllowedItem/GetRoomAllowedItemsList?skip=0&take=10",
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "bs-api-key": Global.bs_api_key,
                },
              }
            );
            const result = await response.json();
            if (result.code === 0) {
              setAllowedItem(result.message);
            }
          } catch (error) {
            toast.error(error.Message || "Something went wrong");
          }
          setLoading(false);
        } else {
          toast.error(result.message);
        }
      } else {
        const result = await response.json();
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };
  const GetMoreAllowedItem = async (skip, take) => {
    try {
      const response = await fetch(
        Global.API_URL +
          "RoomAllowedItem/GetRoomAllowedItemsList?" +
          skip +
          "&take=" +
          pageLimit,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          },
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        setAllowedItem(result.message);
      }
    } catch (error) {
      toast.error(error.Message || "Something went wrong");
    }
  };

  useEffect(() => {
    const GetAllowedItem = async () => {
      try {
        const response = await fetch(
          Global.API_URL +
            "RoomAllowedItem/GetRoomAllowedItemsList?skip=0&take=10",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "bs-api-key": Global.bs_api_key,
            },
          }
        );
        if (response.ok) {
          const result = await response.json();
          if (result.code === 0) {
            setAllowedItem(result.message);
            setTotalCount(result.count);
          } else {
            toast.error(result);
          }
        } else {
          const result = await response.json();
          toast.error(result);
        }
      } catch (error) {
        toast.error(error.Message || "Something went wrong");
      }
    };

    GetAllowedItem();
    setLoading(false);
  }, []);

  let paggination = Array(Math.ceil(totalCount / pageLimit))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const ChangePage = (i) => {
    activePag.current = i;

    changeData(
      activePag.current * pageLimit,
      (activePag.current + 1) * pageLimit
    );
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
          <div className="card-action coin-tabs mb-3"></div>
          <div className="d-flex align-items-center mb-3">
            <Link
              to={"/room-allowed-item/0"}
              className="btn btn-secondary  margin-right-15"
            >
              + New Allowed Item
            </Link>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-xl-12">
            <div className="card">
              {loading ? (
                <div className="card-body p-0 main-div-loader">
                  <div className="d-flex justify-content-center loader">
                    <div
                      className="spinner-border "
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <div
                      id="concierge_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="example2"
                        className="table card-table display mb-4 shadow-hover dataTablesCard dataTable no-footer"
                      >
                        <thead>
                          <tr role="row">
                            <th className="sorting_asc">Id</th>
                            <th className="sorting">Item Name</th>
                            <th className="sorting text-center">Published</th>
                            <th className="sorting bg-none"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {allowedItem.map((item, index) => {
                            return (
                              <tr role="row" className="odd" key={index}>
                                <td>
                                  <p className="mb-0">
                                    {item.roomAllowedItemId}
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0">{item.itemName}</p>
                                </td>
                                <td className="text-center">
                                  {item.isPublished ? (
                                    <span className="font-w600 text-success">
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span className="font-w600 text-danger">
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <DropdownBlog
                                    roomAllowedItemId={item.roomAllowedItemId}
                                    navigate={navigate}
                                    hotelId={id}
                                    IsPublished={item.isPublished}
                                    ChangePublishStatus={ChangePublishStatus}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info">
                          Showing {activePag.current * pageLimit + 1}
                          of {totalCount} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="example2_paginate"
                        >
                          <Link
                            className="paginate_button previous "
                            to="/room-allowed-item"
                            onClick={() =>
                              activePag.current > 0 &&
                              ChangePage(activePag.current - 1)
                            }
                          >
                            <i
                              className="fa fa-angle-double-left"
                              aria-hidden="true"
                            ></i>{" "}
                            Previous
                          </Link>
                          <span>
                            {paggination.map((number, i) => (
                              <Link
                                key={i}
                                to="/room-allowed-item"
                                className={`paginate_button  ${
                                  activePag.current === i ? "current" : ""
                                } `}
                                onClick={() => ChangePage(i)}
                              >
                                {number}
                              </Link>
                            ))}
                          </span>

                          <Link
                            className="paginate_button next"
                            to="/room-allowed-item"
                            onClick={() =>
                              activePag.current + 1 < paggination.length &&
                              ChangePage(activePag.current + 1)
                            }
                          >
                            Nex{" "}
                            <i
                              className="fa fa-angle-double-right"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowedItemList;
