import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

import RoomSlider from "../../Guest/RoomSlider";
import { Global } from "../../../../../Global";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const DropdownBlog = () => {
  return (
    <>
      <Dropdown className="dropdown dropend ms-auto">
        <Dropdown.Toggle as="div" className="btn-link i-false">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
              stroke="#575757"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
              stroke="#575757"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
              stroke="#575757"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>Edit</Dropdown.Item>
          <Dropdown.Item>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const BookingManage = () => {
  const { booking_id, rating_id } = useParams();
  const location = useLocation();
  const { userToken } = useSelector((state) => state.credential);

  const [booking, setBooking] = useState({
    bookingAddressCity: "",
    bookingAddressCountry: "",
    bookingAddressEmail: "",
    bookingAddressFirstName: "",
    bookingAddressId: 0,
    bookingAddressLastName: "",
    bookingAddressPhone: "",
    bookingId: 0,
    bookingStatusId: 0,
    bookingStatusName: "",
    breakfastFee: 0,
    roomBreakfastPrice: 0,
    checkinDate: "2023-05-08T00:00:00",
    checkoutDate: "2023-05-08T00:00:00",
    city: 0,
    country: "",
    floor: 0,
    hotelId: 0,
    hotelImage: "",
    hotelName: "",
    isCanceled: false,
    isIncludedBreakfast: false,
    isTravelingForWork: false,
    maxAdults: 0,
    maxChildren: 0,
    maxInfants: 0,
    nbOfBaths: 0,
    nbOfGuests: 0,
    paymentMethodId: 0,
    paymentMethodName: "",
    reservationDate: "2023-05-08T06:48:34.607",
    roomAllowedItems: [],
    roomDescription: "",
    roomId: 0,
    roomImages: {
      roomImageId: 0,
      roomImageURL: "",
    },
    roomName: "",
    roomServices: [],
    roomShortDescription: "",
    subtotalPrice: 0,
    totalNbOfBeds: 0,
    totalNbofBedRooms: 0,
    totalPrice: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const CancelBooking = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        Global.API_URL + "Booking/CancelBooking?bookingid=" + booking_id,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          }
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        toast.success(result.message);
        setBooking((prevState) => ({
          ...prevState,
          bookingStatusId: 3,
          isCanceled: 1,
          bookingStatusName: "Canceled"
        }));
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const ConfirmBooking = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        Global.API_URL + "Booking/ConfirmBooking?bookingid=" + booking_id,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
          }
        }
      );
      const result = await response.json();
      if (result.code === 0) {
        toast.success(result.message);
        setBooking((prevState) => ({
          ...prevState,
          bookingStatusId: 2,
          bookingStatusName: "Confirmed"
        }));
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const GetBooking = async () => {
      if (parseInt(booking_id) !== 0) {
        try {
          const response = await fetch(
            Global.API_URL + "Booking/GetBookingByID?bookingid=" + booking_id,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "bs-api-key": Global.bs_api_key,
                AuthorizationUser: userToken,
              },
            }
          );
          const result = await response.json();
          if (result.code === 0) {
            let offset, date, newDate;

            offset = new Date(
              result.message.reservationDate
            ).getTimezoneOffset();
            date = new Date(result.message.reservationDate);
            newDate = new Date(date.getTime() - offset * 60 * 1000);
            result.message.reservationDate = newDate;

            setBooking(result.message);
            setIsLoading(false);
          } else {
            toast.error(result.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    GetBooking();
  }, [userToken, booking_id]);
  console.log(booking)

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">

          {(booking.bookingStatusId === 1 || booking.bookingStatusId === 2) && !booking.isCanceled && (
              <button
                type="submit"
                className="btn btn-primary margin-right-15"
                onClick={(e) => CancelBooking(e)}
              >
                Cancel Booking
              </button>
            )}
          {booking.bookingStatusId === 1  && !booking.isCanceled &&  (
            <button
              type="submit"
              className="btn btn-primary margin-right-15"
              onClick={(e) => ConfirmBooking(e)}
            >
              Confirm Booking
            </button>
          )}
          <Link
            to={
              location.pathname.includes("/booking")
                ? "/booking"
                : location.pathname.includes("/customer-booking")
                ? "/customer"
                : "/rating/" + rating_id
            }
            className="btn btn-secondary"
          >
            Back
          </Link>
        </div>
      </div>
      {isLoading ? (
        <div className="card-body p-0 main-div-loader">
          <div className="d-flex justify-content-center loader">
            <div
              className="spinner-border "
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mt-4">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="card overflow-hidden">
                  <div className="row m-0">
                    <div className="col-xl-12 p-0">
                      <div className="card-body">
                        <div className="guest-profile">
                          <div className="d-flex">
                            <div>
                              <div className="d-flex">
                                <h2 className="font-w600">
                                  {booking.bookingAddressFirstName +
                                    " " +
                                    booking.bookingAddressLastName}
                                </h2>
                                {booking.isCanceled ? (
                                  <span className="font-w600 text-danger text-uppercase">
                                    cancelled
                                  </span>
                                ) : (
                                  <span className="font-w600 text-success text-uppercase">
                                    {booking.bookingStatusName}
                                  </span>
                                )}
                              </div>
                              <span className="text-secondary">
                                ID {booking_id}
                              </span>
                            </div>
                            <DropdownBlog />
                          </div>
                          <div className="d-flex flex-wrap">
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">Email</span>
                              <span className="font-w500 fs-16">
                                {booking.bookingAddressEmail}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">
                                Billing Address
                              </span>
                              <span className="font-w500 fs-16">
                                {booking.bookingAddressCountry +
                                  ", " +
                                  booking.bookingAddressCity}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">Check In</span>
                              <span className="font-w500 fs-16">
                                {new Date(booking.checkinDate).toLocaleString()}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">Check Out</span>
                              <span className="font-w500 fs-16">
                                {new Date(
                                  booking.checkoutDate
                                ).toLocaleString()}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">
                                Reservation Date
                              </span>
                              <span className="font-w500 fs-16">
                                {booking.reservationDate.toLocaleString()}
                              </span>
                            </div>
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">
                                Payment Method
                              </span>
                              <span className="font-w500 fs-16">
                                {booking.paymentMethodName}
                              </span>
                            </div>
                            {!booking.isIncludedBreakfast && (
                              <div className="mt-4 check-status">
                                <span className="d-block mb-2">
                                  Breakfast Fee
                                </span>
                                <span className="font-w500 fs-16">
                                  ${booking.breakfastFee}
                                </span>
                              </div>
                            )}
                            <div className="mt-4 check-status">
                              <span className="d-block mb-2">Total Price</span>
                              <span className="font-w500 fs-16">
                                ${booking.totalPrice}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="card overflow-hidden">
                  <div className="row m-0">
                    <div className="col-xl-6 p-0">
                      <div className="card-body">
                        <div className="d-flex flex-wrap">
                          <div className="mt-4 check-status">
                            <span className="d-block mb-2">Room Name</span>
                            <h4 className="font-w500 fs-24">
                              {booking.roomName}
                            </h4>
                          </div>
                          <div className="mt-4">
                            <span className="d-block mb-2 text-black">
                              Breakfast
                            </span>
                            <span className="font-w500 fs-24 text-black">
                              {booking.isIncludedBreakfast ? "Included" : "Not"}
                            </span>
                          </div>
                        </div>
                        <p className="mt-2">{booking.roomShortDescription}</p>
                        <div className="d-flex flex-wrap">
                          <div className="mt-4 check-status ">
                            <span className="d-block mb-2 text-black">
                              Price
                            </span>
                            <span className="font-w500 fs-24 text-black">
                              ${booking.subtotalPrice}
                              {/* <small className="fs-14 ms-2 text-secondary">
                                /night
                              </small> */}
                            </span>
                          </div>
                          <div className="mt-4 check-status">
                            <span className="d-block mb-2 text-black">
                              Breakfast Price
                            </span>
                            <span className="font-w500 fs-24 text-black">
                              ${booking.roomBreakfastPrice}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="mt-4 check-status ">
                            <span className="d-block mb-2">Nb of Guests</span>
                            <h4 className="font-w500 fs-24">
                              {booking.nbOfGuests}
                              <small className="fs-14 ms-2 text-secondary">
                                guests
                              </small>
                            </h4>
                          </div>
                          <div className="mt-4 check-status">
                            <span className="d-block mb-2">Max Adults</span>
                            <h4 className="font-w500 fs-24">
                              {booking.maxAdults}
                              <small className="fs-14 ms-2 text-secondary">
                                adults
                              </small>
                            </h4>
                          </div>
                          <div className="mt-4 check-status">
                            <span className="d-block mb-2 text-black">
                              Max Children
                            </span>
                            <span className="font-w500 fs-24 text-black">
                              {booking.maxChildren}
                              <small className="fs-14 ms-2 text-secondary">
                                children
                              </small>
                            </span>
                          </div>
                          <div className="mt-4">
                            <span className="d-block mb-2 text-black">
                              Max Infants
                            </span>
                            <span className="font-w500 fs-24 text-black">
                              {booking.maxInfants}
                              <small className="fs-14 ms-2 text-secondary">
                                infants
                              </small>
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap mb-3">
                          <div className="mt-4 check-status">
                            <span className="d-block mb-2">Room Floor</span>
                            <h4 className="font-w500 fs-24">{booking.floor}</h4>
                          </div>
                          <div className="mt-4 check-status ">
                            <span className="d-block mb-2">Bedrooms</span>
                            <h4 className="font-w500 fs-24">
                              {booking.totalNbofBedRooms}
                              <small className="fs-14 ms-2 text-secondary">
                                bedrooms
                              </small>
                            </h4>
                          </div>
                          <div className="mt-4 check-status">
                            <span className="d-block mb-2">Beds</span>
                            <h4 className="font-w500 fs-24">
                              {booking.totalNbOfBeds}
                              <small className="fs-14 ms-2 text-secondary">
                                beds
                              </small>
                            </h4>
                          </div>
                          <div className="mt-4">
                            <span className="d-block mb-2 text-black">
                              Baths
                            </span>
                            <span className="font-w500 fs-24 text-black">
                              {booking.nbOfBaths}
                              <small className="fs-14 ms-2 text-secondary">
                                baths{" "}
                              </small>
                            </span>
                          </div>
                        </div>
                        <div className="facilities">
                          <div className="mb-3 ">
                            <span className="d-block mb-3">Room Services</span>
                            {booking.roomServices.map((service, index) => (
                              <Link
                                to={"#"}
                                className="btn btn-secondary light"
                                key={index}
                              >
                                {service.roomServiceName}
                              </Link>
                            ))}
                          </div>
                        </div>
                        <div className="facilities">
                          <div className="mb-3 ">
                            <span className="d-block mb-3">
                              Room Allowed Items
                            </span>
                            {booking.roomAllowedItems.map((item, index) => (
                              <Link
                                to={"#"}
                                className="btn btn-secondary light"
                                key={index}
                              >
                                {item.itemName}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 p-0">
                      <RoomSlider
                        roomImage={booking.roomImages}
                        roomDescription={booking.roomShortDescription}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default BookingManage;
