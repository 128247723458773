import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Global } from "../../../Global";
import { useSelector } from "react-redux";

const BedTypeManage = () => {
  const { bedtype_id } = useParams();
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.credential);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [bedTypeName, setBedTypeName] = useState("");
  const [isPublished, setIsPublished] = useState(true);

  const AddBedType = async (e) => {
    e.preventDefault();
    if (bedTypeName.trim() === "") {
      toast.error("All fields are required");
    } else {
      try {
        const response = await fetch(Global.API_URL + "BedType/AddBedType", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "bs-api-key": Global.bs_api_key,
            AuthorizationUser: userToken,
          },
          body: JSON.stringify({
            bedTypeName: bedTypeName,
            isPublished: isPublished,
          }),
        });
        const result = await response.json();
        if (result.code === 0) {
          toast.success(result.message);
          navigate("/room-bed-type");
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const GetHotel = async () => {
      if (parseInt(bedtype_id) === 0) {
        setIsLoading(false);
        setDisableSubmit(false);
      }
    };

    GetHotel();
  }, [bedtype_id]);

  return (
    <Fragment>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2">
          <button
            type="submit"
            className="btn btn-primary margin-right-15"
            disabled={disableSubmit}
            onClick={(e) => AddBedType(e)}
          >
            Submit
          </button>
          <Link to={"/room-bed-type/" + bedtype_id} className="btn btn-secondary">
            Back
          </Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            {isLoading ? (
              <div className="card-body p-0 main-div-loader">
                <div className="d-flex justify-content-center loader">
                  <div
                    className="spinner-border "
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="form-group mb-4 col-md-5">
                    <label>
                      Bed Type<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="type"
                      value={bedTypeName}
                      onChange={(e) => setBedTypeName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-4 col-md-2">
                    <div className="form-check form-check-inline mt-6">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isPublished}
                          onChange={(e) => setIsPublished(e.target.checked)}
                        />
                        Publish
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BedTypeManage;
